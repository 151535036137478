import * as Types from './action-types'

export function loadBing() {
	return dispatch => {
		window.autotechBingLoaded = () => {
			dispatch({type: Types.BING_MAPS_LOADED_SUCCESS})
		}

		dispatch({type: Types.BING_MAPS_LOADING})

		//This script include will put the Microsoft object on the window
		const script = document.createElement('script')
		script.src = 'https://www.bing.com/api/maps/mapcontrol?callback=autotechBingLoaded'
		script.async = 1
		script.onerror = () => dispatch({type: Types.BING_MAPS_LOADING_ERROR})

		document.body.appendChild(script)
	}
}
