import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ProgressBar} from './progress-bar'
import globals from '../globals'

const cb = 'auto-tech-title-and-progress'

export class TitleAndProgress extends Component {
	render() {
		const {children, currentPage, searchFlow, showProgressBar = true, title} = this.props
		return (
			<div className={cb}>
				{!globals.isLargeView && showProgressBar && <ProgressBar currentPage={currentPage}
																		 searchFlow={searchFlow}
																		 title={title}/>}
				<h1 className={`${cb}__title`}>{title}</h1>
				{children && <div className={`${cb}__sub-text`}>{children}</div>}
			</div>
		)
	}
}

TitleAndProgress.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
	currentPage: PropTypes.string,
	searchFlow: PropTypes.bool,
	showProgressBar: PropTypes.bool,
	title: PropTypes.string
}
