import {combineReducers} from 'redux'
import {metaLayerReducer as metaLayer} from './meta-layer'
import {yearsReducer as years} from './years'
import {makesReducer as makes} from './makes'
import {modelsReducer as models} from './models'
import {trimsReducer as trims} from './trims'
import {calendarsReducer as calendars} from './calendars'
import {storesReducer as stores} from './stores'
import {vehicleReducer as vehicle} from './vehicle'
import {facetsReducer as facets} from './facets'
import {packagesReducer as packages} from './packages'
import {confirmUIReducer as confirmPageUI} from './confirm/confirm-page-ui'
import {storePageUIReducer as storePageUI} from './store-page-ui'
import {vehiclePageUIReducer as vehiclePageUI} from './vehicle-page-ui'
import {reviewPageUIReducer as reviewPageUI} from './review/review-page-ui'
import {userReducer as user} from './user'
import {routerReducer as router} from './router'
import {searchReducer as search} from './search/search'
import {searchPageUIReducer as searchPageUI} from './search/search-page-ui'
import {searchResultsPageUIReducer as searchResultsPageUI} from './search/search-results-page-ui'
import {serviceSelectorPageUI} from './service-selector-page-ui'
import {appointmentDetailsReducer as appointmentDetailsUI} from './appointment-details'

export default combineReducers({
	confirmPageUI,
	metaLayer,
	years,
	makes,
	models,
	stores,
	trims,
	vehicle,
	facets,
	packages,
	calendars,
	storePageUI,
	vehiclePageUI,
	reviewPageUI,
	search,
	searchPageUI,
	searchResultsPageUI,
	serviceSelectorPageUI,
	user,
	router,
	appointmentDetailsUI
})
