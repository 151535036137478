import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {TitleAndProgress} from '../components/title-and-progress'
import globals from '../globals'
import {BackLink} from '../components/back-link'
import * as router from '../actions/routing'
import {buildConsultUrl} from '../utils/flow'
import {BrixIcon} from '@bestbuy/brix-web'
import {WHAT_TO_EXPECT_URL, SAFETY_MEASURES_URL} from '../utils/constants'

const cb = 'auto-tech-confirmation'

export class ConfirmationPage extends Component {

	static propTypes = {
		confirmationNumber: PropTypes.string,
		push: PropTypes.func,
		confirmUI: PropTypes.object,
		isConsult: PropTypes.bool,
		isReschedule: PropTypes.bool,
	}

	render() {
		const {confirmUI = {}, isConsult, isReschedule} = this.props
		const {confirmationNumber} = confirmUI
		const rescheduleByline = "You'll receive two e-mails: one for your original appointment and one for your new appointment."
		const defaultByline = "You'll receive a confirmation e-mail shortly."
		const isLargeView = globals.isLargeView

		const icon = (
			<div className={`${cb}__icon-wrapper`}>
				<BrixIcon  icon='Checkmark_Confirm_Line_Sm' width={35} fill={'#318000'}/>
			</div>
		)

		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>
					<div className={`${cb}__page-title-wrapper`}>
						<TitleAndProgress showProgressBar={false}
										  title="You're all set">
							<span className={`${cb}__initial-phrase`}>Thanks! We'll see you soon.</span> {isReschedule ? rescheduleByline : defaultByline}
						</TitleAndProgress>
					</div>
					<div className={`${cb}__confirmation-number-wrapper`}>
						{isLargeView && icon}
						<div className={`${cb}__confirmation-number-text-wrapper`}>
							<h2 className={`${cb}__confirmation-number-title`}>Confirmation Number</h2>
							{confirmationNumber}
						</div>
					</div>
					<div className={`${cb}__expectations-wrapper`}>
						{isLargeView && icon}
						{this.expectations(isConsult)}
					</div>

					{!isLargeView && <BackLink onClick={e => this.props.push(this.backLinkPath(isConsult))}/>}
				</div>
			</div>
		)
	}

	backLinkPath = (isConsult) => {
		return buildConsultUrl(isConsult, '/review')
	}

	expectations = (isConsult) => {
		let response
		if (isConsult) {
			response = <div className={`${cb}__expectations-text-wrapper`}>
				<h2 className={`${cb}__expectations-title`}>What to Expect</h2>
				<div className={`${cb}__expectations-notes`}>One of our expert Autotechs will help you understand your options.</div>
			</div>
		}
		else {
			response = <div className={`${cb}__expectations-text-wrapper`}>
				<h2 className={`${cb}__expectations-title`}>Remember to get ready for your appointment</h2>
				<ul className={`${cb}__expectations-list`}>
					<li>Check in five minutes prior to your appointment and please allow for an additional 15 minutes for an Autotech to inspect your vehicle.</li>
					<li>Bring all components you would like installed including parts and accessories.</li>
					<li>Make sure your car is clutter free.</li>
					<li>
						<div>Before your reservation, we’ll call you to explain the safety guidelines for our stores.</div>
						<div><a className={`${cb}__what-to-expect`} href={WHAT_TO_EXPECT_URL} data-track='What to Expect Link'>Learn more about how to prepare and what to expect.</a></div>
					</li>
					<li>
						<div>Wear a face covering for safety. Everyone is required to wear a face covering so we can help protect each other. We will supply a face covering if you don’t have one. Small children and those unable to wear one for health reasons may enter without one.</div>
						<div><a className={`${cb}__safety-measures`} href={SAFETY_MEASURES_URL} data-track='Link to safety measures'>Learn more about our safety measures.</a></div>
					</li>
				</ul>
			</div>
		}
		return response
	}
}

export const mapStateToProps = (state) => ({
	isReschedule: !!state.router.isReschedule,
	isConsult: !!state.router.isConsult,
	confirmUI: state.confirmPageUI
})

const mapDispatchToProps = (dispatch) => ({
	push: route => dispatch(router.push(route))
})

export const ConnectedConfirmationPage = connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage)
