export const RETRIEVE_YEARS = 'RETRIEVE_YEARS'
export const RETRIEVE_YEARS_SUCCESS = 'RETRIEVE_YEARS_SUCCESS'
export const RETRIEVE_YEARS_FAILURE = 'RETRIEVE_YEARS_FAILURE'
export const RETRIEVE_MAKES = 'RETRIEVE_MAKES'
export const RETRIEVE_MAKES_SUCCESS = 'RETRIEVE_MAKES_SUCCESS'
export const RETRIEVE_MAKES_FAILURE = 'RETRIEVE_MAKES_FAILURE'
export const RETRIEVE_MODELS = 'RETRIEVE_MODELS'
export const RETRIEVE_MODELS_SUCCESS = 'RETRIEVE_MODELS_SUCCESS'
export const RETRIEVE_MODELS_FAILURE = 'RETRIEVE_MODELS_FAILURE'
export const RETRIEVE_TRIMS = 'RETRIEVE_TRIMS'
export const RETRIEVE_TRIMS_SUCCESS = 'RETRIEVE_TRIMS_SUCCESS'
export const RETRIEVE_TRIMS_FAILURE = 'RETRIEVE_TRIMS_FAILURE'
export const RETRIEVE_VEHICLE = 'RETRIEVE_VEHICLE'
export const RETRIEVE_VEHICLE_SUCCESS = 'RETRIEVE_VEHICLE_SUCCESS'
export const RETRIEVE_VEHICLE_FAILURE = 'RETRIEVE_VEHICLE_FAILURE'
export const RETRIEVE_USER_DETAILS = 'RETRIEVE_USER_DETAILS'
export const RETRIEVE_USER_DETAILS_SUCCESS = 'RETRIEVE_USER_DETAILS_SUCCESS'
export const RETRIEVE_USER_DETAILS_FAILURE = 'RETRIEVE_USER_DETAILS_FAILURE'
export const RESET_RESTORE_SESSION = 'RESET_RESTORE_SESSION'
export const RESTORE_SESSION_COMPLETED = 'RESTORE_SESSION_COMPLETED'

export const SET_YEAR = 'SET_YEAR'
export const SET_MAKE = 'SET_MAKE'
export const SET_MODEL = 'SET_MODEL'
export const SET_TRIM = 'SET_TRIM'
export const SET_FACET = 'SET_FACET'
export const ADD_FACET = 'ADD_FACET'
export const REMOVE_FACET = 'REMOVE_FACET'

export const SET_PACKAGE = 'SET_PACKAGE'
export const PRODUCT_DETAIL_SUCCESS = 'PRODUCT_DETAIL_SUCCESS'
export const PRODUCT_DETAIL_FAILURE = 'PRODUCT_DETAIL_FAILURE'
export const REMOVE_PACKAGE = 'REMOVE_PACKAGE'
export const HIDE_PACKAGE_PARTS_AND_LABOR = 'HIDE_PACKAGE_PARTS_AND_LABOR'
export const SHOW_PACKAGE_PARTS_AND_LABOR = 'SHOW_PACKAGE_PARTS_AND_LABOR'
export const SERVICE_SUMMARY_SUSPEND_RENDERING_START = 'SERVICE_SUMMARY_SUSPEND_RENDERING_START'
export const SERVICE_SUMMARY_SUSPEND_RENDERING_STOP = 'SERVICE_SUMMARY_SUSPEND_RENDERING_STOP'

export const RETRIEVE_SLOTS = 'RETRIEVE_SLOTS'
export const RETRIEVE_SLOTS_SUCCESS = 'RETRIEVE_SLOTS_SUCCESS'
export const RETRIEVE_SLOTS_FAILURE = 'RETRIEVE_SLOTS_FAILURE'
export const RETRIEVE_INITIAL_SLOTS = 'RETRIEVE_INITIAL_SLOTS'
export const RETRIEVE_INITIAL_SLOTS_SUCCESS = 'RETRIEVE_INITIAL_SLOTS_SUCCESS'
export const RETRIEVE_INITIAL_SLOTS_FAILURE = 'RETRIEVE_INITIAL_SLOTS_FAILURE'
export const RETRIEVE_STORES = 'RETRIEVE_STORES'
export const RETRIEVE_STORES_SUCCESS = 'RETRIEVE_STORES_SUCCESS'
export const RETRIEVE_STORES_FAILURE = 'RETRIEVE_STORES_FAILURE'
export const SET_ZIP = 'SET_ZIP'
export const SET_SLOT = 'SET_SLOT'
export const SELECT_SLOT = 'SELECT_SLOT'
export const SAVE_USER_INPUT = 'SAVE_USER_INPUT'
export const SUBMIT_APPOINTMENT = 'SUBMIT_APPOINTMENT'
export const SUBMIT_APPOINTMENT_SUCCESS = 'SUBMIT_APPOINTMENT_SUCCESS'
export const SUBMIT_APPOINTMENT_FAILURE = 'SUBMIT_APPOINTMENT_FAILURE'

export const SEARCH_FOR_APPOINTMENT = 'SEARCH_FOR_APPOINTMENT'
export const SEARCH_BY_ENCRYPTED_CONFIRMATION = 'SEARCH_BY_ENCRYPTED_CONFIRMATION'
export const SEARCH_FOR_APPOINTMENT_SUCCESS = 'SEARCH_FOR_APPOINTMENT_SUCCESS'
export const SEARCH_FOR_APPOINTMENT_EMPTY = 'SEARCH_FOR_APPOINTMENT_EMPTY'
export const SEARCH_BY_TOKENIZED_CONFIRMATION = 'SEARCH_BY_TOKENIZED_CONFIRMATION'
export const SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE = 'SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE'
export const SEARCH_BY_TOKENIZED_CONFIRMATION_SUCCESS = 'SEARCH_BY_TOKENIZED_CONFIRMATION_SUCCESS'
export const SEARCH_FOR_APPOINTMENT_FAILURE = 'SEARCH_FOR_APPOINTMENT_FAILURE'
export const SEARCH_FOR_APPOINTMENT_INVALID = 'SEARCH_FOR_APPOINTMENT_INVALID'
export const SET_SEARCH_CONFIRMATION_NUMBER = 'SET_SEARCH_CONFIRMATION_NUMBER'
export const SET_SEARCH_LASTNAME = 'SET_SEARCH_LASTNAME'
export const SET_FLOW_TYPE = 'SET_FLOW_TYPE'

export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT'
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS'
export const CANCEL_APPOINTMENT_FAILURE = 'CANCEL_APPOINTMENT_FAILURE'

export const BING_MAPS_LOADING = 'BING_MAPS_LOADING'
export const BING_MAPS_LOADED_SUCCESS = 'BING_MAPS_LOADED_SUCCESS'
export const BING_MAPS_LOADING_ERROR = 'BING_MAPS_LOADING_ERROR'
export const SHOW_TIMES = 'SHOW_TIMES'
export const SHOW_HIDE_MAP = 'SHOW_HIDE_MAP'
export const SET_FOCUSED_STORE = 'SET_FOCUSED_STORE'
export const NAVIGATE = 'NAVIGATE'
export const PUSH = 'PUSH'

export const BUILD_SERVICE_SUMMARY = 'BUILD_SERVICE_SUMMARY'
export const CALC_PACKAGE_TOTAL_PRICE = 'CALC_PACKAGE_TOTAL_PRICE'

export const UPDATE_PHONE = 'UPDATE_PHONE'
export const ADD_PHONE = 'ADD_PHONE'
export const SMS_OPT_OUT = 'SMS_OPT_OUT'
