import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {emptyFunction, PRIVACY_POLICY, TERMS_URL} from '../utils/constants'
import {invalidOptionalPhone} from '../utils/form-validation-utils'
import {BrixButton, BrixInputWrapper, BrixInput} from '@bestbuy/brix-web'
import {humanizePhone, sanitizePhoneInput} from '../utils/string-utils'
import {saveUserInput} from '../actions/save-user-input'
import * as Types from '../actions/action-types'

const cb = 'sms-opt-in'

export default class SmsOptIn extends Component {

	static propTypes = {
		isSMSOptInEligible: PropTypes.bool,
		isSMSOptIn: PropTypes.bool,
		smsOptInPhoneNumber: PropTypes.string,
		saveUserInput: PropTypes.func,
		newPhone: PropTypes.string,
		updatePhone: PropTypes.func,
		updatingPhone: PropTypes.bool,
		addPhone: PropTypes.func,
		addingPhone: PropTypes.bool,
		optOut: PropTypes.func,
		optingOut: PropTypes.bool,
		hasBeenSubmitted: PropTypes.bool,
	}

	static defaultProps = {
		saveUserInput: emptyFunction,
		updatePhone: emptyFunction,
		addPhone: emptyFunction,
		optOut: emptyFunction,
		newPhone: '',
	}

	render() {
		const {isSMSOptInEligible, isSMSOptIn, smsOptInPhoneNumber, saveUserInput, newPhone, updatePhone, updatingPhone, addPhone, addingPhone, optOut, optingOut, hasBeenSubmitted} = this.props
		const alreadyOptedIn = isSMSOptIn && smsOptInPhoneNumber && !optingOut
		const eligibleToOptIn = isSMSOptInEligible && (!isSMSOptIn || optingOut)
		const showPhoneInput = updatingPhone || addingPhone

		let italicsText = 'Optional'
		if (alreadyOptedIn) {
			italicsText = 'for text updates'
		}

		return (
			<div className={cb}>
				<h3 className={`${cb}__heading`}>Mobile Phone <span className={`${cb}__italics`}>{italicsText}</span></h3>
				{!alreadyOptedIn && <h4 className={`${cb}__italics`}>For text updates</h4>}
				{alreadyOptedIn && !updatingPhone && (
					<div>
						<p className={`${cb}__number`}>{smsOptInPhoneNumber}</p>
						<BrixButton
							buttonStyle='default-link'
							data-track='Change mobile number'
							className={`${cb}__update-link`}
							onClick={updatePhone}
						>
							Change mobile number
						</BrixButton>
					</div>
				)}
				{eligibleToOptIn && !addingPhone && (
					<BrixButton
						buttonStyle='default-link'
						data-track='Add mobile number'
						className={`${cb}__update-link`}
						onClick={addPhone}
					>
						Add mobile number
					</BrixButton>
				)}
				{showPhoneInput && (
					<BrixInputWrapper aria-invalid={hasBeenSubmitted && invalidOptionalPhone(newPhone)}
									errorMessage={{
										id: `${cb}__phone-id`,
										text: 'Please enter a valid phone number.'
									}}>
						<label className={`${cb}__phone-label sr-only`} htmlFor='form-phone'>Phone Number</label>
						<BrixInput id='sms-opt-in-form'
								className={`${cb}__phone-input`}
								value={newPhone}
								onChange={event => saveUserInput({newPhone: sanitizePhoneInput(event.target.value)})}/>
					</BrixInputWrapper>
				)}
				<div className={`${cb}__fine-print`}>
					{alreadyOptedIn && <span>You're getting texts for all your orders, services, and appointments. <BrixButton className={`${cb}__opt-out`} buttonStyle='default-link' data-track='SMS Opt Out' onClick={optOut}>Opt out</BrixButton></span>}
					{eligibleToOptIn && <span>Text me updates about all my orders, services and appointments</span>}
				</div>
				{eligibleToOptIn && <div className={`${cb}__terms-and-conditions`}>Mobile phones only. Message and data rates may apply. <a href={TERMS_URL} data-track='SMS Terms' target='_blank' rel='noopener noreferrer'>Terms <span className='sr-only'>Opens a new window</span></a> and <a href={PRIVACY_POLICY} data-track='SMS Privacy Policy' target='_blank' rel='noopener noreferrer'>Privacy Policy <span className='sr-only'>Opens a new window</span></a> apply.</div>}
			</div>
		)
	}
}

export const mapStateToProps = (state) => ({
	newPhone: humanizePhone(state.user.newPhone),
	isSMSOptInEligible: state.user.isSMSOptInEligible,
	isSMSOptIn: state.user.isSMSOptIn,
	smsOptInPhoneNumber: humanizePhone(state.user.smsOptInPhoneNumber),
	updatingPhone: state.user.updatingPhone,
	addingPhone: state.user.addingPhone,
	optingOut: state.user.optingOut,
	hasBeenSubmitted: state.user.hasBeenSubmitted
})

export const mapDispatchToProps = (dispatch) => ({
	saveUserInput: userInput => dispatch(saveUserInput(userInput)),
	updatePhone: () => dispatch({type: Types.UPDATE_PHONE}),
	addPhone: () => dispatch({type: Types.ADD_PHONE}),
	optOut: () => dispatch({type: Types.SMS_OPT_OUT})
})

export const ConnectedSmsOptIn = connect(mapStateToProps, mapDispatchToProps)(SmsOptIn)

