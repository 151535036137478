import React from 'react'
import {BrixButton} from '@bestbuy/brix-web'
import PropTypes from 'prop-types'
import {emptyFunction} from '../utils/constants'

const cb = 'auto-tech-cancel-modal'

export class CancelModal extends React.Component {
	render() {
		const {uiState = {}} = this.props

		return (
			<div className={cb}>
				<h3 className={`${cb}__title`}>Are you sure you want to cancel your appointment?</h3>

				<div className={`${cb}__exit-container`}>
					<BrixButton className={`${cb}__confirm-cancel-button ${uiState.loading ? 'btn-trailing-ficon btn-disabled' : ''}`}
							  buttonStyle='secondary'
							  size='large'
							  onClick={() => !uiState.loading ? this.props.cancelAppointment() : null}>
						Cancel Appointment
						{uiState.loading && <i className='spinner' aria-label='loading'/>}
					</BrixButton>

					<BrixButton className={`${cb}__exit-modal-button`}
							  buttonStyle='outline'
							  size='large'
							  onClick={this.props.hide}>
						Keep Appointment
					</BrixButton>
				</div>
			</div>
		)
	}
}

CancelModal.defaultProps = {
	cancelAppointment: emptyFunction,
	hide: emptyFunction
}

CancelModal.propTypes = {
	cancelAppointment: PropTypes.func,
	hide: PropTypes.func,
	uiState: PropTypes.object
}
