const moment = require('moment')
require('moment-duration-format')

export function toLowerCase(text) {
	return typeof text === 'string' ? text.toLowerCase().trim() : text
}

export function trim(text) {
	return typeof text === 'string' ? text.trim() : text
}

export function sanitizePhoneInput(text) {
	let output = text
	if (typeof text === 'string') {
		const numbersOnlyTrimmed = text.replace(/\D/g,'')
		if (numbersOnlyTrimmed.charAt(0) === '1') {
			output = numbersOnlyTrimmed.substr(0, 11)
		}
		else {
			output = numbersOnlyTrimmed.substr(0, 10)
		}
	}
	return output
}

export function humanizeTimes(time) {
	return typeof time === 'string' ? time.replace(/^12 pm$/, 'Noon').replace(/ am$/, ' a.m.').replace(/ pm$/, ' p.m.') : time
}

export function humanizeDuration(duration) {
	if (!duration || duration < 0) {
		return null
	}
	const hasMinutes = duration % 60
	return moment.duration(duration, 'minutes').format(`h [hr.]${hasMinutes ? ' m [min.]' : ''}`)
}

export function humanizePhone(phone) {
	let output = phone
	let exchangeCode, subscriberNumber
	if (typeof phone === 'string') {
		if (phone.charAt(0) === '1') {
			const countryCode = phone.substr(0, 1)
			if (countryCode) output = '+' + countryCode

			const areaCode = phone.substr(1, 3)
			if (areaCode) output += '-' + areaCode

			exchangeCode = phone.substr(4, 3)
			subscriberNumber = phone.substr(7, 4)
		}
		else {
			const areaCode = phone.substr(0, 3)
			if (areaCode) output = areaCode

			exchangeCode = phone.substr(3, 3)
			subscriberNumber = phone.substr(6, 4)
		}

		if (exchangeCode) output += '-' + exchangeCode
		if (subscriberNumber) output += '-' + subscriberNumber
	}
	return output
}

export function priceToString(price) {
	return typeof price === 'number' ? price.toFixed(2) : price
}

export function isBoolean(value) {
	return typeof value === 'boolean'
}
