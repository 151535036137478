import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrixButton, BrixSelect} from '@bestbuy/brix-web'
import {emptyFunction} from '../../utils/constants'

const cb = 'auto-tech-service-block'

export class ServiceBlock extends Component {
	render() {
		const {facets = {}, index, id, onChange, removeFacet} = this.props
		const primarySelectionText = id === 'speaker' ? 'Location Select' : 'Service Select'
		const secondarySelectionText = id === 'speaker' ? 'Location Select' : 'Additional Service Select'

		return (
			<div className={`${cb}__all-content-wrapper`}>
				<label className={`${cb}__dropdown-label`} htmlFor={`${cb}__${index}-dropdown`}>{index > 0 ? secondarySelectionText : primarySelectionText}</label>

				<BrixSelect id={`${cb}__${index}-dropdown`}
							className={`${cb}__dropdown`}
							options={getOptions(facets, id, 'Select Service')}
							value={id || 'default'}
							disabled={!!id && id !== 'default'}
							onChange={e => onChange(facets.data.find(facet => facet.id === e.target.value), index)}/>

				{this.props.children}

				<div className={`${cb}__remove-button-container`}>
					<BrixButton className={`${cb}__${index}-remove-button`}
							  buttonStyle='outline'
							  onClick={() => removeFacet(id, index)}>
							  Remove this Service
					</BrixButton>
				</div>
			</div>
		)
	}
}

export const getOptions = (facets, id, defaultText) => {
	const options = [{text: defaultText, value: 'default', attributes: {disabled: true}}]
	if (Array.isArray(facets.data)) {
		options.push(...facets.data.map(it => ({
			text: it.name,
			value: it.id,
			attributes: {
				disabled: it.id !== id && facetAlreadySelected(facets.selection, it.id)
			}
		})))
	}
	return options
}

const facetAlreadySelected = (selections = [], currentId) => selections.some(selection => selection.id === currentId)

ServiceBlock.defaultProps = {
	onChange: emptyFunction,
	removeFacet: emptyFunction
}

ServiceBlock.propTypes = {
	index: PropTypes.number,
	facets: PropTypes.object,
	onChange: PropTypes.func,
	removeFacet: PropTypes.func,
	id: PropTypes.string,
	children: PropTypes.object,
}
