import React, {Component} from 'react'
import {connect} from 'react-redux'
import {BrixButton, BrixModal, BrixIcon} from '@bestbuy/brix-web'
import {TitleAndProgress} from '../components/title-and-progress'
import {Address} from '../components/address'
import PropTypes from 'prop-types'
import {humanizeDuration} from '../utils/string-utils'
import {CancelModal} from './cancel-modal'
import globals from '../globals'
import {cancelAppointment} from '../actions/cancel'
import * as routing from '../actions/routing'
import {GeneralMessage} from '../components/general-message'
import {ERROR, profileOrderDetailsUrl, SearchType, SAFE_CAR_SERVICE} from '../utils/constants'
import {searchForEncryptedAppointment, searchForT1T2Appointment} from '../actions/search'
import {determineNextRoute} from '../actions/session'

const cb = 'auto-tech-search-results'

export class SearchResultsPage extends Component {

	static propTypes = {
		cancelAppointment: PropTypes.func,
		searchForEncryptedAppointment: PropTypes.func,
		searchForT1T2Appointment: PropTypes.func,
		push: PropTypes.func,
		searchResult: PropTypes.object,
		uiState: PropTypes.object,
		searchType: PropTypes.string,
		match: PropTypes.object,
		location: PropTypes.object,
		setFlowType: PropTypes.func,
		rescheduleAppointment: PropTypes.func,
		user: PropTypes.object,
	}

	state = {
		showModal: false
	}

	componentWillMount() {
		if (this.props.searchType === SearchType.ENCRYPTED_SEARCH) {
			const encryptedAppointment = this.props.match.params.encryptedConfirmationNumber
			if (encryptedAppointment) {
				this.props.searchForEncryptedAppointment(encryptedAppointment)
			}
		}
		else if (this.props.searchType === SearchType.T1T2_SEARCH) {
			this.props.searchForT1T2Appointment(this.props.location.search)
		}
	}

	componentWillReceiveProps(nextProps) {
		const {uiState = {}} = nextProps
		if (uiState.failure) {
			this.setState({showModal: false})
		}
	}

	render() {
		const {searchResult, uiState} = this.props
		const showServices = !searchResult.suppressReschedule && !searchResult.isCancelled && !uiState.searchByConfirmationError
		const showInitialLoadingDiv = uiState.searchingByConfirmation

		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>

					<div className={`${cb}__content`}>
						<TitleAndProgress title='Appointment details' showProgressBar={false}>
							{searchResult.suppressReschedule && <span>To reschedule, please cancel this appointment and make a new one.</span>}
						</TitleAndProgress>

						<div className={`${cb}__precautions-wrapper`}>
								<div className={`${cb}__service-icon-wrapper`}>{this.getSvgIcon('CarElectronics_Line_Sm','service')}</div>
								<div className={`${cb}__text-block-precautions`}>
									<h2 className={`${cb}__service-title`}>Safety Precautions for Your Car Service and Installation</h2>
									<div className={`${cb}__service-content`}>
										<div>Your safety is our top priority. Learn about the essential steps we're taking before, during, and after your Autotech car service or installation.</div>
										<div><a className={`${cb}__safety-measures`} target='#' href={SAFE_CAR_SERVICE} data-track='Link to safety measures'>Learn about our new safety measures</a></div>
									</div>
								</div>
						</div>

						{searchResult.isCancelled && <GeneralMessage usedBy={ERROR.CANCELLED}/>}
						{searchResult.isCompleted && <GeneralMessage usedBy={ERROR.PAST}/>}
						{uiState.failure && <GeneralMessage usedBy={ERROR.CANCEL_FAILED}/>}
						{uiState.searchByConfirmationError && <GeneralMessage usedBy={ERROR.SEARCH_FAILED}/>}

						{showInitialLoadingDiv && (
							<div className={`${cb}__spinner-wrapper`}>
								<div className={`${cb}__spinner-content`}>
									<i className='spinner' aria-label='loading'/>
								</div>
							</div>
						)}

						{showServices && !showInitialLoadingDiv && (
							<div className={`${cb}__service-wrapper`}>
								<div className={`${cb}__service-icon-wrapper`}>{this.getSvgIcon('CarElectronics_Line_Sm','service')}</div>
								<div className={`${cb}__text-block`}>
									<h2 className={`${cb}__service-title`}>Service</h2>
									<div className={`${cb}__service-content`}>
										<div>Your Appointment for</div>
										{searchResult.year} {searchResult.make} {searchResult.model}
										<ul className={`${cb}__service-list`}>
											{(searchResult.services || []).map(service => <li key={service}>{service}</li>)}
										</ul>
									</div>
								</div>
							</div>
						)}

						{!uiState.searchByConfirmationError && !showInitialLoadingDiv && (<div>
							<div className={`${cb}__appt-location-wrapper`}>
								<div className={`${cb}__appt-location-icon-wrapper`}>{this.getSvgIcon('Map_Pin_Line_Sm','location')}</div>
								<div className={`${cb}__text-block`}>
									<h2 className={`${cb}__appt-location-title`}>Store</h2>
									<div className={`${cb}__appt-location-content`}>
										<Address locationId={searchResult.locationId} address={searchResult.address}/>
									</div>
								</div>
							</div>

							<div className={`${cb}__confirmation-wrapper`}>
								<div className={`${cb}__confirmation-icon-wrapper`}>{this.getSvgIcon('Login_Line_Sm','user')}</div>
								<div className={`${cb}__text-block`}>
									<h2 className={`${cb}__confirmation-title`}>Confirmation Number</h2>
									<div className={`${cb}__confirmation-content`}>
										{uiState.confirmationNumber || searchResult.confirmationNumber}
									</div>
								</div>
							</div>

							<div className={`${cb}__appt-time-wrapper`}>
								<div className={`${cb}__appt-time-icon-wrapper`}>{this.getSvgIcon('Calendar_Line_Sm','app-time')}</div>
								<div className={`${cb}__text-block`}>
									<h2 className={`${cb}__appt-time-title`}>Date & Time</h2>
									<div className={`${cb}__appt-time-content`}>
										<div>{searchResult.date}</div>
										<div>{searchResult.start}</div>
										<div className={`${cb}__appt-duration`}>(approximately {humanizeDuration(searchResult.duration)})</div>
									</div>
								</div>
							</div>
						</div>)}
					</div>

					{!showInitialLoadingDiv && (
						<div className={`${cb}__button-container`}>
							{searchResult.isOpen && !searchResult.isBossAppointment && (
								<BrixButton className={`${cb}__cancel-button`}
										  buttonStyle='outline'
										  onClick={() => this.setState({showModal: true})}>
									Cancel Appointment
								</BrixButton>
							)}

							{this.state.showModal && (
								<BrixModal autoFocus='false'
										 size={globals.isLargeView ? 'medium' : 'small-view'}
										 onClose={() => this.setState({showModal: false})}>
									<CancelModal cancelAppointment={this.props.cancelAppointment}
												 uiState={this.props.uiState}
												 hide={() => this.setState({showModal: false})}
												 />
								</BrixModal>
							)}

							{searchResult.isOpen && !searchResult.suppressReschedule && (
								<BrixButton className={`${cb}__reschedule-button`}
										  buttonStyle='secondary'
										  onClick={() => this.onReschedule()}>
									Reschedule Appointment
								</BrixButton>
							)}

							{(searchResult.isCancelled || searchResult.isCompleted) && (
								<BrixButton className={`${cb}__schedule-button`}
										  buttonStyle='secondary'
										  href='/services/autotech'>
									Make a New Appointment
								</BrixButton>
							)}
						</div>
					)}
				</div>
			</div>
		)
	}

	getSvgIcon = (icon, modifier) =>
		<div className={`${cb}__icon-wrapper`}>
			<BrixIcon className={`${cb}__icon-${modifier}`} icon={icon}/>
		</div>

	onReschedule = () => {
		const {isBossAppointment, bbyOrderId} = this.props.searchResult || {}

		if (isBossAppointment) {
			window.location.assign(profileOrderDetailsUrl(bbyOrderId))
		}
		else {
			if ((this?.props?.user || {})?.email) {
				this.props.setFlowType({isReschedule: true})
				this.props.push('/store-selector')
			}
			else {
				this.props.setFlowType({isRescheduleAppointment: true})
				this.props.rescheduleAppointment()
			}
		}
	}
}

export const mapStateToProps = state => {
	return {
		searchResult: state.search,
		uiState: state.searchResultsPageUI,
		user: state.user,
	}
}

export const mapDispatchToProps = dispatch => ({
	cancelAppointment: () => dispatch(cancelAppointment()),
	push: (location) => dispatch(routing.push(location)),
	setFlowType: (flow) => dispatch(routing.setFlowType(flow)),
	searchForEncryptedAppointment: (encApp) => dispatch(searchForEncryptedAppointment(encApp)),
	searchForT1T2Appointment: (t1t2) => dispatch(searchForT1T2Appointment(t1t2)),
	rescheduleAppointment: () => {
		dispatch(determineNextRoute())
		return Promise.resolve()
		}
})

export const ConnectedSearchResultsPage = connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage)
