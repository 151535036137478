import * as Types from '../actions/action-types'

export function storesReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.RETRIEVE_STORES_SUCCESS:
			return {data: action.stores, zip: action.zip, index: 0}

		case Types.RETRIEVE_STORES_FAILURE:
			return {error: true}

		case Types.SET_FACET:
		case Types.REMOVE_FACET:
		case Types.SET_PACKAGE:
		case Types.REMOVE_PACKAGE:
			return {}

		case Types.RETRIEVE_INITIAL_SLOTS_SUCCESS:
		case Types.RETRIEVE_INITIAL_SLOTS_FAILURE:
			return {...state, index: state.index + 1}

		case Types.SET_SLOT:
			return {...state, selection: undefined}

		case Types.SELECT_SLOT:
			return {...state, selection: state.data.find(store => store.locationId === action.locationId)}

		default:
			return state
	}
}
