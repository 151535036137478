import * as StringUtils from '../utils/string-utils'

export function formatPhone(number) {
	if (number) {
		number = number.replace(/[()[\] -]/g, '')
		if (number.length >= 10 && number[0] === '1') {
			number = number.substring(1)
		}
	}
	return number
}

const bbyEmailValidation = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z]{2,})\b$/i
const bbyPhoneValidation = /^1?[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/
const nameValidation = name => (/^[a-zA-Z '-]+$/.test(name || ''))
const confirmationValidation = value => (/^[a-zA-Z0-9]{1}[a-zA-Z0-9-_]{4,}$/.test(value || ''))

export const invalidPhone = phone => !(bbyPhoneValidation.test(formatPhone(phone)))
export const invalidOptionalPhone = phone => phone ? invalidPhone(phone) : false
export const invalidEmail = email => !(bbyEmailValidation.test(email))
export const isZip = (value) => (/^\d{5}?$/.test(value))

export const isStringBlank = (val) => {
	if (val && typeof val === 'string') {
		return val.trim() ? false : true
	}
	return true
}

export const invalidName = (name) => {
	if (!StringUtils.trim(name) || !nameValidation(name)) {
		return true
	}
	return false
}

export const invalidConfirmationNumber = (name) => {
	if (!StringUtils.trim(name) || !confirmationValidation(name)) {
		return true
	}
	return false
}
