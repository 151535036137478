import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {TitleAndProgress} from '../components/title-and-progress'
import {BrixButton, BrixInputWrapper, BrixInput, PLTextarea} from '@bestbuy/brix-web'
import {humanizePhone, sanitizePhoneInput, trim} from '../utils/string-utils'
import {connect} from 'react-redux'
import {saveUserInput} from '../actions/save-user-input'
import globals from '../globals'
import {BackLink} from '../components/back-link'
import {invalidEmail, invalidName, invalidOptionalPhone, invalidPhone} from '../utils/form-validation-utils'
import * as routing from '../actions/routing'
import {buildConsultUrl} from '../utils/flow'
import {ConnectedSmsOptIn} from '../components/sms-opt-in-section'

const cb = 'auto-tech-customer-details'

export class CustomerDetailsPage extends Component {

	static propTypes = {
		user: PropTypes.object,
		push: PropTypes.func,
		saveUserInput: PropTypes.func,
		isConsult: PropTypes.bool,
		isSMSOptInEligible: PropTypes.bool,
	}

	render() {
		const {user = {}, isConsult, isSMSOptInEligible} = this.props
		const hasBeenSubmitted = user.hasBeenSubmitted
		const isLargeView = globals.isLargeView
		const showSMSOptIn = isSMSOptInEligible && globals.liveConfig.smsOptInEnabled

		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>
					<div className={`${cb}__page-title-wrapper`}>
						<TitleAndProgress currentPage='customer-details'
										  title='Provide your details'/>
					</div>

					<form className={`${cb}__form`}>
						<div className={`${cb}__required-fields`}>
							<BrixInputWrapper aria-invalid={invalidName(user.firstName) && hasBeenSubmitted}
											errorMessage={{
												id: `${cb}__first-name-id`,
												text: 'Please enter first name.'
											}}>
								<label className={`${cb}__first-name-label`} htmlFor='form-first-name'>First Name</label>
								<BrixInput id='form-first-name'
										className={`${cb}__first-name-input`}
										value={user.firstName}
										onChange={event => this.props.saveUserInput({firstName: trim(event.target.value)})}/>
							</BrixInputWrapper>

							<BrixInputWrapper aria-invalid={invalidName(user.lastName) && hasBeenSubmitted}
											errorMessage={{
												id: `${cb}__last-name-id`,
												text: 'Please enter last name.'
											}}>
								<label className={`${cb}__last-name-label`} htmlFor='form-last-name'>Last Name</label>
								<BrixInput id='form-last-name'
										className={`${cb}__last-name-input`}
										value={user.lastName}
										onChange={event => this.props.saveUserInput({lastName: trim(event.target.value)})}/>
							</BrixInputWrapper>

							<BrixInputWrapper aria-invalid={invalidEmail(user.email) && hasBeenSubmitted}
											errorMessage={{
												id: `${cb}__email-id`,
												text: 'Please enter a valid e-mail address.'
											}}>
								<label className={`${cb}__email-label`} htmlFor='form-email'>Email Address</label>
								<BrixInput id='form-email'
										className={`${cb}__email-input`}
										value={user.email}
										onChange={event => this.props.saveUserInput({email: trim(event.target.value)})}/>
							</BrixInputWrapper>
							{showSMSOptIn ?
								<ConnectedSmsOptIn/> :
								<BrixInputWrapper aria-invalid={invalidPhone(user.phone) && hasBeenSubmitted}
												errorMessage={{
													id: `${cb}__phone-id`,
													text: 'Please enter a valid phone number.'
												}}>
									<label className={`${cb}__phone-label`} htmlFor='form-phone'>Phone Number</label>
									<BrixInput id='form-phone'
											className={`${cb}__phone-input`}
											value={humanizePhone(user.phone)}
											onChange={event => this.props.saveUserInput({phone: sanitizePhoneInput(event.target.value)})}/>
									<div className={`${cb}__phone-info`} aria-label='Note to the customer'> We may call you about your scheduled services. Calls may be live or pre-recorded.</div>
								</BrixInputWrapper>
							}
						</div>
						<div className={`${cb}__optional-fields`}>
							{this.commentText(isConsult)}
							<PLTextarea id='form-comments'
										placeholder={this.commentPlaceholderText(isConsult)}
										className={`${cb}__comments-input`}
										value={user.comments}
										onChange={event => this.props.saveUserInput({comments: event.target.value})}/>
						</div>
					</form>
					<div className={`${cb}__submit-button-section`}>
						<BrixButton className={`${cb}__submit-button`}
								  buttonStyle='secondary'
								  onClick={e => this.handleSubmit(e)}>
							Continue
						</BrixButton>
					</div>
					{!isLargeView && <BackLink onClick={e => this.props.push(this.backLinkPath(isConsult))}/>}
				</div>
			</div>
		)
	}

	commentText = (isConsult) => {
		if (isConsult) {
			return <label className={`${cb}__comments-label`} htmlFor='form-comments'>How can we help you with your car electronics needs?</label>
		}
		return (
			<label className={`${cb}__comments-label`} htmlFor='form-comments'>How can we help?
				<span className={`${cb}__comments-label--optional`}> (optional)</span>
			</label>
		)
	}

	commentPlaceholderText = (isConsult) => {
		return isConsult ? 'Example: I want to upgrade my sound system.' : ''
	}

	backLinkPath = (isConsult) => {
		return buildConsultUrl(isConsult, '/store-selector')
	}

	isFormInvalid = () => {
		const invalidFirstName = invalidName(this.props.user.firstName)
		const invalidLastName = invalidName(this.props.user.lastName)
		const invalidPhoneNumber = this.props.isSMSOptInEligible ? invalidOptionalPhone(this.props.user.newPhone) : invalidPhone(this.props.user.phone)
		const invalidEmailAddress = invalidEmail(this.props.user.email)
		return invalidFirstName || invalidLastName || invalidPhoneNumber || invalidEmailAddress
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const url = buildConsultUrl(this.props.isConsult, '/review')
		this.props.saveUserInput({hasBeenSubmitted: true})
		if (!this.isFormInvalid()) {
			this.props.push(url)
		}
	}
}

export const mapStateToProps = (state) => ({
	isConsult: state.router.isConsult,
	user: state.user || {},
	isSMSOptInEligible: state.user.isSMSOptInEligible,
})

export const mapDispatchToProps = (dispatch) => ({
	saveUserInput: userInput => dispatch(saveUserInput(userInput)),
	push: route => dispatch(routing.push(route))
})

export const ConnectedCustomerDetailsPage = connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage)
