import * as Actions from '../../actions/action-types'

export function searchResultsPageUIReducer(state = {}, action = {}) {

	switch (action.type) {
		case Actions.SET_SEARCH_CONFIRMATION_NUMBER:
			return {...state, confirmationNumber: action.confirmationNumber}

		case Actions.SEARCH_FOR_APPOINTMENT:
			return {...state, failure: false}

		case Actions.CANCEL_APPOINTMENT:
			return {...state, loading: true}

		case Actions.CANCEL_APPOINTMENT_SUCCESS:
			return {...state, failure: false, loading: false}

		case Actions.CANCEL_APPOINTMENT_FAILURE:
			return {...state, failure: true, loading: false}

		case Actions.SEARCH_BY_ENCRYPTED_CONFIRMATION:
			return {...state, searchingByConfirmation: true, searchByConfirmationError: false}

		case Actions.SEARCH_FOR_APPOINTMENT_SUCCESS:
			return {...state, searchingByConfirmation: false, searchByConfirmationError: false}

		case Actions.SEARCH_FOR_APPOINTMENT_FAILURE:
			return {...state, searchingByConfirmation: false, searchByConfirmationError: true}

		default:
			return state
	}
}
