import * as Actions from '../actions/action-types'

const catNameByPath = {
	'/': 'Home',
	'/vehicle-selector': 'Home',
	'/service-selector': 'Describe Your Request',
	'/store-selector': 'Select a Store',
	'/customer-details': 'Provide Your Details',
	'/review': 'Confirm Appointment',
	'/confirmation': 'Reservation Confirmed'
}

export const analyticsMiddleware = store => next => action => {
	const result = next(action)
	switch (action.type) {
		case Actions.NAVIGATE:
			if (catNameByPath[action.location.pathname]) {
				window.track = {
					pageType: 'geekSquad',
					uberCatName: 'Geek Squad',
					parentCatName: 'Autotech Triage',
					templateName: 'GSAT',
					catName: catNameByPath[action.location.pathname],
				}
				try {
					window.EventManager.trigger('page:stateChange')
				}
				catch (error) {
					// Silent fail; We need to address with FTA test to catch this condition.
				}
			}
	}
	return result
}
