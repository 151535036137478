import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrixCheckbox, BrixInputGroup, BrixRadio} from '@bestbuy/brix-web'
import {emptyFunction, phoneNumber} from '../utils/constants'
import globals from '../globals'
import {ServiceFacetSpeaker} from './service-facet-speaker'

const cb = 'auto-tech-service-package'

export class ServicePackage extends Component {
	render() {
		const {packages = [], setPackage, selectedPackages = [], removePackage, facetId} = this.props
		const showHelpText = !packages.length && (globals.isLargeView || !globals.isLargeView && facetId)

		if (showHelpText) {
			return (
				<div>
					<div className={`${cb}__title-more-info`}>Your vehicle requires additional information.</div>
					<div className={`${cb}__msg-more-info`}>Please call {phoneNumber} to schedule your appointment.</div>
				</div>
			)
		}

		const containsExclusivePackage = packages.some(pkg => pkg.exclusive)
		const helperText = containsExclusivePackage ? 'Select One' : 'Select All That Apply'
		const packagesBySubcategory = packages.reduce((groups, next) => {
			groups[next.subCategory || ''] = (groups[next.subCategory || ''] || []).concat(next)
			return groups
		}, {})
		const smallviewSpeakers = facetId === 'speaker' && !globals.isLargeView

		return (
			<div className={cb}>
				{Object.keys(packagesBySubcategory).map(subCategory =>
					<div key={subCategory}>
						{smallviewSpeakers && <ServiceFacetSpeaker subCategory={subCategory}
																   packages={packagesBySubcategory[subCategory]}
																   selectedPackages={selectedPackages}
																   setPackage={setPackage}
																   removePackage={removePackage}/>}

						{!smallviewSpeakers && <div className={`${cb}__title`}>{subCategory ? `${subCategory}: ` : ''}{helperText}</div>}
						{!smallviewSpeakers && (
							<BrixInputGroup className={`${cb}__input-group`}>
								{packagesBySubcategory[subCategory].map((pkg, idx) => {
									const isSelected = selectedPackages.some(selectedPkg => selectedPkg.id === pkg.id)
									if (containsExclusivePackage) {
										return (
											<div className={`${cb}__input-wrapper`} key={idx}>
												<BrixRadio id={`${cb}__${pkg.id}`}
														 className={`${cb}__input`}
														 name={`${pkg.facet.id}-${pkg.subCategory}`}
														 onChange={() => isSelected ? null : setPackage(pkg)}
														 checked={isSelected}>
													<div className={`${cb}__package`}>{pkg.shortName}</div>
												</BrixRadio>
											</div>
										)
									}

									return (
										<div className={`${cb}__input-wrapper`} key={idx}>
											<BrixCheckbox id={`${cb}__${pkg.id}`}
														className={`${cb}__input`}
														onChange={() => isSelected ? removePackage(pkg) : setPackage(pkg)}
														checked={isSelected}>
												<div className={`${cb}__package`}>{pkg.shortName}</div>
											</BrixCheckbox>
										</div>
									)
								})}
							</BrixInputGroup>
						)}
					</div>
				)}
			</div>
		)
	}
}

ServicePackage.defaultProps = {
	setPackage: emptyFunction,
	removePackage: emptyFunction
}

ServicePackage.propTypes = {
	facetId: PropTypes.string,
	packages: PropTypes.array,
	setPackage: PropTypes.func,
	selectedPackages: PropTypes.array,
	removePackage: PropTypes.func
}
