import React from 'react'
import PropTypes from 'prop-types'
import {BrixIcon, BrixButton} from '@bestbuy/brix-web'
import globals from '../globals'

export const AppointmentPrepTask = ({
    task
}) => {
    const {
        taskDescription,
        url,
        taskActionText,
        taskDisplayText
    } = task

    const RenderTaskContent = () => (
        <>
            <h3 className={`apptPrepTaskHeading v-fw-medium ${globals.isLargeView ? 'body-copy-lg' : 'body-copy'}`}>{taskDisplayText && taskDisplayText.replace('.', '')}</h3>
            {!!taskDescription &&
                <p className='v-m-top-xxs v-m-bottom-reset'>{taskDescription}</p>
            }
            {!!taskActionText && !!url &&
                <p className='v-m-bottom-reset'>
                    <BrixButton
                        buttonStyle='default-link'
                        href={url}
                        target='_blank'
                    >
                        {taskActionText}
                    </BrixButton>
                </p>
            }
        </>
    )

    const RenderStaticTask = () => (
        <li className='apptPrepTask apptPrepTaskStatic d-flex'>
            <div className='apptPrepTaskIconWrapper'>
                <BrixIcon icon='Confirm_Line_Sm' />
            </div>
            <div className={`apptPrepTaskContent ${globals.isLargeView ? 'body-copy-lg' : 'body-copy'}`}>
                <RenderTaskContent />
            </div>
        </li>
    )


    return <RenderStaticTask />
}

AppointmentPrepTask.propTypes = {
    task: PropTypes.object.isRequired,
}
