import React from 'react'
import globals from '../../globals'
import PropTypes from 'prop-types'

const cb = 'auto-tech-part-line-item'

export const PartLineItem = ({imageUrl, description, manufacturer, model, sku, price, productDescription}) =>
	<li className={cb}>
		<div className={`${cb}__flex`}>
			<div className={`${cb}__inner-flex`}>
				{globals.isLargeView && <img className={`${cb}__img`} src={imageUrl} alt=''/>}
				<div className={`${cb}__details`}>
					<div className={`${cb}__manufacturer-desc`}>
						<Description manufacturer={manufacturer}
									 description={description}
									 productDescription={productDescription}/>
					</div>
					<ModelSku model={model} sku={sku}/>
				</div>
			</div>
			<div className={`${cb}__price`}>
				{(price || price === 0) ? <span>${price.toFixed(2)}</span> : null}
			</div>
		</div>
	</li>

PartLineItem.propTypes = {
	imageUrl: PropTypes.string,
	description: PropTypes.string,
	manufacturer: PropTypes.string,
	model: PropTypes.string,
	sku: PropTypes.number,
	price: PropTypes.number,
	productDescription: PropTypes.string,
}

const Description = ({manufacturer, description, productDescription}) =>
	productDescription
		? <span>{productDescription}</span>
		: <span>
			{manufacturer}
			{description && manufacturer && ' - '}
			{description}
		</span>

Description.propTypes = {
	manufacturer: PropTypes.string,
	description: PropTypes.string,
	productDescription: PropTypes.string,
}

const ModelSku = ({model, sku}) => {
	return globals.isLargeView
		? (
			<div className={`${cb}__model-sku`}>
				{model && <span>Model : <span className={`${cb}__model`}>{model}</span></span>}
				{model && sku && <span> | </span>}
				{sku && <span>SKU : <span className={`${cb}__sku`}>{sku}</span></span>}
			</div>
		)
		: (
			<div className={`${cb}__model-sku`}>
				{model && <div>Model : <span className={`${cb}__model`}>{model}</span></div>}
				{sku && <div>SKU : <span className={`${cb}__sku`}>{sku}</span></div>}
			</div>
		)
}

ModelSku.propTypes = {
	model: PropTypes.string,
	sku: PropTypes.number,
}
