import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {AUTOTECH_NUMBER, AUTOTECH_NUMBER_LINK, ERROR} from '../utils/constants'
import globals from '../globals'
import {BrixAlert} from '@bestbuy/brix-web'

const cb = 'auto-tech-general-message'

export class GeneralMessage extends Component {

	static propTypes = {
		tiny: PropTypes.bool,
		usedBy: PropTypes.string,
		errorRef: PropTypes.object,
	}

	static defaultProps = {}

	levels = {
		SUCCESS: 'success',
		WARNING: 'warning',
		DANGER: 'danger',
	}

	render() {
		const phoneNumberNode = globals.isLargeView ? AUTOTECH_NUMBER : AUTOTECH_NUMBER_LINK
		const tiny = this.props.tiny ? `${cb}__tiny` : ''

		let content = {}
		content[ERROR.DURATION] = {
			message: "We're sorry. It looks like your service request will take over six hours.",
			instruction: <span>Please call a Geek Squad Autotech on {phoneNumberNode} for help scheduling this service.</span>,
			level: this.levels.WARNING
		}

		content[ERROR.DURATION_SMALL] = {
			message: "We're sorry. Your service will take over 6 hours.",
			instruction: <span>Please call us at {phoneNumberNode} for scheduling.</span>,
			level: this.levels.WARNING
		}

		content[ERROR.UNKNOWN] = {
			message: 'We’re sorry. It looks like we’re unable to complete your specific request online.',
			instruction: <span>Please call a Geek Squad Autotech on {phoneNumberNode}.</span>,
			level: this.levels.WARNING
		}

		content[ERROR.SLOT_UNAVAILABLE] = {
			message: 'This appointment time is no longer available. Please use your browser back button and select a different time.',
			instruction: null,
			level: this.levels.WARNING
		}

		content[ERROR.SUBMIT_SUCCESS] = {
			message: 'Your appointment has been successfully submitted!',
			instruction: <span>Please call {phoneNumberNode} if you need further assistance.</span>,
			level: this.levels.SUCCESS
		}

		content[ERROR.SEARCH_FAILED] = content[ERROR.CANCEL_FAILED] = {
			message: <strong>We’re sorry! Our reservation system is having difficulty.</strong>,
			instruction: <span>Try your request again or call {phoneNumberNode} for assistance.</span>,
			level: this.levels.WARNING
		}

		content[ERROR.SEARCH_EMPTY] = {
			message: <strong>We’re sorry. We’re unable to locate this reservation in our system.</strong>,
			instruction: <span>Please re-enter your information or call {phoneNumberNode} for assistance.</span>,
			level: this.levels.DANGER
		}

		content[ERROR.CANCELLED] = {
			message: <span>This reservation has already been canceled.</span>,
			instruction: null,
			level: this.levels.WARNING
		}

		content[ERROR.PAST] = {
			message: <span>This appointment occurs in the past.</span>,
			instruction: null,
			level: this.levels.WARNING
		}

		const usedBy = content.hasOwnProperty(this.props.usedBy) ? this.props.usedBy : ERROR.UNKNOWN
		const alertContent = content[usedBy]

		return (
			<div className={`${cb} ${tiny}`}
				 tabIndex='-1'
				 ref={this.props.errorRef}>

				<BrixAlert level={alertContent.level} isVisible={true} role='alert'>
					{alertContent.message}
					{alertContent.instruction && (<p>{alertContent.instruction}</p>)}
				</BrixAlert>
			</div>
		)
	}
}
