import * as Types from '../../actions/action-types'

export function confirmUIReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.SUBMIT_APPOINTMENT_SUCCESS:
			return {...state, confirmationNumber: action.confirmationNumber}

		default:
			return state
	}
}
