import {invalidEmail, invalidName, invalidPhone} from '../utils/form-validation-utils'
import * as Actions from './action-types'
import {buildConsultUrlUsingState} from '../utils/flow'

export const listener = (history) => {
	return (dispatch, getState) => {
		history.listen((location, action) => {
			dispatch({type: Actions.NAVIGATE, location: {pathname: location.pathname}, action})

			const state = getState()
			const userIsReschedule = state.router && state.router.isReschedule

			if (action === Actions.PUSH) {
				window.scrollTo(0, 0)
			}

			if (action === 'POP' && location.pathname === '/review') {
				if (userIsReschedule) {
					storeSelectorCheck(state, dispatch)
				}
				else {
					customerDetailsCheck(state, dispatch)
				}
			}

			else if (action === 'POP' && userIsReschedule) {
				return
			}

			else if (action === 'POP' && location.pathname === '/service-selector') {
				vehicleSelectorCheck(state, dispatch)
			}

			else if (action === 'POP' && location.pathname === '/store-selector') {
				serviceSelectorCheck(state, dispatch)
			}

			else if (action === 'POP' && location.pathname === '/customer-details') {
				storeSelectorCheck(state, dispatch)
			}

		})
	}
}

export function handle(current = {}, next = {}, history) {
	const currentPathname = (current.location || {}).pathname
	const currentLocation = currentPathname ? currentPathname.split('?')[0] : currentPathname

	if ((next.location || {}).pathname !== currentLocation) {
		if (next.action === Actions.PUSH) {
			history.push(next.location.pathname)
		}
	}
}

export function push(pathname) {
	return {type: Actions.PUSH, location: {pathname}, action: Actions.PUSH}
}

export function setFlowType(flowType) {
	return {type: Actions.SET_FLOW_TYPE, value: flowType}
}

const vehicleSelectorCheck = (state, dispatch) => {
	const data = (state.vehicle || {}).data
	const allowSelfSchedule = (data || {}).allowSelfSchedule
	if (!allowSelfSchedule) {
		dispatch(push(buildConsultUrlUsingState(state, '/vehicle-selector')))
	}
}

const serviceSelectorCheck = (state, dispatch) => {
	const selection = (state.packages || {}).selection
	if (!selection || selection.length == 0) {
		dispatch(push('/service-selector'))
	}
}

const storeSelectorCheck = (state, dispatch) => {
	const storesSelection = (state.stores || {}).selection
	const storePageUISelection = (state.storePageUI || {}).selection
	if (!storesSelection || !storePageUISelection) {
		dispatch(push(buildConsultUrlUsingState(state, '/store-selector')))
	}
}

const customerDetailsCheck = (state, dispatch) => {
	if (invalidName(state.user.firstName) || invalidName(state.user.lastName) || invalidEmail(state.user.email) || invalidPhone(state.user.phone)) {
		dispatch(push(buildConsultUrlUsingState(state, '/customer-details')))
	}
}
