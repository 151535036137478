import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Moment from 'moment'
import {BrixButton, BrixIcon} from '@bestbuy/brix-web'
import {TitleAndProgress} from '../components/title-and-progress'
import {Address} from '../components/address'
import {DAY_LABEL_FULL_DATE, DAY_LABEL_MONTH_DATE, STD_DATE} from '../utils/constants'
import {submitAppointment} from '../actions/submit-appointment'
import {GeneralMessage} from '../components/general-message'
import globals from '../globals'
import {BackLink} from '../components/back-link'
import {isAdditionalItem} from '../reducers/packages'
import * as routing from '../actions/routing'
import {humanizeDuration, humanizePhone, humanizeTimes} from '../utils/string-utils'
import {buildConsultUrl} from '../utils/flow'

const cb = 'auto-tech-review'

export class ReviewPage extends Component {

	render() {
		const {
			packages = {},
			vehicle = {},
			vehicleYear = '',
			stores = {},
			user = {},
			storePageUI = {},
			reviewPageUI = {},
			search = {},
			isConsult,
			isReschedule,
			smsOptInPhoneNumber,
			optingOut,
			isSMSOptInEligible
		} = this.props

		const storeSelection = stores.selection || {}
		const storePageUIselection = storePageUI.selection || {}
		const isLargeView = globals.isLargeView
		const serviceTitle = isConsult ? 'Consultation' : 'Services'
		let phoneText
		if (optingOut && !user.newPhone) {
			phoneText = smsOptInPhoneNumber ? `You will no longer receive text updates at ${humanizePhone(smsOptInPhoneNumber)}.` : 'You will no longer receive text updates.'
		}
		else if (smsOptInPhoneNumber || user.newPhone) {
			phoneText = `You will receive text updates at ${humanizePhone(user.newPhone ? user.newPhone : smsOptInPhoneNumber)}.`
		}

		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>
					<div className={`${cb}__page-title-wrapper`}>
						<TitleAndProgress currentPage='confirmation'
										  searchFlow={!!isReschedule}
										  title='Confirm your appointment'/>
					</div>

					{!!reviewPageUI.error && <div className={`${cb}__general-message`}><GeneralMessage usedBy={reviewPageUI.error}/></div>}
					{reviewPageUI.submitSuccess && <div className={`${cb}__general-message`}><GeneralMessage usedBy='submitSuccess'/></div>}

					<div className={`${cb}__service-wrapper`}>
						{isLargeView && this.getSvgIcon('CarElectronics_Line_Sm', 'service')}
						<div className={`${cb}__service-text-wrapper`}>
							<h2 className={`${cb}__service-title`}>{serviceTitle}</h2>
							<div className={`${cb}__service-summary`}>
								{(isConsult || !isLargeView) && <div className={`${cb}__service-intro-text`}>Appointment for your</div>}
								<div className={`${cb}__vehicle-summary`}>{vehicleYear} {vehicle.data.make} {vehicle.data.model}</div>
								{!isConsult && (
									<ul className={`${cb}__service-list`}>
										{packages.selection && this.buildServicesList(packages.selection)}
										{search.services && search.services.map(service => <li key={service}>{service}</li>)}
									</ul>
								)}
							</div>
						</div>
					</div>

					<div className={`${cb}__location-wrapper`}>
						{isLargeView && this.getSvgIcon('Map_Pin_Line_Sm', 'location')}
						<div className={`${cb}__location-text-wrapper`}>
							<h2 className={`${cb}__location-title`}>Store</h2>
							<Address locationId={storeSelection.locationId} address={storeSelection.address}/>
						</div>
					</div>

					{!isReschedule && (
						<div className={`${cb}__user-details-wrapper`}>
							{isLargeView && this.getSvgIcon('Login_Line_Sm', 'user')}
							<div className={`${cb}__user-details-text-wrapper`}>
								<h2 className={`${cb}__user-details-title`}>Your Details</h2>
								<div>{user.firstName} {user.lastName}</div>
								{!isSMSOptInEligible && <div>{humanizePhone(user.phone)}</div>}
								<div>{user.email}</div>
								{phoneText && <div>{phoneText}</div>}
							</div>
						</div>
					)}

					<div className={`${cb}__appt-time-wrapper`}>
						{isLargeView && this.getSvgIcon('Calendar_Line_Sm', 'app-time')}
						<div className={`${cb}__appt-time-text-wrapper`}>
							<h2 className={`${cb}__appt-time-title`}>Date & Time</h2>
							<div className={`${cb}__appt-date`}>
								{Moment(storePageUIselection.date, STD_DATE).format(isLargeView ? DAY_LABEL_FULL_DATE : DAY_LABEL_MONTH_DATE)}
							</div>
							<div className={`${cb}__appt-time`}>{humanizeTimes(storePageUIselection.start)}</div>
							<div className={`${cb}__appt-duration`}>
								{`(approximately ${humanizeDuration(search.duration || packages.summary.duration)})`}
							</div>
						</div>
					</div>

					<div className={`${cb}__confirmation-button-section`}>
						{!reviewPageUI.submitSuccess &&
						<BrixButton className={`${cb}__confirmation-button ${reviewPageUI.submitting ? 'btn-trailing-ficon' : ''}`}
								  buttonStyle='secondary'
								  onClick={this.props.submitAppointment}>
							Confirm
							{reviewPageUI.submitting && <i className='spinner' aria-label='loading'/>}
						</BrixButton>}
					</div>
					{!isLargeView && <BackLink onClick={() => this.props.push(this.backLinkPath(isReschedule, isConsult))}/>}
				</div>
			</div>
		)
	}

	backLinkPath = (isReschedule, isConsult) => {
		let url = '/customer-details'
		if (isReschedule) {
			url = '/store-selector'
		}
		else if (isConsult) {
			url = buildConsultUrl(isConsult, '/customer-details')
		}
		return url
	}

	getSvgIcon = (icon, modifier) => {
		const svgUseAttributes = {width: 90, height: 90}
		return <div className={`${cb}__icon-wrapper`}>
			<BrixIcon className={`${cb}__icon-${modifier}`} icon={icon} svgUseAttributes={svgUseAttributes}/>
		</div>
	}

	buildServicesList = (selection) =>
		selection.map((pkg, idx) => {
			if (!!pkg.name && !!pkg.facet && !isAdditionalItem(pkg)) {
				return (
					<li key={idx}>
						<span className={`${cb}__facet-name`}>{pkg.facet.name}</span>
						{globals.isLargeView ? `: ${pkg.name}` : ''}
					</li>
				)
			}
		})
}

ReviewPage.propTypes = {
	isConsult: PropTypes.bool,
	isReschedule: PropTypes.bool,
	packages: PropTypes.object,
	push: PropTypes.func,
	reviewPageUI: PropTypes.object,
	storePageUI: PropTypes.object,
	stores: PropTypes.object,
	search: PropTypes.object,
	submitAppointment: PropTypes.func,
	user: PropTypes.object,
	vehicle: PropTypes.object,
	vehicleYear: PropTypes.string,
	smsOptInPhoneNumber: PropTypes.string,
	optingOut: PropTypes.bool,
	isSMSOptInEligible: PropTypes.bool,
}

export const mapStateToProps = (state = {}) => {
	const {router = {}, search = {}, years = {}, user = {}} = state

	return {
		isConsult: !!router.isConsult,
		isReschedule: !!router.isReschedule,
		packages: state.packages,
		reviewPageUI: state.reviewPageUI,
		storePageUI: state.storePageUI,
		stores: state.stores,
		search: state.search,
		user: state.user,
		vehicle: state.vehicle,
		vehicleYear: years.selection || search.year,
		smsOptInPhoneNumber: user.smsOptInPhoneNumber,
		optingOut: user.optingOut,
		isSMSOptInEligible: user.isSMSOptInEligible
	}
}

const mapDispatchToProps = (dispatch) => ({
	push: (route) => dispatch(routing.push(route)),
	submitAppointment: () => dispatch(submitAppointment())
})

export const ConnectedReviewPage = connect(mapStateToProps, mapDispatchToProps)(ReviewPage)
