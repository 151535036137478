import * as Types from '../actions/action-types'

export function vehicleReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.RETRIEVE_VEHICLE_SUCCESS:
			return {
				data: {
					make: action.vehicle.make,
					model: action.vehicle.model,
					allowSelfSchedule: !!(action.vehicle['allow_self_schedule'])
				}
			}

		case Types.SEARCH_FOR_APPOINTMENT_SUCCESS:
			return {
				data: {
					make: action.make,
					model: action.model,
					allowSelfSchedule: true
				}
			}

		case Types.RETRIEVE_VEHICLE_FAILURE:
			return {...state, error: true}

		case Types.SET_YEAR:
		case Types.SET_MAKE:
		case Types.SET_MODEL:
		case Types.SET_TRIM:
			return {}

		default:
			return state
	}
}
