import {UserLocationManager} from 'sc-location'
import {SET_ZIP} from './action-types'

export function geolocateZip(locationManager) {
	const manager = locationManager || new UserLocationManager({changeInitiator: 'DSE-auto-tech-ui'})

	return dispatch =>
		manager.getLocation()
			.then(loc => dispatch({type: SET_ZIP, zip: loc.physicalZipCode || null}))
			.catch(err => {
			})
}

export function setZip(zip) {
	return dispatch => dispatch({type: SET_ZIP, zip: zip})
}
