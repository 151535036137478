import * as api from '../api/utils'
import * as Types from './action-types'
import {DEFAULT_DROPDOWN_VALUE} from '../utils/constants'

export function retrieveVehicle() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_VEHICLE})

		const state = getState()

		if (state.trims.selection === DEFAULT_DROPDOWN_VALUE) {
			return new Promise(function (resolve) {
				resolve(dispatch({type: Types.RETRIEVE_VEHICLE_FAILURE}))
			})
		}

		return api.baseFetch(`${state.metaLayer.env_appServer}/carfit/vehicles?year=${state.years.selection}&make=${state.makes.selection}&model=${state.models.selection}&trim=${state.trims.selection}`)
			.then(
				data => dispatch({type: Types.RETRIEVE_VEHICLE_SUCCESS, vehicle: data}),
				err => dispatch({type: Types.RETRIEVE_VEHICLE_FAILURE})
			)
	}
}
