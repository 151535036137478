import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {BrixOverlayWrapper, BrixButton, BrixInformationButton} from '@bestbuy/brix-web'
import globals from '../../globals'
import alertTriangle from '../../images/alert-triangle.svg'
import checkmark from '../../images/checkmark.svg'
import {DollarAmount} from '../../components/dollar-amount'

const cb = 'auto-tech-modal-member-savings'

export const MemberSavings = ({user = {}, totalSavings, showIcon}) => {
	const hasSavings = !!totalSavings

	return (
		<div className={cb}>
			{(user.totalTechMember || user.paidMember) && (
				<div className={`${cb}__flex`}>
					{showIcon && (
						<div className={`${cb}__icon`}>
							<img src={checkmark} alt='' />
						</div>
					)}
					<div>
						{hasSavings && <p><strong>Congrats, {user.firstName}!</strong> As a {user.totalTechMember ? <span>Total Tech Support</span> : <span>Best Buy Totaltech&trade;</span>} member, your estimated savings are <DollarAmount amount={totalSavings} hideCents={true} />.</p>}
						{!hasSavings &&
							<p>
								{user.totalTechMember ? <strong>Total Tech Support</strong> : <strong>Best Buy Totaltech&trade;</strong>}
								<div>Free installation for members on Best Buy purchases.
									<Disclaimer/>
								</div>
							</p>
						}
					</div>
				</div>
			)}
			{!(user.totalTechMember || user.paidMember) && (
				<div className={`${cb}__flex`}>
					{showIcon && (
						<div className={`${cb}__icon`}>
							<img src={alertTriangle} alt='' />
						</div>
					)}
					<div>
						{hasSavings && (
							<div>
								<p><strong>Best Buy Totaltech&trade;</strong> members save an estimated <DollarAmount amount={totalSavings} hideCents={true} /> on this installation. <Disclaimer/></p>
							</div>
						)}
						{!hasSavings && (
							<div>
								<p>
									<strong>Best Buy Totaltech&trade;</strong>
									<div>Free installation for members on Best Buy purchases.
										<Disclaimer/>
									</div>
								</p>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

MemberSavings.propTypes = {
	user: PropTypes.object,
	totalSavings: PropTypes.number,
	showIcon: PropTypes.bool,
}

const Disclaimer = () => {

	const [wrapperClose, setWrapperClose] = useState(false)

	const overlay = () => {
		return (<div className={`${cb}__overlayContent`}>
			<div className={`${cb}__disclaimerContent`}>
				Until your renewal, you’ll continue to have access to all your current TotalTech benefits.
				After renewal, your membership will no longer include services that were included at no cost like standard delivery and installation.
				Instead, as a new My Best Buy Total member, you’ll occasionally get exclusive access to promotional service offers like discounted installation.
			</div>
			<BrixButton buttonStyle='link' onClick={() => setWrapperClose(false)} className={`${cb}__closeButton`}>Close</BrixButton>
		</div>)
	}

	return (<>
		<BrixOverlayWrapper
			id='overlay-id'
			overlay={overlay}
			isVisible={wrapperClose}
			position='top'
			className={`${cb}__overlayWrapper`}
			arrowOffset={globals.isLargeView ? {left: '13px'} : {left: '176px'}}
			preventPlacement={true}
			loadWhileClosed={true}
		>
			<BrixInformationButton className={`${cb}__informationIcon`} onClick={() => setWrapperClose(true)} />
		</BrixOverlayWrapper>
	</>)

}