import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import {ConnectedVehicleSelectorPage} from './vehicle-selector/vehicle-selector-page'
import {ConnectedServiceSelectorPage} from './service-selector/service-selector-page'
import {ConnectedStoreSelectorPage} from './store-selector/store-selector-page'
import {ConnectedCustomerDetailsPage} from './customer-details/customer-details-page'
import {ConnectedReviewPage} from './review/review-page'
import {ConnectedConfirmationPage} from './confimation/confirmation-page'
import * as router from './actions/routing'
import {listener} from './actions/routing'
import {ConnectedSearchPage} from './search/search-page'
import {ConnectedSearchResultsPage} from './search/search-results-page'
import {CancelConfirmation} from './search/cancel-confirmation'
import {SearchType} from './utils/constants'
import querystring from 'query-string'
import {ConnectedAppointmentDetails} from './components/appointment-details'

export class MainLayout extends Component {

	componentDidMount() {
		this.props.listener(this.props.history)
	}

	componentWillReceiveProps(nextProps) {
		router.handle(this.props.router, nextProps.router, this.props.history)
	}

	render() {
		return (
			<Switch>
				<Route path='/vehicle-selector' component={ConnectedVehicleSelectorPage}/>
				<Route path='/service-selector' component={ConnectedServiceSelectorPage}/>
				<Route path='/store-selector' component={ConnectedStoreSelectorPage}/>
				<Route path='/customer-details' component={ConnectedCustomerDetailsPage}/>
				<Route path='/review' component={ConnectedReviewPage}/>
				<Route path='/confirmation' component={ConnectedConfirmationPage}/>
				<Route path='/search/results' component={ConnectedSearchResultsPage}/>
				<Route path='/cancel/confirmation' component={CancelConfirmation}/>

				<Route path='/search/:encryptedConfirmationNumber' render={props =>
					<ConnectedSearchResultsPage {...props} searchType={SearchType.ENCRYPTED_SEARCH}/>}/>

				<Route path='/search' render={props => {
					const queryParams = querystring.parse(props.location.search)
					if (queryParams.t1 && queryParams.t2) {
						return <ConnectedSearchResultsPage {...props} searchType={SearchType.T1T2_SEARCH}/>
					}
					return <ConnectedSearchPage {...props}/>
				}}/>

				<Route path='/appointment-details' component={ConnectedAppointmentDetails} />

				<Route path='*' component={ConnectedVehicleSelectorPage}/>
			</Switch>
		)
	}
}

MainLayout.propTypes = {
	router: PropTypes.object
}

const mapStateToProps = state => ({
	router: state.router
})

const mapDispatchToProps = (dispatch) => ({
	listener: (history) => dispatch(listener(history)),
})

export const ConnectedMainLayout = connect(mapStateToProps, mapDispatchToProps)(MainLayout)
