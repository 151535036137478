import React from 'react'
import PropTypes from 'prop-types'
import globals from '../globals'
import {BrixButton} from '@bestbuy/brix-web'

export const AppointmentParts = ({
    partList = []
}) => {
    const noImgUrl = 'https://pisces.bbystatic.com/image2/vector/BestBuy_US/Gallery/image_placeholder-217379.svg'
    return (
        <>
            <ul className='apptPrepList v-m-top-xs v-m-bottom-reset list-unstyled'>
                {partList && partList.map((part, index) => (
                    <li className='Part' key={index}>
                        {part.imageUrl ? <img src={part.imageUrl} className='PartImage' alt={part.productDescription} /> : 
                            <img src={noImgUrl} className='PartImage' alt='noImage' />
                        }
                        {
                            part.productUrl &&
                            <BrixButton
                                buttonStyle='default-link'
                                href={part.productUrl}
                                target='_blank'
                            >{part.productDescription}
                            </BrixButton>
                        }
                        {
                            !part.productUrl &&
                            <div className={`${globals.isLargeView ? 'body-copy-lg' : 'body-copy'}`}>
                                <div><b>Call to order 1-888-237-8289</b></div>
                                <div>{part.productDescription}</div>
                            </div>
                        }
                    </li>
                ))}
            </ul>
        </>
    )
}

AppointmentParts.propTypes = {
    partList: PropTypes.array.isRequired,
}
