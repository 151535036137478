import * as Types from '../actions/action-types'
import {supportedFacets} from '../utils/supported-facets'

export function facetsReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.RETRIEVE_VEHICLE_SUCCESS: {
			const vehicleFacets = action.vehicle.facets.map(facet => ({id: facet.persistent_id, name: facet.name}))
			return {data: vehicleFacets.filter(obj => supportedFacets().includes(obj.id))}
		}

		case Types.ADD_FACET: {
			const selection = Array.isArray(state.selection) ? state.selection.slice() : []
			selection.push({})
			return {...state, selection}
		}

		case Types.REMOVE_FACET: {
			let selection
			if (state.selection) {
				selection = state.selection.slice()
				selection.splice(action.index, 1)
			}
			if (Array.isArray(selection) && selection.length) {
				return {...state, selection}
			}
			return {...state, selection: undefined}
		}

		case Types.SET_FACET: {
			let selection
			let index = action.index || 0
			selection = Array.isArray(state.selection) ? state.selection.slice() : []

			if (selection.length < index) {
				index = selection.length
			}

			selection[index] = action.facet
			return {...state, selection}
		}

		default:
			return state
	}
}
