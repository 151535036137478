import * as Actions from './action-types'
import {baseDelete} from '../api/utils'
import {push, setFlowType} from './routing'
import {determineNextRoute} from '../actions/session'

export function cancelAppointment() {
	return (dispatch, getState) => {
		dispatch({type: Actions.CANCEL_APPOINTMENT})
		dispatch(setFlowType({isCancelAppointment: true}))

		const state = getState()
		if ((state.user || {}).email) {
			return baseDelete(`${state.metaLayer.env_appServer}/appointment/${state.searchPageUI.confirmationNumber}`)
			.then(() => {
				dispatch({type: Actions.CANCEL_APPOINTMENT_SUCCESS})
				dispatch(push('/cancel/confirmation'))
			})
			.catch(err => dispatch({type: Actions.CANCEL_APPOINTMENT_FAILURE}))

		}
		else {
			dispatch(determineNextRoute())
			return Promise.resolve()
			}
				
		}
}
