import Bootstrap from './bootstrap-data'
import {toLowerCase} from './utils/string-utils'

const defaultLiveConfig = {addPartsLink: true}

const globals = {
	isLargeView: toLowerCase(Bootstrap.metaLayer.device_deviceClass) !== 's',
	nodePath: Bootstrap.metaLayer.env_appServer,
	liveConfig: {...defaultLiveConfig, ...(Bootstrap.liveConfig || {})}
}

globals.isSmallView = !globals.isLargeView

Object.freeze(globals)
export default globals
