import {setFacet} from './set-facet'
import {setPackage} from './packages'
import {push} from './routing'
import * as Types from './action-types'

export function setConsultationSelection(url) {
	return (dispatch, getState) => {
		const state = getState()
		const consultFacetExists = !!(state.facets && state.facets.data && state.facets.data[0] && state.facets.data[0].id === 'consultation')
		const consultPackageExists = !!(state.packages && state.packages.data && state.packages.data.consultation && state.packages.data.consultation[0])

		if (consultFacetExists && consultPackageExists) {
			dispatch(setFacet(state.facets.data[0], 0))
			dispatch(setPackage(state.packages.data.consultation[0]))
			dispatch(push(url))
		}
		else {
			dispatch({type: Types.RETRIEVE_VEHICLE_FAILURE})
		}
	}
}
