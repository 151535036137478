import * as Types from './action-types'

export function setFacet(id, index) {
	return dispatch => dispatch({type: Types.SET_FACET, facet: id, index})
}

export function addFacet(id) {
	return dispatch => dispatch({type: Types.ADD_FACET})
}

export function removeFacet(id, index) {
	return dispatch => dispatch({type: Types.REMOVE_FACET, id, index})
}
