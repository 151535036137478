import React from 'react'
import PropTypes from 'prop-types'
import bbyLogo from '../images/best-buy-logo.svg'
import amp from '../images/facets/amp.svg'
import ampActive from '../images/facets/amp-active.svg'
import radio from '../images/facets/radio.svg'
import radioActive from '../images/facets/radio-active.svg'
import remotestart from '../images/facets/remotestart.svg'
import remotestartActive from '../images/facets/remotestart-active.svg'
import satellite from '../images/facets/satellite.svg'
import satelliteActive from '../images/facets/satellite-active.svg'
import smartphone from '../images/facets/smartphone.svg'
import smartphoneActive from '../images/facets/smartphone-active.svg'
import speaker from '../images/facets/speaker.svg'
import speakerActive from '../images/facets/speaker-active.svg'
import cameraGps from '../images/facets/camera-gps.svg'
import cameraGpsActive from '../images/facets/camera-gps-active.svg'
import fleet from '../images/facets/fleet.svg'
import fleetActive from '../images/facets/fleet-active.svg'

const cb = 'auto-tech-facet-image'

export class FacetImage extends React.Component {
	render() {
		let {name = 'bbyLogo', active} = this.props
		name = active ? `${name}Active` : name

		const imageMap = {
			bbyLogo, bbyLogoActive: bbyLogo,
			amp, ampActive,
			camera_gps: cameraGps, camera_gpsActive: cameraGpsActive,
			fleet, fleetActive,
			radio, radioActive,
			remotestart, remotestartActive,
			satellite, satelliteActive,
			smartphone, smartphoneActive,
			speaker, speakerActive
		}

		return (
			<div className={cb}>
				<img src={imageMap[name]} className={`${cb}__facet-icon`} alt={`${name} facet icon`} aria-hidden/>
			</div>
		)
	}
}

FacetImage.propTypes = {
	name: PropTypes.string,
	active: PropTypes.bool
}
