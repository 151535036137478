//This function takes n number of arguments, and checks if every argument has a length property
export function hasTruthyLength(...args) {
	return args.every(arg => !!(arg && arg.length))
}

export function cloneArray(array) {
	if (typeof array === 'object') {
		return JSON.parse(JSON.stringify(array))
	}
}
