import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrixSelect} from '@bestbuy/brix-web'
import {emptyFunction} from '../utils/constants'

const cb = 'auto-tech-service-facet-speaker'

export class ServiceFacetSpeaker extends Component {

	render() {
		const {selectedPackages = [], packages = [], setPackage, removePackage, subCategory} = this.props

		const options = [{text: 'None', value: 'None'}].concat(packages.map(pkg => ({text: pkg.shortName, value: pkg.id})))
		const selected = selectedPackages.find(pkg => packages.some(selected => pkg.id === selected.id)) || {}

		return (
			<div className={cb}>
				<div className={`${cb}__title`}>{subCategory}</div>
				<BrixSelect className={`${cb}__dropdown`}
							options={options}
							value={selected.id || 'None'}
							onChange={e => (e.target.value !== 'None' ? setPackage(this.findPackage(e.target.value)) : removePackage(selected))}/>
			</div>
		)
	}

	findPackage = (id) => this.props.packages.find(pkg => pkg.id === id)
}

ServiceFacetSpeaker.defaultProps = {
	setPackage: emptyFunction,
	removePackage: emptyFunction,
}

ServiceFacetSpeaker.propTypes = {
	packages: PropTypes.array,
	selectedPackages: PropTypes.array,
	setPackage: PropTypes.func,
	removePackage: PropTypes.func,
	subCategory: PropTypes.string
}
