import * as Types from './action-types'
import {baseFetch} from '../api/utils'
import {DEFAULT_DROPDOWN_VALUE} from '../utils/constants'

export function retrieveMakes() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_MAKES})

		const state = getState()

		if (state.years.selection === DEFAULT_DROPDOWN_VALUE) {
			return new Promise(function (resolve) {
				resolve(dispatch({type: Types.RETRIEVE_MAKES_FAILURE}))
			})
		}

		return baseFetch(`${state.metaLayer.env_appServer}/carfit/vehicles?year=${state.years.selection}`)
			.then(
				data => dispatch({type: Types.RETRIEVE_MAKES_SUCCESS, makes: data.makes}),
				err => dispatch({type: Types.RETRIEVE_MAKES_FAILURE})
			)
	}
}

export function setMake(make) {
	return dispatch => dispatch({type: Types.SET_MAKE, make})
}
