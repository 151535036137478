import Moment from 'moment'
import {DAY_LABEL_MONTH_DATE, DAY_LABEL_FULL_DATE, HOUR_MINUTE_AMPM} from './constants'

export default function formatSearchResponse(data = {}) {
	const location = data.location || {}
	let bossAppointmentProperties = {}

	if (data.isBossAppointment) {
		bossAppointmentProperties.bbyOrderId = data.bbyOrderId
		bossAppointmentProperties.isBossAppointment = true
	}

	return {
		...bossAppointmentProperties,
		confirmationNumber: data.confirmationNumber,
		duration: data.duration ? Number(data.duration) : undefined,
		locationId: location.locationId,
		date: data.appointmentStartTime ? Moment(data.appointmentStartTime).format(DAY_LABEL_MONTH_DATE) : undefined,
		appointmentDate: data.appointmentStartTime ? Moment(data.appointmentStartTime).format(DAY_LABEL_FULL_DATE) : undefined,
		start: data.appointmentStartTime ? Moment(data.appointmentStartTime).format(HOUR_MINUTE_AMPM) : undefined,
		end: data.appointmentEndTime ? Moment(data.appointmentEndTime).format(HOUR_MINUTE_AMPM) : undefined,
		firstName: data.firstName,
		lastName: data.lastName,
		year: data.year,
		make: data.make,
		model: data.model,
		trim: data.trim,
		serviceTypes: data.serviceTypes,
		services: determineServices(data.serviceTypes),
		isOpen: data.appointmentStatus !== 'Completed' && data.appointmentStatus !== 'Cancelled',
		isCompleted: data.appointmentStatus === 'Completed',
		isCancelled: data.appointmentStatus === 'Cancelled',
		address: {
			displayName: location.displayName,
			addressLineOne: location.addr1,
			addressLineTwo: location.addr2,
			city: location.city,
			state: location.state,
			zip: location.zipCode,
			phone: location.phone
		},
		suppressReschedule: data.suppressReschedule
	}
}

const determineServices = (serviceTypes) => {
	if (serviceTypes) {
		const uniqueServices = [...new Set(serviceTypes.map(serviceType => serviceType.category))]
		return uniqueServices
			.filter(service => service !== '')
			.filter(service => service !== 'Material Fee')
	}
	return []
}
