export function salvageError(resp) {
	return resp.json()
		.then(errJSON => {
				throw new Error(JSON.stringify(errJSON))
			},
			err => {
				throw new Error(JSON.stringify({}))
			})
}


export function baseFetch(url, options = {}) {
	const baseOptions = {
		credentials: 'same-origin',
		headers: {
			Accept: 'application/json'
		}
	}

	return fetch(url, {...baseOptions, ...options})
		.then((resp) => {
			if (resp.ok) {
				return resp.json()
			}

			return salvageError(resp)
		})
}

export function baseDelete(url) {
	return fetch(url, {credentials: 'same-origin', method: 'delete'})
		.then(resp => {
			if (!resp.ok) {
				throw new Error(resp.body)
			}
		})
}
