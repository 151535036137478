import React from 'react'
import PropTypes from 'prop-types'

const cb = 'auto-tech-dollar-amount'

export const DollarAmount = ({amount, strikeout, hideCents}) => {
	let value = null

	if (typeof amount === 'number') {
		if (hideCents) {
			value = `$${Math.floor(amount)}`
		}
		else {
			value = `$${amount.toFixed(2)}`
		}
	}

	return (
		<span className={`${cb} ${strikeout ? `${cb}__strikeout` : ''}`}>
			{value}
		</span>
	)
}

DollarAmount.propTypes = {
	amount: PropTypes.number,
	strikeout: PropTypes.bool,
	hideCents: PropTypes.bool,
}
