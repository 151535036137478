import * as Types from '../../actions/action-types'

export function appointmentDetailsReducer(state = {}, action = {}) {
    switch (action.type) {
        case Types.SEARCH_BY_TOKENIZED_CONFIRMATION:
            return {...state, loading: true}

        case Types.SEARCH_BY_TOKENIZED_CONFIRMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                failure: false,
                tasks: action.tasks,
                confirmationNumber: action.confirmationNumber,
                specialOrderParts: action.specialOrderParts,
                calendarUrl: action.calendarUrl
            }

        case Types.SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE:
            return {...state, loading: false, failure: true}

        default:
            return state
    }
}
