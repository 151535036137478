import * as Actions from '../../actions/action-types'
import * as StringUtils from '../../utils/string-utils'

export function searchPageUIReducer(state = {}, action = {}) {
	switch (action.type) {
		case Actions.SET_SEARCH_CONFIRMATION_NUMBER:
			return {...state, confirmationNumber: StringUtils.trim(action.confirmationNumber)}

		case Actions.SET_SEARCH_LASTNAME:
			return {...state, lastName: action.lastName}

		case Actions.SEARCH_FOR_APPOINTMENT:
			return {...state, submitting: true, hasBeenSubmitted: true, failure: false, invalid: false, empty: false}

		case Actions.SEARCH_FOR_APPOINTMENT_SUCCESS:
			return {...state, submitting: false}

		case Actions.SEARCH_FOR_APPOINTMENT_FAILURE:
			return {...state, submitting: false, failure: true}

		case Actions.SEARCH_FOR_APPOINTMENT_INVALID:
			return {...state, submitting: false, invalid: true}

		case Actions.SEARCH_FOR_APPOINTMENT_EMPTY:
			return {...state, submitting: false, empty: true}

		default:
			return state
	}
}
