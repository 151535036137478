import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrixButton, BrixInputWrapper, BrixInput} from '@bestbuy/brix-web'
import {trim} from '../utils/string-utils'
import {isZip} from '../utils/form-validation-utils'
import {emptyFunction} from '../utils/constants'

const cb = 'auto-tech-zip-entry'

export class ZipEntry extends Component {

	state = {
		isFirstLoad: true
	}

	componentWillMount() {
		this.setState({zip: this.props.zip})
	}

	render() {
		const isFirstLoad = this.state.isFirstLoad

		return (
			<div className={cb}>
				<form className={`${cb}__form`} onSubmit={this.handleSubmit}>
					<BrixInputWrapper aria-invalid={!isFirstLoad && !this.isValid()}
									errorMessage={{
										id: `${cb}__zip-id`,
										text: 'Please enter a valid ZIP code.'
									}}>
						<label htmlFor='form-zip-name'>Enter ZIP Code</label>
						<BrixInput id='form-zip-name'
								defaultValue={this.props.zip}
								onChange={(event) => this.setState({zip: event.target.value})}/>
					</BrixInputWrapper>
					<BrixButton className={`${cb}__submit-button`}
							  buttonStyle='secondary'
							  disabled={false}
							  onClick={this.handleSubmit}>
						Continue
					</BrixButton>
				</form>
			</div>
		)
	}

	getTrimmedZip = () => trim(this.state.zip)

	isValid = () => isZip(this.getTrimmedZip())

	handleSubmit = (e) => {
		e.preventDefault()
		this.setState({isFirstLoad: false})
		if (this.isValid()) {
			this.props.submitHandler(this.getTrimmedZip())
		}
	}
}

ZipEntry.defaultProps = {
	submitHandler: emptyFunction
}

ZipEntry.propTypes = {
	zip: PropTypes.string,
	submitHandler: PropTypes.func
}
