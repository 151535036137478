import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {BrixButton, BrixModal} from '@bestbuy/brix-web'
import {humanizeDuration} from '../utils/string-utils'
import {AppointmentCalendar} from './appointment-calendar'
import {CancelModal} from '../search/cancel-modal'
import globals from '../globals'

export const AppointmentConfirmation = ({
    year,
    make,
    model,
    storeUrl,
    storeDisplayName,
    date,
    time,
    duration,
    rescheduleAppointment,
    cancelAppointment,
    calendarUrl,
    serviceTypes,
    phone
}) => {
    const safetyMeasuresButtonProps = {
        buttonStyle: 'link',
        href: '/site/shop-confidently/in-store-consultation-safety-precautions/pcmcat1588011072758.c?id=pcmcat1588011072758',
        target: '_blank',
        className: 'body-copy learn'
    }
    const [showModal, setShowModal] = useState(false)

    const installationCategory = serviceTypes && [...new Set(serviceTypes.map((data) => data.category))]
    
    return (
        <div className='confirmationDetails'>
            <h2 className='heading-6 v-m-bottom-xs'>Your Appointment</h2>
            <div className='confirmationDetailsInner'>
                <h3 className='heading-6 v-m-bottom-xs body-copy-lg'>Car Electronics Service</h3>
                <div className='mt-150 mb-50'>
                    <div>{year} {' '} {make} {' '} {model}</div>
                    {
                        installationCategory && installationCategory.map((data,ind)=> <div key={ind}>{data}</div>)
                    }
                </div>
                <div className='mt-150 mb-50'>
                    <p className='storeName'>
                        <BrixButton buttonStyle='link'
                            className='storeName'
                            href={storeUrl}
                            target='_blank'>
                            {storeDisplayName}
                        </BrixButton>
                    </p>

                    <strong className='storeDetails'>
                        <span>{`${phone} then dial #2240`}</span>
                        <br />
                        <span>{date}</span>
                        <br />
                        <span>{time}</span>
                        <br />
                        <span>Estimated Install time: {humanizeDuration(duration)}</span>
                    </strong>
                </div>

                <div className='mt-150 mb-50'>
                    <p className='install'>Installation Bay may be around the side or back of the store.</p>
                </div>

                <div className='mt-150 mb-50'>
                    <BrixButton className='reschedule_cancel_buttons'
                        buttonStyle='secondary'
                        onClick={rescheduleAppointment}>
                        Reschedule Appointment
                    </BrixButton>
                </div>
                <div className='mt-150 mb-50'>
                    <BrixButton className='reschedule_cancel_buttons'
                        buttonStyle='outline'
                        onClick={() => setShowModal(true)}>
                        Cancel Appointment
                    </BrixButton>
                </div>
                {showModal && (
                    <BrixModal autoFocus='false' size={globals.isLargeView ? 'medium' : 'small-view'} onClose={() => setShowModal(false)}>
                        <CancelModal cancelAppointment={cancelAppointment} hide={() => setShowModal(false)}/>
                    </BrixModal>
				)}
                <div className='mt-150 mb-50'>
                    <AppointmentCalendar url={calendarUrl?.calendarLinks?.addToCalendarUrl} />
                </div>
                <div className='mt-150 mb-50'>
                    <BrixButton {...safetyMeasuresButtonProps}>
                        Learn about safety measures at our stores
                    </BrixButton>
                </div>
            </div>

        </div>)
}

AppointmentConfirmation.propTypes = {
    year: PropTypes.string.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    storeUrl: PropTypes.string.isRequired,
    storeDisplayName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    cancelAppointment: PropTypes.func.isRequired,
    rescheduleAppointment: PropTypes.func.isRequired,
    calendarUrl: PropTypes.object,
    serviceTypes: PropTypes.array,
    phone: PropTypes.string
}