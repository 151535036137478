import * as router from './routing'
import {buildConsultUrl} from '../utils/flow'

export function determineNextRoute() {
	return (dispatch, getState) => {
		const state = getState()
		const {isConsult, isReschedule, isCancelAppointment, isRescheduleAppointment} = state.router || {}
		if (isReschedule) {
			dispatch(router.push('/review'))
		}
		else if ((state.user || {}).email) {
			if(state?.user?.isRescheduleAppointment){
				dispatch(router.push('/review'))
			}
		else {
				let url = buildConsultUrl(isConsult, '/customer-details')
				dispatch(router.push(url))
			}
			

		}
		else {
			const redirect = isCancelAppointment ? '/cancel/confirmation' : isRescheduleAppointment? '/store-selector' : isConsult ? '/consult' :  ''
			optional(() => window.sessionStorage.setItem('autotech-state', JSON.stringify({...getState(), user: {...getState().user, restoreSession: true, restoreSessionCompleted: false, isCancelAppointment: isCancelAppointment || false, isRescheduleAppointment: isRescheduleAppointment || false}})))
			location.assign(`/services/autotech/api/cia/token?redirect=/services/autotech${redirect}`)
		}
	}
}

export function retrieveState() {
	let state = optional(() => JSON.parse(window.sessionStorage.getItem('autotech-state'))) || {}
	optional(() => window.sessionStorage.removeItem('autotech-state'))
	return state
}

const optional = (func) => {
	try {
		return func()
	}
	catch (error) {
		return null
	}
}
