import React, {Component} from 'react'
import {connect} from 'react-redux'
import {TitleAndProgress} from '../components/title-and-progress'
import {BrixButton, BrixInputWrapper, BrixInput} from '@bestbuy/brix-web'
import PropTypes from 'prop-types'
import {searchForAppointment, setConfirmationNumber, setLastName} from '../actions/search'
import {invalidConfirmationNumber, invalidName} from '../utils/form-validation-utils'
import {GeneralMessage} from '../components/general-message'
import {ERROR} from '../utils/constants'
import globals from '../globals'
import * as routing from '../actions/routing'

const cb = 'auto-tech-search'

export class SearchPage extends Component {
	render = () => {
		const {setConfirmationNumber, setLastName, searchForAppointment} = this.props
		const {confirmationNumber, lastName, submitting, hasBeenSubmitted, failure, invalid, empty} = this.props.uiState
		const isLargeView = globals.isLargeView

		return (
			<div className={`${cb} ${isLargeView ? `${cb}__background` : ''}`}>
				<div className={`${cb}__all-content-wrapper`}>

					<TitleAndProgress title='Professional installation for car electronics' showProgressBar={false}>
						Have a Geek Squad&reg; Autotech take care of your install needs.
					</TitleAndProgress>

					{failure && !invalid && <GeneralMessage usedBy={ERROR.SEARCH_FAILED}/>}
					{empty && <GeneralMessage usedBy={ERROR.SEARCH_EMPTY}/>}

					<div className={`${cb}__options-wrapper`}>
						<div className={`${cb}__schedule-content`}>
							<h2 className={`${cb}__schedule-title`}>New Appointment</h2>
							<p className={`${cb}__schedule-description`}>Set up a time and location at your convenience.</p>
							<BrixButton className={`${cb}__schedule-link`}
									  buttonStyle='secondary'
									  onClick={() => this.props.push('/vehicle-selector')}>
								Schedule Now
							</BrixButton>
						</div>

						<div className={`${cb}__reschedule-content`}>
							<h2 className={`${cb}__reschedule-title`}>Change Appointment</h2>
							<p className={`${cb}__reschedule-description`}>To cancel or reschedule your appointment, first locate it by entering your confirmation number and last name.</p>

							<form className={`${cb}__form`}
								  onSubmit={(e) => this.submitForm(e, submitting, searchForAppointment)}>
								<BrixInputWrapper aria-invalid={invalidConfirmationNumber(confirmationNumber) && hasBeenSubmitted}
												errorMessage={{
													id: `${cb}__confirmation-number-id`,
													text: 'Please enter a confirmation number.'
												}}>
									<label className={`${cb}__confirmation-number-label`} htmlFor={`${cb}__confirmation-number`}>Confirmation Number</label>
									<BrixInput id={`${cb}__confirmation-number`}
											className={`${cb}__confirmation-number-input`}
											value={confirmationNumber}
											onChange={e => setConfirmationNumber(e.target.value)}/>
								</BrixInputWrapper>

								<BrixInputWrapper aria-invalid={invalidName(lastName) && hasBeenSubmitted}
												errorMessage={{
													id: `${cb}__last-name-id`,
													text: 'Please enter your last name.'
												}}>
									<label className={`${cb}__last-name-label`} htmlFor={`${cb}__last-name`}>Last Name</label>
									<BrixInput id={`${cb}__last-name`}
											className={`${cb}__last-name-input`}
											value={lastName}
											onChange={e => setLastName(e.target.value)}/>
								</BrixInputWrapper>
								<BrixButton className={`${cb}__submit-button ${submitting ? 'btn-trailing-ficon' : ''}`}
										  type='submit'
										  buttonStyle='secondary'
										  disabled={submitting}>
									Find Appointment
									{submitting && <i className='spinner' aria-label='loading'/>}
								</BrixButton>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}

	submitForm = (e, submitting, searchForAppointment) => {
		e.preventDefault()
		if (!submitting) {
			searchForAppointment()
		}
	}
}

SearchPage.propTypes = {
	push: PropTypes.func,
	searchForAppointment: PropTypes.func,
	setConfirmationNumber: PropTypes.func,
	setLastName: PropTypes.func,
	uiState: PropTypes.object
}

export const mapStateToProps = state => ({
	uiState: state.searchPageUI
})

export const mapDispatchToProps = dispatch => ({
	push: route => dispatch(routing.push(route)),
	setConfirmationNumber: input => dispatch(setConfirmationNumber(input)),
	setLastName: input => dispatch(setLastName(input)),
	searchForAppointment: () => dispatch(searchForAppointment())
})

export const ConnectedSearchPage = connect(mapStateToProps, mapDispatchToProps)(SearchPage)
