import * as api from '../api/utils'
import * as Types from './action-types'

export function retrieveUserDetails() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_USER_DETAILS})

		const state = getState()
		return api.baseFetch(`${state.metaLayer.env_appServer}/user/details`)
			.then(
				details => dispatch({type: Types.RETRIEVE_USER_DETAILS_SUCCESS, details}),
				err => dispatch({type: Types.RETRIEVE_USER_DETAILS_FAILURE})
			)
	}
}

export function resetRestoreSession() {
	return {type: Types.RESET_RESTORE_SESSION}
}

export function restoreSessionCompleted() {
	return {type: Types.RESTORE_SESSION_COMPLETED}
}
