import * as Types from '../actions/action-types'

export function modelsReducer(state = {}, action = {}) {

	switch (action.type) {
		case Types.SET_MODEL:
			return {...state, selection: action.model}

		case Types.RETRIEVE_MODELS_SUCCESS:
			return {data: action.models}

		case Types.RETRIEVE_MODELS_FAILURE:
			return {...state, error: true}

		case Types.SET_YEAR:
		case Types.SET_MAKE:
			return {}

		default:
			return state
	}
}
