import React from 'react'
import {searchForTokenizedConfirmationNumber} from '../actions/search'
import {useEffect} from 'react'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import {push, setFlowType} from '../actions/routing'
import {AppointmentPrepList} from './appointment-prep-list'
import {AppointmentConfirmation} from './appointment-confirmation'
import {AppointmentParts} from './appointment-parts'
import {cancelAppointment} from '../actions/cancel'

export const AppointmentDetails = ({
    searchForAppoinment,
    cancelAppointment,
    navigateToSearchPage,
    rescheduleAppointment,
    location,
    loading,
    failure,
    tasks,
    confirmationNumber,
    appointmentDetails,
    specialOrderParts,
    calendarUrl
}) => {


    useEffect(() => {
        const {token} = queryString.parse(location && location.search)
        if (!token) {
            navigateToSearchPage()
        }
        searchForAppoinment(token)
    }, [])

    useEffect(() => {
        if (failure) {
            navigateToSearchPage()
        }
    }, [failure])


    const Spinner = () => (
        <div className='flex justify-content-center align-items-center'>
            <i className='spinner' aria-label='loading' />
        </div>
    )

    const getAppointmentConfirmationProps = () => ({
        year: appointmentDetails.year,
        make: appointmentDetails.make,
        model: appointmentDetails.model,
        storeDisplayName: `Best Buy ${appointmentDetails?.address?.displayName}`,
        storeUrl: `//stores.bestbuy.com/${appointmentDetails?.locationId}`,
        date: appointmentDetails.appointmentDate,
        time: appointmentDetails.start,
        duration: appointmentDetails.duration,
        rescheduleAppointment,
        cancelAppointment,
        calendarUrl,
        serviceTypes: appointmentDetails.serviceTypes,
        phone: appointmentDetails?.address?.phone
    })

    if (loading) {
        return <Spinner />
    }

    if (!appointmentDetails || !tasks) {
        return null
    }

    const taskLists = tasks.map(task => {
        const taskCode = task?.taskCode
        const taskDescription = task?.fragmentResponse?.attributes?.bodyCopy
        const url = task?.fragmentResponse?.attributes?.linkTo
        const taskActionText = task?.fragmentResponse?.attributes?.callToActionText
        const taskDisplayText = task?.fragmentResponse?.attributes?.subhead
        return {taskDescription, url, taskActionText, taskDisplayText, taskCode}
    })

    return (
        <>
            <div className='container pureWhiteBackground'>
                <div className='apptPrepRow'>
                    <section className='apptPrepContent'>
                        <h1 className='apptPrepHeading'>
                            Autotech appointment details
                        </h1>
                        <h2 className='apptPrepSubheading'>
                            Your appointment confirmation number: {' '} <b>{confirmationNumber}</b>
                        </h2>
                        <hr className='apptPrepLineBreak'/>
                        <h3 className='apptPrepSubheading2 heading-5'>
                            What to bring.
                        </h3>
                        <AppointmentPrepList taskList={taskLists} />
                        <AppointmentParts partList={specialOrderParts} />
                    </section>
                    <aside className='apptPrepSidebar'>
                        <AppointmentConfirmation {...getAppointmentConfirmationProps()} />
                    </aside>
                </div>
            </div>
        </>
    )
}

AppointmentDetails.propTypes = {
    searchForAppoinment: PropTypes.func.isRequired,
    cancelAppointment: PropTypes.func.isRequired,
    rescheduleAppointment: PropTypes.func.isRequired,
    navigateToSearchPage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    failure: PropTypes.bool,
    location: PropTypes.object,
    calendarUrl: PropTypes.object,
    tasks: PropTypes.array,
    confirmationNumber: PropTypes.string,
    specialOrderParts: PropTypes.array
}


export const mapStateToProps = state => {
    return {
        loading: state.appointmentDetailsUI?.loading,
        failure: state.appointmentDetailsUI?.failure,
        tasks: state.appointmentDetailsUI?.tasks,
        confirmationNumber: state.appointmentDetailsUI?.confirmationNumber,
        specialOrderParts: state.appointmentDetailsUI?.specialOrderParts,
        appointmentDetails: state.search,
        calendarUrl: state.appointmentDetailsUI?.calendarUrl
    }
}

export const mapDispatchToProps = dispatch => ({
    cancelAppointment: () => dispatch(cancelAppointment()),
    searchForAppoinment: (token) => dispatch(searchForTokenizedConfirmationNumber(token)),
    navigateToSearchPage: () => dispatch(push('/search')),
    rescheduleAppointment: () => {
        dispatch(setFlowType({isReschedule: true}))
        dispatch(push('/store-selector'))
    }
})

export const ConnectedAppointmentDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails))