import * as Types from '../actions/action-types'
import {toLowerCase} from '../utils/string-utils'
import {supportedFacets} from '../utils/supported-facets'
import globals from '../globals'
import {IMAGE_UNAVAILABLE_URL, PRODUCT_DETAILS_API_STATUS} from '../utils/constants'
import {cloneArray, hasTruthyLength} from '../utils/utils'

export function packagesReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.RETRIEVE_VEHICLE_SUCCESS:
			return {data: extractPackages(action.vehicle)}

		case Types.SET_PACKAGE: {
			let selection
			if (isConsultation(action.package)) {
				selection = [action.package]
			}
			else {
				selection = (state.selection || [])
					.filter(pkg => !pkg.exclusive || pkg.facet.id !== action.package.facet.id || (pkg.exclusive && pkg.subCategory !== action.package.subCategory))
					.filter(pkg => !isAdditionalItem(pkg))
					.concat(action.package)

				if (globals.liveConfig.showAdditionalItems) {
					selection.push(...additionalItems)
				}
			}

			return {...state, selection, summary: summarizePackages(selection)}
		}

		case Types.CALC_PACKAGE_TOTAL_PRICE: {
			const selections = cloneArray(state.selection)
			selections.forEach(selection => selection.price = findTotalPackagePrice(selection))

			return {...state, selection: selections}
		}

		case Types.BUILD_SERVICE_SUMMARY: {
			return {...state, summary: summarizePackages(state.selection)}
		}

		case Types.PRODUCT_DETAIL_SUCCESS: {
			let offerSavingsPackage
			let pkgToBeUpdated

			if (state.selection && action.package && action.productDetails) {
				pkgToBeUpdated = state
					.selection
					.find(selectionPkg => selectionPkg.id === action.package.id)
				pkgToBeUpdated.productDetailsApiStatus = PRODUCT_DETAILS_API_STATUS.SUCCESS
				pkgToBeUpdated.skus = mergeProductDetails(action.package, action.productDetails, action.totalTechMember, action.paidMember)

				const laborSKUs = getLabor(action.package).map(labor => labor.sku)
				offerSavingsPackage = calcOfferSavingsForPackage(laborSKUs, action.productDetails)

				if (offerSavingsPackage) {
					pkgToBeUpdated.totalOfferSavings = offerSavingsPackage
				}
			}

			return {...state}
		}

		case Types.PRODUCT_DETAIL_FAILURE: {
			if (state.selection && action.package) {
				state
					.selection
					.find(selectionPkg => selectionPkg.id === action.package.id)
					.productDetailsApiStatus = PRODUCT_DETAILS_API_STATUS.FAIL
			}
			return {...state}
		}

		case Types.REMOVE_PACKAGE: {
			const selections = removeAdditionalItemsIfNecessary(state.selection.filter(pkg => pkg.id !== action.package.id))
			return {...state, selection: selections, summary: summarizePackages(selections)}
		}

		case Types.REMOVE_FACET: {
			let selections = []
			if (state.selection) {
				selections = removeAdditionalItemsIfNecessary(state.selection.filter(pkg => pkg.facet.id !== action.id))
			}
			return {...state, selection: selections, summary: summarizePackages(selections)}
		}

		case Types.HIDE_PACKAGE_PARTS_AND_LABOR: {
			return {...state, showPartsAndLabor: false, parts: undefined, labor: undefined}
		}

		case Types.SHOW_PACKAGE_PARTS_AND_LABOR: {
			return {
				...state,
				showPartsAndLabor: true,
				partsAndLaborFacetName: action.partsAndLabor && action.partsAndLabor.facet && action.partsAndLabor.facet.name || '',
				parts: getParts(action.partsAndLabor),
				labor: getLabor(action.partsAndLabor),
				totalOfferSavings: action.partsAndLabor && action.partsAndLabor.totalOfferSavings,
			}
		}

		default:
			return state
	}
}

export const getParts = pkg => {
	if (pkg && pkg.skus) {
		return pkg.skus.filter(sku => sku.availability !== 'labor')
	}
	return []
}

export const getLabor = pkg => {
	if (pkg && pkg.skus) {
		return pkg.skus.filter(sku => sku.availability === 'labor')
	}
	return []
}

export const calcOfferSavingsForPackage = (laborSKUs, products) => {
	if (hasTruthyLength(laborSKUs, products)) {
		const laborsWithDetails = products.filter(product => {
			return laborSKUs.includes(Number.parseInt(product.skuId))
		})
		return laborsWithDetails.reduce((total, labor) => total + (labor.offerSavings || 0), 0)
	}
}

const extractPackages = (vehicle) =>
	supportedFacets().reduce((facets, facet) => {
		facets[facet] = createPackage(vehicle, facet)
		return facets
	}, {})

const createPackage = (vehicle, id) => {
	const facet = vehicle.facets.find(facet => facet.persistent_id === id) || {}
	const packages = (facet.packages || []).map(pkg => ({
		name: pkg.name,
		shortName: pkg.shortName,
		duration: pkg.install_time,
		price: pkg.price,
		id: pkg.persistent_id,
		exclusive: pkg.exclusive,
		facet: {id: facet.persistent_id, name: facet.name},
		subCategory: pkg.subCategory,
		skus: (pkg.labor || []).map((sku) => ({
			sku: sku.sku,
			model: sku.model,
			manufacturer: sku.manufacturer || '',
			imageUrl: sku.image_url || IMAGE_UNAVAILABLE_URL,
			availability: toLowerCase(sku.availability),
			description: getDescription(sku.description, sku.availability, facet.name),
			name: sku.name,
			price: sku.price,
			specialOrder: toLowerCase(sku.availability) === 'online' || undefined,
			duration: sku.install_time || undefined
		}))
	}))

	packages.forEach(pkg => {
		const specialOrder = pkg.skus.find(it => it.specialOrder)
		pkg.hasSpecialOrder = !!specialOrder || undefined
	})

	return packages
}

const getDescription = (skuDescription, skuAvailability, facetName) => {
	if (skuDescription) {
		return skuDescription
	}
	if (toLowerCase(skuAvailability) === 'labor') {
		return `${facetName} Installation`
	}
	return 'Required Part'
}

export const findTotalPackagePrice = (pkg) => {
	if (pkg && hasTruthyLength(pkg.skus)) {
		return pkg.skus.reduce((total, sku) => total + (sku.price || 0), 0)
	}
}

const summarizePackages = (packages) => {
	const summary = packages.reduce((total, pkg) => ({
		price: total.price + (pkg.price || 0),
		duration: total.duration + pkg.duration,
		totalOfferSavingsAllPackages: total.totalOfferSavingsAllPackages + (pkg.totalOfferSavings || 0),
		hasSpecialOrder: total.hasSpecialOrder || pkg.hasSpecialOrder,
		skus: total.skus.concat((pkg.skus || []).map(sku => sku.sku))
	}), {
		price: 0,
		duration: 0,
		totalOfferSavingsAllPackages: 0,
		skus: []
	})

	summary.price = summary.price.toFixed(2)
	summary.skus = Array.from(new Set(summary.skus))

	return summary
}

const removeAdditionalItemsIfNecessary = (selections) => {
	if (selections.every(pkg => isAdditionalItem(pkg))) {
		return []
	}
	return selections
}

const isConsultation = (pkg) => (pkg.facet || {}).id === 'consultation'
export const isAdditionalItem = (pkg) => (pkg.facet || {}).name === 'Additional Items'

//We only show Vehicle Inspection on the UI and there is no SKU
//The actual appointment submission only gets the Material Fee SKU sent
const additionalItems = [
	{
		price: 5,
		duration: 0,
		name: 'Materials Fee',
		facet: {name: 'Additional Items'},
		skus: [{
			sku: 4081923,
			name: 'Material Fee',
			price: 5,
			duration: 15
		}]
	},
	{
		price: 0,
		duration: 15,
		name: 'Vehicle Inspection',
		facet: {name: 'Additional Items'},
		skus: []
	}
]

const mergeProductDetails = (pkg, productDetails, totalTechMember, paidMember) => {
	return pkg.skus.map(pkgSku => {
		const matchingProductDetailSku = productDetails
			.find(productDetailSku => `${pkgSku.sku}` === productDetailSku.skuId) || {}

		const hasOfferPrice = matchingProductDetailSku.offerPrice || matchingProductDetailSku.offerPrice === 0

		let apiFieldsToKeep = {}
		if (matchingProductDetailSku.model) {
			apiFieldsToKeep.model = matchingProductDetailSku.model
		}
		if (matchingProductDetailSku.imageUrl) {
			apiFieldsToKeep.imageUrl = matchingProductDetailSku.imageUrl
		}
		if (matchingProductDetailSku.productDescription) {
			apiFieldsToKeep.productDescription = matchingProductDetailSku.productDescription
		}
		if (matchingProductDetailSku.originalPrice) {
			apiFieldsToKeep.originalPrice = matchingProductDetailSku.originalPrice
		}
		if (matchingProductDetailSku.offerSavings) {
			apiFieldsToKeep.offerSavings = matchingProductDetailSku.offerSavings
		}
		if (hasOfferPrice) {
			apiFieldsToKeep.offerPrice = matchingProductDetailSku.offerPrice
		}
		if ((totalTechMember || paidMember) && hasOfferPrice) {
			apiFieldsToKeep.price = matchingProductDetailSku.offerPrice
		}
		if (!(totalTechMember || paidMember) && matchingProductDetailSku.originalPrice) {
			apiFieldsToKeep.price = matchingProductDetailSku.originalPrice
		}

		return {...pkgSku, ...apiFieldsToKeep}
	})
}
