import './webpack-public-path'
<script type='nomodule'>
	import 'core-js/stable' //provided by bby header or externals if no header available
</script>
import '@shop/lib-eventmanager' //needed for analytics
import React from 'react'
import ReactDOM from 'react-dom'
import {applyMiddleware, compose, createStore} from 'redux'
import {Provider} from 'react-redux'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import thunk from 'redux-thunk'
import reducers from './reducers'
import Bootstrap from './bootstrap-data'
import './main-entry.less'
import {geolocateZip} from './actions/zip'
import {retrieveUserDetails} from './actions/user'
import * as Session from './actions/session'
import globals from './globals'
import {loadBing} from './actions/load-bing'
import {ConnectedMainLayout} from './main-layout'
import {analyticsMiddleware} from './middleware/analytics'
import * as Actions from './actions/action-types'
import queryString from 'query-string'

const getInitialState = () => {
	const state = reducers(undefined, {})

	return {
		...state,
		...Session.retrieveState(),
		metaLayer: Bootstrap.metaLayer,
		router: {
			isConsult: window.location.pathname.includes('/consult') || queryString.parse(window.location.search).ref === 'c'
		}
	}
}

const configureStore = () => (
	createStore(
		reducers,
		getInitialState(),
		compose(applyMiddleware(analyticsMiddleware, thunk), window.devToolsExtension ? window.devToolsExtension() : (f) => f)
	)
)

let store
if (module.hot) {
	store = global.__REDUX_STORE = global.__REDUX_STORE || configureStore()
	module.hot.accept('./reducers', () => {
		store.replaceReducer(require('./reducers').default)
	})
	module.hot.accept()
}
else {
	store = configureStore()
}

store.dispatch(geolocateZip())
if(window.location.pathname !== '/services/autotech/appointment-details'){
	store.dispatch(retrieveUserDetails())
}
if (Bootstrap.metaLayer.env !== 'mock-cypress') {
	store.dispatch(loadBing())
}
store.dispatch({type: Actions.NAVIGATE, location: {pathname: '/'}, action: 'PUSH'})

const AppRoot = () => {
	return (
		<Provider store={store}>
			<BrowserRouter basename='/services/autotech'>
				<div className={globals.isLargeView ? '' : 'auto-tech-small-view'}>
					<Switch>
						<Route path='*' component={ConnectedMainLayout}/>
					</Switch>
				</div>
			</BrowserRouter>
		</Provider>
	)
}

ReactDOM.render(
	<AppRoot />,
	document.getElementById('auto-tech-wrapper')
)

export {AppRoot}
