import * as Types from './action-types'
import {baseFetch} from '../api/utils'
import {DEFAULT_DROPDOWN_VALUE} from '../utils/constants'

export function retrieveModels() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_MODELS})

		const state = getState()

		if (state.makes.selection === DEFAULT_DROPDOWN_VALUE) {
			return new Promise(function (resolve) {
				resolve(dispatch({type: Types.RETRIEVE_MODELS_FAILURE}))
			})
		}

		return baseFetch(`${state.metaLayer.env_appServer}/carfit/vehicles?year=${state.years.selection}&make=${state.makes.selection}`)
			.then(
				data => dispatch({type: Types.RETRIEVE_MODELS_SUCCESS, models: data.models}),
				err => dispatch({type: Types.RETRIEVE_MODELS_FAILURE})
			)
	}
}

export function setModel(model) {
	return dispatch => dispatch({type: Types.SET_MODEL, model})
}
