import * as Types from '../actions/action-types'

export function trimsReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.SET_TRIM:
			return {...state, selection: action.trim}

		case Types.RETRIEVE_TRIMS_SUCCESS:
			return {data: action.trims}

		case Types.RETRIEVE_TRIMS_FAILURE:
			return {...state, error: true}

		case Types.SET_YEAR:
		case Types.SET_MAKE:
		case Types.SET_MODEL:
			return {}

		default:
			return state
	}
}
