import Moment from 'moment'
import * as _ from 'lodash'
import {HOUR_MINUTE_AMPM, STD_DATE, STD_DATE_TIME} from './constants'

export const calendarResponse = {
	formatResponse(slots, start, end) {

		if (!Array.isArray(slots) || (!slots.length && !start && !end)) {
			return {}
		}

		const firstSlot = slots.length ? slots[0] : null
		const lastSlot = slots.length ? slots[slots.length - 1] : null
		const startDate = (start) ? Moment(start, STD_DATE).startOf('d') : Moment(firstSlot.startTime, STD_DATE_TIME).startOf('d')
		const endDate = (end) ? Moment(end, STD_DATE).startOf('d') : Moment(lastSlot.startTime, STD_DATE_TIME).startOf('d')
		const days = endDate.diff(startDate, 'd')
		const baseRange = this.setBaseRange(startDate, days)
		return this.updateBaseRange(baseRange, slots)
	},

	setBaseRange(startDate, days) {
		const dateRange = {}

		for (let i = 0; i <= days; i++) {
			const setMoment = startDate.clone().add(i, 'd')
			const date = setMoment.format(STD_DATE)
			dateRange[date] = {
				verified: true,
				disabled: true
			}
		}
		return dateRange
	},

	updateBaseRange(baseRange = {}, slots = []) {
		const base = _.cloneDeep(baseRange)

		slots.forEach((slot) => {
			if (slot.startTime && !isNaN(slot.duration)) {
				const slotDuration = Number(slot.duration)
				const endTime = Moment(slot.startTime, STD_DATE_TIME).add(slotDuration, 'm')
				const date = Moment(slot.startTime, STD_DATE_TIME).format(STD_DATE)
				const dateTime = Moment(slot.startTime, STD_DATE_TIME).format(STD_DATE_TIME)
				const start = Moment(slot.startTime, STD_DATE_TIME).format(HOUR_MINUTE_AMPM)
				const end = Moment(endTime, STD_DATE_TIME).format(HOUR_MINUTE_AMPM)

				if (base[date]) {
					const day = base[date]

					day.disabled = false
					if (!day.slots) {
						day.slots = {}
					}
					day.slots[dateTime] = {start, end, resources: slot.resources}
				}
			}
		})
		return base
	}
}
