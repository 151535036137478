import * as Types from '../actions/action-types'
import * as _ from 'lodash'

export function storePageUIReducer(state = {}, action = {}) {

	switch (action.type) {
		case Types.RETRIEVE_STORES_SUCCESS:
		case Types.SET_FACET:
		case Types.REMOVE_FACET:
		case Types.SET_PACKAGE:
		case Types.REMOVE_PACKAGE:
			return {bingError: state.bingError, bingLoaded: state.bingLoaded}

		case Types.SET_SLOT:
			return mergeSingleStoreData(state, action)

		case Types.SELECT_SLOT: {
			const {locationId} = action
			return {...state, selection: {locationId, ...state[locationId]}}
		}

		case Types.RETRIEVE_INITIAL_SLOTS:
			if (action.locationId) {
				const loadingMultipleStores = state.loadingMultipleStores || []
				loadingMultipleStores.push(action.locationId)
				return {...state, loadingMultipleStores}
			}
			return state

		case Types.RETRIEVE_INITIAL_SLOTS_SUCCESS:
		case Types.RETRIEVE_INITIAL_SLOTS_FAILURE:
			return purgeStoreSpinnerByLocationId(state, action.locationId)

		case Types.RETRIEVE_SLOTS:
			return mergeSingleStoreData(state, {...action, storeLoading: true})

		case Types.RETRIEVE_SLOTS_SUCCESS:
		case Types.RETRIEVE_SLOTS_FAILURE:
			return mergeSingleStoreData(state, {...action, storeLoading: false})

		case Types.BING_MAPS_LOADING:
			return {...state, bingError: false, bingLoaded: false}

		case Types.BING_MAPS_LOADED_SUCCESS:
			return {...state, bingError: false, bingLoaded: true}

		case Types.BING_MAPS_LOADING_ERROR:
			return {...state, bingError: true, bingLoaded: false}

		case Types.SET_FOCUSED_STORE:
			return {...state, focusedLocationId: action.locationId}

		default:
			return state
	}
}

function mergeSingleStoreData(state = {}, action = {}) {
	if (action.locationId) {
		//todo majerus: FIRE-3559 - Find a way to safely remove type and slots from 'action' without being clever or mutating the object
		// eslint-disable-next-line no-unused-vars
		const {locationId, type, slots, ...rest} = action  //declaring type to pull out of the 'rest' remainder object
		const storeData = {}
		storeData[locationId] = {...state[locationId], ...rest}
		return {...state, ...storeData, selection: undefined}
	}
	return state
}

function purgeStoreSpinnerByLocationId(state = {}, locationId) {
	const loadingStores = state.loadingMultipleStores || []

	if (locationId && loadingStores.length) {
		return {...state, loadingMultipleStores: _.without(loadingStores, locationId)}
	}
	return state
}
