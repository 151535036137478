import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {BrixButton, BrixIcon} from '@bestbuy/brix-web'
import {ConnectedSelectedServicePage} from './selected-service'
import {GeneralMessage} from '../components/general-message'
import {emptyFunction, ERROR, phoneNumber} from '../utils/constants'
import globals from '../globals'
import {Sticky} from 'react-sticky'
import {humanizeDuration} from '../utils/string-utils'

const cb = 'auto-tech-service-summary'

export class ServiceSummary extends Component {

	static propTypes = {
		packages: PropTypes.object,
		push: PropTypes.func,
		facet: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object
		]),
		removePackage: PropTypes.func,
		showPackagePartsAndLabor: PropTypes.func,
		toggleSummary: PropTypes.func,
		expanded: PropTypes.bool,
		confirmSummary: PropTypes.bool,
		scheduleEnabled: PropTypes.bool,
		vehiclePageUI: PropTypes.object,
		ui: PropTypes.object,
		user: PropTypes.object,
		suppressFleetInformation: PropTypes.bool,
	}

	static defaultProps = {
		push: emptyFunction,
		removePackage: emptyFunction,
		showPackagePartsAndLabor: emptyFunction,
		toggleSummary: emptyFunction
	}

	shouldComponentUpdate(nextProps) {
		return !nextProps.ui.suspendRendering
	}

	render() {
		const {scheduleEnabled, vehiclePageUI = {}, confirmSummary, expanded, user, suppressFleetInformation} = this.props
		const {selection: selectedPackages = [], summary = {}} = this.props.packages

		const packagesByFacet = {}
		selectedPackages.forEach(pkg => packagesByFacet[(pkg.facet || {}).name] = (packagesByFacet[(pkg.facet || {}).name] || []).concat(pkg))

		if (globals.isSmallView) {
			const showServiceSummary = !!summary.duration || expanded
			const hasSelectedPackages = selectedPackages.length > 0

			return (
				<div>
					<Sticky>
						{props => (
							<div className={cb} style={props.style}>
								<SummaryTitle user={user}/>

								{!showServiceSummary && (
									<div className={`${cb}__no-services`}>
										<div className={`${cb}__icon`}><BrixIcon icon='open-box'/></div>
										<div className={`${cb}__help-text`}>Pick services and see your installation estimate here</div>
									</div>
								)}

								{showServiceSummary && this.buildEstimateSummary(summary, user, suppressFleetInformation, expanded, confirmSummary, this.props.toggleSummary)}

								{!scheduleEnabled && (
									<div className={`${cb}__error`}>
										<GeneralMessage usedBy={ERROR.DURATION_SMALL} tiny={true}/>
									</div>
								)}
							</div>
						)}
					</Sticky>

					{expanded && (
						<div>
							{hasSelectedPackages && this.buildSummaryBreakdown(packagesByFacet, suppressFleetInformation)}

							{!hasSelectedPackages && (
								<div className={`${cb}__empty-summary-container`}>
									<div className={`${cb}__empty-summary-text`}>No services are selected</div>
									<a className={`${cb}__empty-summary-link`}
									   href='javascript:void(0)'
									   onClick={() => this.props.toggleSummary()}>Back to select service</a>
								</div>
							)}

							{!suppressFleetInformation && <Disclaimer/>}

							{this.buildSubmitButtonContent(selectedPackages, scheduleEnabled, confirmSummary)}
						</div>
					)}
				</div>
			)
		}

		return (
			<div className={cb}>
				<div className={`${cb}__divider-container`}>
					<div className={`${cb}__your-vehicle`}>
						{vehiclePageUI.year} {vehiclePageUI.make} {vehiclePageUI.model}
					</div>

					<div className={`${cb}__divider`}/>

				
					<div  className= {`${cb}__space`}/>
					{this.buildEstimateSummary(summary, user, suppressFleetInformation)}
				</div>

				{this.buildSummaryBreakdown(packagesByFacet, suppressFleetInformation)}

				{!suppressFleetInformation && <Disclaimer/>}

				{this.buildSubmitButtonContent(selectedPackages, scheduleEnabled)}

				<div className={`${cb}__help-text`}>Have Questions? Call {phoneNumber}</div>
			</div>
		)
	}

	buildEstimateSummary = (summary = {}, user, suppressFleetInformation, expanded, confirmSummary, toggleSummary) => {
		const duration = humanizeDuration(summary.duration) || '0 min.'
		const price = summary.price || '0.00'

		let buttonText = 'Expand'
		let buttonStyle = 'outline'

		if (expanded && confirmSummary) {
			buttonText = 'Back'
		}
		else if (expanded) {
			buttonText = 'Collapse'
		}

		return (
			<div className={`${cb}__summary-block`}>
				{globals.isLargeView && <SummaryTitle user={user}/>}
				{!suppressFleetInformation && <div className={`${cb}__summary-price-est`}>${price}</div>}

				{(!!summary.duration || expanded) && (
					<div className={`${cb}__summary-time-est ${Number(price) >= 1000 ? `${cb}__summary-time-est--less-margin` : ''}`}>
						<div className={`${cb}__summary-time-title`}>{globals.isSmallView ? 'Est. Install Time' : 'Estimated Install Time'}</div>
						<div className={`${cb}__summary-time-value`}>{duration}</div>
					</div>
				)}

				{globals.isSmallView && (
					<BrixButton onClick={toggleSummary}
							  buttonStyle={buttonStyle}
							  size='small'
							  className={`${cb}__toggle-button`}>
						{buttonText}
					</BrixButton>
				)}
			</div>
		)
	}

	buildSummaryBreakdown = (packagesByFacet, suppressFleetInformation) =>
		<div className={`${cb}__breakdown`}>
			{Object.keys(packagesByFacet).map(facetKey =>
				<ul className={`${cb}__summary-list`} key={facetKey}>
					<div className={`${cb}__facet-name`}>{facetKey}</div>

					{packagesByFacet[facetKey].map((pkg, idx) =>
						<li className={`${cb}__summary-entry`} key={idx}>
							<ConnectedSelectedServicePage pkg={pkg} removePackage={this.props.removePackage} showPackagePartsAndLabor={this.props.showPackagePartsAndLabor} suppressFleetInformation={suppressFleetInformation}/>
						</li>
					)}
				</ul>
			)}
		</div>

	buildSubmitButtonContent = (selectedPackages, scheduleEnabled, confirmSummary) => {
		const renderButton = globals.isLargeView || (globals.isSmallView && confirmSummary)
		const button = !!(selectedPackages.length && scheduleEnabled) &&
			<BrixButton className={`${cb}__submit-button`}
					  buttonStyle='secondary'
					  onClick={() => this.props.push('/store-selector')}>
				Schedule Service
			</BrixButton>

		const svServiceLink = (
			<a className={`${cb}__to-selections-link`}
			   href='javascript:void(0)'
			   onClick={() => this.props.toggleSummary()}>
				Back to select service
			</a>
		)

		return (
			<div className={`${cb}__submit-wrapper`}>
				{renderButton && button}
				{globals.isSmallView && svServiceLink}
			</div>
		)
	}
}

function SummaryTitle({user}) {
	return (
		<h2 className={`${cb}__summary-title`}>Estimate</h2>
	)
}

SummaryTitle.propTypes = {
	user: PropTypes.object,
}

function Disclaimer() {
	return (
		<div className={`${cb}__disclaimer`}>
			<p>This estimate includes labor and additional parts required for the installation of your product which is sold separately.</p>
		</div>
	)
}