import * as Types from './action-types'
import {baseFetch} from '../api/utils'
import {push} from './routing'
import {invalidConfirmationNumber, invalidName} from '../utils/form-validation-utils'
import * as StringUtils from '../utils/string-utils'
import moment from 'moment'

export function searchForAppointment() {
	return (dispatch, getState) => {
		dispatch({type: Types.SEARCH_FOR_APPOINTMENT})

		const state = getState()
		const searchUI = state.searchPageUI || {}
		const confirmationNumber = searchUI.confirmationNumber
		const lastName = StringUtils.trim(searchUI.lastName)

		if (invalidConfirmationNumber(confirmationNumber) || invalidName(lastName)) {
			return dispatch({type: Types.SEARCH_FOR_APPOINTMENT_INVALID})
		}

		return baseFetch(`${state.metaLayer.env_appServer}/appointment/${confirmationNumber}/${encodeURIComponent(lastName)}`)
			.then(data => {
				if (Object.keys(data).length) {
					if (data.isAutoTechAppointment) {
						dispatch({type: Types.SEARCH_FOR_APPOINTMENT_SUCCESS, ...data})
						dispatch(push('/search/results'))
					}
					else {
						window.location.href = `/services/triage/modify?cn=${confirmationNumber}`
					}
				}
				else {
					dispatch({type: Types.SEARCH_FOR_APPOINTMENT_EMPTY})
				}
			})
			.catch(err => dispatch({type: Types.SEARCH_FOR_APPOINTMENT_FAILURE}))
	}
}

export function searchForEncryptedAppointment(encryptedConfirmationNumber) {
	return (dispatch, getState) => {
		dispatch({type: Types.SEARCH_BY_ENCRYPTED_CONFIRMATION})

		return baseFetch(`${getState().metaLayer.node}/appointment/${encryptedConfirmationNumber}`)
			.then(data => {
				if (Object.keys(data).length) {
					dispatch({type: Types.SEARCH_FOR_APPOINTMENT_SUCCESS, ...data})
					dispatch({type: Types.SET_SEARCH_CONFIRMATION_NUMBER, confirmationNumber: data.confirmationNumber})

				}
				else {
					dispatch({type: Types.SEARCH_FOR_APPOINTMENT_EMPTY})
				}
			})
			.catch(err => dispatch({type: Types.SEARCH_FOR_APPOINTMENT_FAILURE}))
	}
}

export function searchForT1T2Appointment(queryString) {
	return (dispatch, getState) => {
		dispatch({type: Types.SEARCH_BY_ENCRYPTED_CONFIRMATION})
		return baseFetch(`${getState().metaLayer.node}/appointment${queryString}`)
			.then(data => {
				if (Object.keys(data).length) {
					dispatch({type: Types.SEARCH_FOR_APPOINTMENT_SUCCESS, ...data})
					dispatch({type: Types.SET_SEARCH_CONFIRMATION_NUMBER, confirmationNumber: data.confirmationNumber})

				}
				else {
					dispatch({type: Types.SEARCH_FOR_APPOINTMENT_EMPTY})
				}
			})
			.catch(err => dispatch({type: Types.SEARCH_FOR_APPOINTMENT_FAILURE}))
	}
}

export function searchForTokenizedConfirmationNumber(token) {
	return (dispatch) => {
		dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION})
		return baseFetch(`${window.location.origin}/services/triage/appointment/detokenizer/retrieve?token=${token}`)
			.then(({payload}) => {
				dispatch(searchForAppointmentDetails(payload.confirmationNumber))
			})
			.catch((err) => dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE}))
	}
}

function searchForAppointmentDetails(confirmationNumber) {
	return (dispatch, getState) => {
		return baseFetch(`${getState().metaLayer.env_appServer}/appointment-details/${confirmationNumber}`)
			.then(data => {
				if(data.appointment_details.appointmentStatus === 'Cancelled'){
					dispatch(push('/cancel/confirmation'))
				} 
				else {
					dispatch(searchForAppointmentPrepTasks(confirmationNumber, data))
				}
			})
			.catch((err) => dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE}))
	}
}

function searchForAppointmentPrepTasks(confirmationNumber, appointmentData) {
	return (dispatch) => {
		const itemsData = appointmentData?.appointment_details?.items.map((data) => {
			return {
				...data,
				appointmentType: 'AUTOTECH'
			}
		})
		const payload = {
			sourceSystem: 'DOTCOM',
			uniqueIdentifier: confirmationNumber,
			orderNumber: confirmationNumber,
			appointmentDt: appointmentData?.appointment_details?.appointmentStartTime,
			appointmentLocation: 'PRECINCT',
			items: itemsData
		}
		const options = {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify(payload)
		}
		return baseFetch(`${window.location.origin}/services/triage/appointments/tasks`, options)
			.then((taskData) => dispatch(setCalendarLinks(confirmationNumber, appointmentData, taskData)))
			.catch(() => dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE}))
	}
}

function setCalendarLinks(confirmationNumber, appointmentData, taskData){
	const payload = {
	startDateTime: moment(appointmentData?.appointment_details?.appointmentStartTime).toISOString(),
        endDateTime: moment(appointmentData?.appointment_details?.appointmentEndTime).toISOString(),
        address:{
            addr1: appointmentData?.appointment_details?.location?.addr1,
            addr2: appointmentData?.appointment_details?.location?.addr2,
            city: appointmentData?.appointment_details?.location?.city,
            state: appointmentData?.appointment_details?.location?.state,
            zipCode: appointmentData?.appointment_details?.location?.zipCode
        }
	}
	const options = {
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify(payload)
	}
	return (dispatch) => {
		return baseFetch (`${window.location.origin}/services/triage/calendar/links`, options)
		.then((url) => {
			const actionData = {
				appointmentDetails: appointmentData?.appointment_details,
				tasks: taskData?.tasks,
				confirmationNumber,
				specialOrderParts: appointmentData?.special_order_parts,
				calendarUrl: url
			}
			dispatch({type: Types.SET_SEARCH_CONFIRMATION_NUMBER, confirmationNumber})
			dispatch({type: Types.SEARCH_FOR_APPOINTMENT_SUCCESS, ...actionData.appointmentDetails})
			dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION_SUCCESS, ...actionData})
		})
		.catch(() => dispatch({type: Types.SEARCH_BY_TOKENIZED_CONFIRMATION_FAILURE}))
	}
}
export function setConfirmationNumber(confirmationNumber) {
	return dispatch => dispatch({type: Types.SET_SEARCH_CONFIRMATION_NUMBER, confirmationNumber})
}

export function setLastName(lastName) {
	return dispatch => dispatch({type: Types.SET_SEARCH_LASTNAME, lastName})
}
