import React from 'react'

/*
 * External URLs
 */
export const profileOrderDetailsUrl = bbyOrderId => `/profile/ss/orders/order-details?orderId=${bbyOrderId}`
export const IMAGE_UNAVAILABLE_URL = 'https://pisces.bbystatic.com/image2/BestBuy_US/store/images/global/misc/unavailable.png'
export const TTS_LEARN_MORE_URL = '/site/electronics/totaltech/pcmcat1629315977983.c?id=pcmcat1629315977983'
export const TERMS_URL = '/site/help-topics/terms-and-conditions/pcmcat204400050067.c?id=pcmcat204400050067'
export const PRIVACY_POLICY = '/site/help-topics/privacy-policy/pcmcat204400050062.c?id=pcmcat204400050062'
export const WHAT_TO_EXPECT_URL = '/site/shop-confidently/in-car-service-safety-precautions/pcmcat1590591461119.c?id=pcmcat1590591461119'
export const SAFETY_MEASURES_URL = '/site/shop-confidently/in-store-consultation-safety-precautions/pcmcat1588011072758.c?id=pcmcat1588011072758)'

/*
 * Enums
 */
export const SearchType = {
	ENCRYPTED_SEARCH: 'ENCRYPTED_SEARCH',
	T1T2_SEARCH: 'T1T2_SEARCH'
}

export const ERROR = {
	SLOT_UNAVAILABLE: 'slotUnavailable',
	SUBMIT_SUCCESS: 'submitSuccess',
	SEARCH_FAILED: 'searchFailed',
	SEARCH_EMPTY: 'searchEmpty',
	CANCEL_FAILED: 'cancelFailed',
	DURATION: 'duration',
	DURATION_SMALL: 'duration-small',
	CANCELLED: 'cancelled',
	PAST: 'past',
	UNKNOWN: 'unknown'
}

export const PRODUCT_DETAILS_API_STATUS = {SUCCESS: 'SUCCESS', FAIL: 'FAIL', PENDING: 'PENDING'}

export const DEFAULT_DROPDOWN_VALUE = 'default'
export const RAM_HINT_FOR_DODGE_MODELS_TEXT = 'Ram: Change Dodge to Ram'
export const RAM_HINT_FOR_DODGE_MODELS_VALUE = 'default-ram-hint'

/*
 * Moment JS
 */
// NOTE the T between date and time is optional and accounted for
// No timezone attached to these
export const STD_DATE = 'YYYY-MM-DD'   // 2017-01-31
export const STD_DATE_TIME = 'YYYY-MM-DD HH:mm:ss'  // 2017-01-31 13:59:59
export const STD_DATE_TIME_WITH_T = 'YYYY-MM-DDTHH:mm:ss'  // 2017-01-31T13:59:59
export const DAY_LABEL_FULL_DATE = 'dddd, MMMM D, YYYY' // Tuesday, April 18, 2017
export const DAY_LABEL_MONTH_DATE = 'dddd, MMMM D' // Tuesday, April 18
export const CALENDAR_LABEL = 'MMMM YYYY' // April 2017
export const HOUR_MINUTE_AMPM = 'h:mm a'  // 1:30 pm

/*
 * Misc
 */
export const emptyFunction = () => {
}

export const noHref = 'javascript:void(0)'
export const CONSULT_PARAM = 'ref=c'

export const phoneNumber = '1-800-433-5778'
export const clickablePhone = phoneNumber => <a className='auto-tech-phone-number-link' href={`tel:${phoneNumber}`}>{phoneNumber}</a>
export const AUTOTECH_NUMBER = <span className='auto-tech-phone-number'>{phoneNumber}</span>
export const AUTOTECH_NUMBER_LINK = clickablePhone(phoneNumber)

export const OPT_IN = 'OPT_IN'
export const OPT_OUT = 'OPT_OUT'
export const FLEET_ID = 'fleet'

export const SAFE_CAR_SERVICE =  'https://www.bestbuy.com/SafeCarService'
