import * as Types from '../actions/action-types'

export function makesReducer(state = {}, action = {}) {

	switch (action.type) {
		case Types.SET_MAKE:
			return {...state, selection: action.make}

		case Types.RETRIEVE_MAKES_SUCCESS:
			return {data: action.makes}

		case Types.RETRIEVE_MAKES_FAILURE:
			return {...state, error: true}

		case Types.SET_YEAR:
			return {}

		default:
			return state
	}
}
