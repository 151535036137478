import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {BrixButton, BrixModal} from '@bestbuy/brix-web'
import {LaborLineItem} from './labor-line-item'
import {PartLineItem} from './part-line-item'
import {hasTruthyLength} from '../../utils/utils'
import {hidePackagePartsAndLabor} from '../../actions/packages'
import {MemberSavings} from './member-savings'

const cb = 'auto-tech-parts-and-labor-modal'

export class PartsAndLaborModal extends React.Component {

	static propTypes = {
		hidePackagePartsAndLabor: PropTypes.func,
		labor: PropTypes.array,
		parts: PropTypes.array,
		title: PropTypes.string,
		user: PropTypes.object,
		totalOfferSavings: PropTypes.number,
	}

	render() {
		const {hidePackagePartsAndLabor, labor, parts, title, user, totalOfferSavings} = this.props
		const hasParts = hasTruthyLength(parts)
		const hasLabor = hasTruthyLength(labor)
		const hasOfferSavings = !!totalOfferSavings

		return (
			<div className={cb}>
				<BrixModal className={`${cb}__modal c-modal-content a-fade a-fade-in`} autoFocus='true' onClose={hidePackagePartsAndLabor}>
					<Title title={title}/>

					{hasParts && <Parts parts={parts}/>}
					{hasLabor && <Labor labor={labor} isTotalTechMember={user.totalTechMember} isPaidMember={user.paidMember}/>}

					{hasOfferSavings && (
						<div className={`${cb}__member-savings-container`}>
							<MemberSavings user={user} totalSavings={totalOfferSavings} showIcon={true}/>
						</div>
					)}

					<div className={`${cb}__bottom`}>
						<BrixButton className={`${cb}__ok`}
								  buttonStyle='secondary'
								  onClick={hidePackagePartsAndLabor}>
							Ok
						</BrixButton>
					</div>
				</BrixModal>
			</div>
		)
	}
}

const Title = ({title}) =>
	<div className={`${cb}__title`}>
		<h1 className={`${cb}__title-heading`}>{title && `${title} Package`}</h1>
	</div>

Title.propTypes = {
	title: PropTypes.string,
}

const Parts = ({parts}) =>
	<div className={`${cb}__parts`}>
		<div className={`${cb}__header`}>
			<div>Parts</div>
			<div className={`${cb}__price`}>Price</div>
		</div>
		<div>
			<ul className={`${cb}__list`}>
				{parts.map((partLineItem, idx) => <PartLineItem key={idx} {...partLineItem}/>)}
			</ul>
		</div>
	</div>

Parts.propTypes = {
	parts: PropTypes.array,
}

const Labor = ({labor, isTotalTechMember, isPaidMember}) =>
	<div className={`${cb}__labor`}>
		<div className={`${cb}__header`}>
			<div>Labor</div>
			<div className={`${cb}__price`}>Price</div>
		</div>
		<div>
			<ul className={`${cb}__list`}>
				{labor.map((laborLineItem, idx) =>
					<LaborLineItem key={idx} isTotalTechMember={isTotalTechMember} isPaidMember={isPaidMember} {...laborLineItem}/>)}
			</ul>
		</div>
	</div>

Labor.propTypes = {
	labor: PropTypes.array,
	isTotalTechMember: PropTypes.bool,
	isPaidMember: PropTypes.bool,
}

export const mapStateToProps = (state) => {
	return {
		parts: state.packages.parts || [],
		labor: state.packages.labor || [],
		title: state.packages.partsAndLaborFacetName,
		totalOfferSavings: state.packages.totalOfferSavings,
		user: state.user,
	}
}

const mapDispatchToProps = (dispatch) => ({
	hidePackagePartsAndLabor: () => dispatch(hidePackagePartsAndLabor()),
})

export const ConnectedPartsAndLaborModal = connect(mapStateToProps, mapDispatchToProps)(PartsAndLaborModal)
