import * as Types from '../actions/action-types'
import {calendarResponse} from '../utils/calendar-response'
import {STD_DATE_TIME} from '../utils/constants'
import Moment from 'moment'

export function calendarsReducer(state = {}, action = {}) {
	switch (action.type) {

		case Types.RETRIEVE_STORES_SUCCESS:
		case Types.SET_FACET:
		case Types.REMOVE_FACET:
		case Types.SET_PACKAGE:
		case Types.REMOVE_PACKAGE:
			return {}

		case Types.RETRIEVE_INITIAL_SLOTS_SUCCESS: {
			const {slots, locationId} = action
			const firstAvailableDate = (slots[0] && slots[0].startTime) ? Moment(slots[0].startTime, STD_DATE_TIME).format(STD_DATE_TIME) : null
			const formattedDates = calendarResponse.formatResponse(slots)
			return {...state, [locationId]: {firstAvailableDate, dates: formattedDates}}
		}

		case Types.RETRIEVE_SLOTS_SUCCESS:
			if (Array.isArray(action.slots)) {
				const {slots, locationId, startDate, endDate} = action
				const formattedDates = calendarResponse.formatResponse(slots, startDate, endDate)
				let calendar = {}
				calendar[locationId] = state[locationId]
				calendar[locationId].dates = {...calendar[locationId].dates, ...formattedDates}
				return {...state, ...calendar}
			}
			return state

		case Types.RETRIEVE_INITIAL_SLOTS_FAILURE:
		case Types.RETRIEVE_SLOTS_FAILURE:
			return state

		case Types.SHOW_TIMES: {
			const calendarEntry = state[action.locationId]
			if (calendarEntry) {
				return {...state, [action.locationId]: {...calendarEntry, showTimes: true}}
			}
			return {...state}
		}

		case Types.SHOW_HIDE_MAP: {
			const calEntry = state[action.locationId]
			if (calEntry) {
				return {...state, [action.locationId]: {...calEntry, showMap: !(calEntry.showMap)}}
			}
			return {...state}
		}

		default:
			return state
	}
}
