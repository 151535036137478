import * as Actions from '../../actions/action-types'
import formatSearchResponse from '../../utils/search-response'

export function searchReducer(state = {}, action = {}) {
	switch (action.type) {
		case Actions.SEARCH_FOR_APPOINTMENT_SUCCESS:
			return {...state, ...formatSearchResponse(action)}

		default:
			return state
	}
}
