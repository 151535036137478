import React, {Component} from 'react'
import {BrixButton} from '@bestbuy/brix-web'

const cb = 'auto-tech-cancel-confirmation'

export class CancelConfirmation extends Component {
	render() {
		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>
					<div className={`${cb}__content`}>
						<div className={`${cb}__cancel-text`}>Your appointment has been cancelled.</div>
						<div className={`${cb}__button-wrapper`}>
							<BrixButton className={`${cb}__new-reservation-button`}
									  buttonStyle='secondary'
									  href='/services/autotech'
									  data-track='Make a New Reservation'>
								Make a New Appointment
							</BrixButton>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
