import * as Types from './action-types'
import {baseFetch} from '../api/utils'
import {PRODUCT_DETAILS_API_STATUS} from '../utils/constants'
import {getLabor, getParts} from '../reducers/packages'

export function setPackage(pack) {
	return (dispatch, getState) => {
		const state = getState()
		const parts = getParts(pack).map(part => part.sku).join(',')
		const labor = getLabor(pack).map(labor => labor.sku).join(',')

		if (!state.router.isConsult && (parts || labor)) {
			dispatch({type: Types.SERVICE_SUMMARY_SUSPEND_RENDERING_START})
			dispatch({type: Types.SET_PACKAGE, package: {...pack, productDetailsApiStatus: PRODUCT_DETAILS_API_STATUS.PENDING}})

			const url =
				state?.user.totalTechMember
					? `${state.metaLayer.env_appServer}/productprice/details?parts=${parts}&labors=${labor}&type=tts`
					: state?.user.paidMember
						? `${state.metaLayer.env_appServer}/productprice/details?parts=${parts}&labors=${labor}&type=pmem`
						: `${state.metaLayer.env_appServer}/productprice/details?parts=${parts}&labors=${labor}`

			return baseFetch(url)
				.then(data => {
					dispatch({type: Types.PRODUCT_DETAIL_SUCCESS, package: pack, productDetails: data, totalTechMember: state.user.totalTechMember, paidMember: state.user.paidMember})
					dispatch({type: Types.CALC_PACKAGE_TOTAL_PRICE})
					dispatch({type: Types.BUILD_SERVICE_SUMMARY})
					dispatch({type: Types.SERVICE_SUMMARY_SUSPEND_RENDERING_STOP})
				})
				.catch(error => {
					dispatch({type: Types.PRODUCT_DETAIL_FAILURE, package: pack})
					dispatch({type: Types.SERVICE_SUMMARY_SUSPEND_RENDERING_STOP})
				})
		}
		else {
			dispatch({type: Types.SET_PACKAGE, package: pack})
		}
	}
}

export function removePackage(pack) {
	return dispatch => dispatch({type: Types.REMOVE_PACKAGE, package: pack})
}

export function showPackagePartsAndLabor(pack) {
	return dispatch => dispatch({type: Types.SHOW_PACKAGE_PARTS_AND_LABOR, partsAndLabor: pack})
}

export function hidePackagePartsAndLabor(pack) {
	return dispatch => dispatch({type: Types.HIDE_PACKAGE_PARTS_AND_LABOR})
}
