import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {StickyContainer} from 'react-sticky'
import {BrixButton, BrixIcon} from '@bestbuy/brix-web'
import {TitleAndProgress} from '../components/title-and-progress'
import {ServiceFacet} from './service-facet'
import {addFacet, removeFacet, setFacet} from '../actions/set-facet'
import {ServicePackage} from './service-package'
import {removePackage, setPackage, showPackagePartsAndLabor} from '../actions/packages'
import {ServiceSummary} from './service-summary'
import {GeneralMessage} from '../components/general-message'
import globals from '../globals'
import {ServiceBlock} from './small/service-block'
import {BackLink} from '../components/back-link'
import * as routing from '../actions/routing'
import {ConnectedPartsAndLaborModal} from './parts-and-labor-modal/parts-and-labor-modal'
import {FLEET_ID} from '../utils/constants'

const cb = 'auto-tech-service-selector'

export class ServiceSelectorPage extends Component {

	static propTypes = {
		addFacet: PropTypes.func,
		facets: PropTypes.object,
		packages: PropTypes.object,
		push: PropTypes.func,
		removeFacet: PropTypes.func,
		removePackage: PropTypes.func,
		setFacet: PropTypes.func,
		setPackage: PropTypes.func,
		showPackagePartsAndLabor: PropTypes.func,
		user: PropTypes.object,
		vehiclePageUI: PropTypes.object,
		ui: PropTypes.object,
	}

	state = {}

	componentWillMount() {
		if (this.props.user.restoreSession && !this.props.user.restoreSessionCompleted) {
			this.props.push('/store-selector')
		}
	}

	render() {
		const {facets, setFacet, packages, setPackage, removePackage, showPackagePartsAndLabor, user, vehiclePageUI, ui} = this.props
		const {data, selection, showPartsAndLabor, summary} = packages
		const selectedPackages = selection || []
		const packagesByFacet = data || {}
		const duration = ((summary || {}).duration || 0)
		const durationOver6Hours = duration > 360
		const scheduleEnabled = !durationOver6Hours
		const isLargeView = globals.isLargeView
		const suppressFleetInformation = isFleetOnly(selectedPackages)

		if (isLargeView) {
			return (
				<div className={`${cb} ${cb}__background`}>
					{showPartsAndLabor && <ConnectedPartsAndLaborModal/>}

					<div className={`${cb}__all-content-wrapper ${cb}__all-content-wrapper--orange-stripe`}>
						<TitleAndProgress title='Choose your service'/>
						{durationOver6Hours && (
							<div className={`${cb}__error`}>
								<GeneralMessage usedBy='duration'/>
							</div>
						)}
						<div className={`${cb}__services`}>
							<ul className={`${cb}__services-list`}>
								{facets.data && facets.data.map((facet, idx) => {
									const selection = Array.isArray(facets.selection) ? facets.selection[0] : {}
									return (
										<ServiceFacet key={facet.id}
													  indexValue={idx}
													  facet={facet}
													  selected={selection.id === facet.id}
													  setFacet={setFacet}>
											<ServicePackage packages={packagesByFacet[facet.id]}
															setPackage={setPackage}
															removePackage={removePackage}
															selectedPackages={packages.selection}/>
										</ServiceFacet>
									)
								})}
							</ul>
						</div>

						<ServiceSummary packages={packages}
										user={user}
										push={this.props.push}
										removePackage={removePackage}
										facet={facets.selection}
										scheduleEnabled={scheduleEnabled}
										vehiclePageUI={vehiclePageUI}
										ui={ui}
										showPackagePartsAndLabor={showPackagePartsAndLabor}
										suppressFleetInformation={suppressFleetInformation}/>
					</div>
				</div>
			)
		}

		return (
			<StickyContainer>
				{showPartsAndLabor && <ConnectedPartsAndLaborModal/>}

				<ServiceSummary packages={packages}
								user={user}
								push={this.props.push}
								removePackage={removePackage}
								facet={facets.selection}
								scheduleEnabled={scheduleEnabled}
								toggleSummary={() => {
									this.scrollToTop()
									this.setState({showFullSummary: !this.state.showFullSummary, confirmSummary: false})
								}}
								expanded={this.state.showFullSummary}
								confirmSummary={this.state.confirmSummary}
								ui={ui}
								showPackagePartsAndLabor={showPackagePartsAndLabor}
								suppressFleetInformation={suppressFleetInformation}/>

				{!this.state.showFullSummary && (
					<div className={cb}>
						<div className={`${cb}__small-view-heading`}>
							<TitleAndProgress title='Select a service' currentPage='service-selector'/>
						</div>

						{facets.data && !facets.selection && <ServiceBlock index={0}
																		   facets={facets}
																		   onChange={setFacet}
																		   removeFacet={this.props.removeFacet}/>}

						{facets.data && facets.selection && facets.selection.map((sel, idx) =>
							<ServiceBlock index={idx}
										  key={idx}
										  id={sel.id}
										  facets={facets}
										  onChange={setFacet}
										  removeFacet={this.props.removeFacet}>
								<ServicePackage facetId={sel.id}
												packages={packagesByFacet[sel.id]}
												setPackage={setPackage}
												removePackage={removePackage}
												selectedPackages={selectedPackages}/>
							</ServiceBlock>
						)}

						{!! scheduleEnabled && facets.selection && facets.selection.every(selection => selection.id) && (
							<div className={`${cb}__add-button-container`}>
								<BrixButton className={`${cb}__add-button`}
										  buttonStyle='primary'
										  onClick={() => this.props.addFacet()}>
									<BrixIcon icon='mobile-add' className={'svg-size-s ficon-plus'}/>Add Another
									Service
								</BrixButton>
							</div>
						)}

						<div className={`${cb}__button-section`}>
							{!!(selectedPackages.length && scheduleEnabled) &&
							<BrixButton className={`${cb}__next-button`}
									  buttonStyle='secondary'
									  onClick={e => {
										  this.scrollToTop()
										  this.setState({confirmSummary: true, showFullSummary: true})
									  }}>
								Next
							</BrixButton>
							}
						</div>

						<BackLink onClick={e => this.props.push('/vehicle-selector')}/>
					</div>
				)}
			</StickyContainer>
		)
	}

	scrollToTop = () => window.scrollTo(0, 0)
}

export const isFleetOnly = selectedPackages => {
	let result
	selectedPackages.map(selection => {
		if (selection && selection.facet && selection.facet.id && selection.facet.id.toLowerCase() === FLEET_ID && result !== false) {
			result = true
		}
		else {
			result = false
		}
	})
	return result || false
}

export const mapStateToProps = (state) => ({
	facets: state.facets || {},
	packages: state.packages || {},
	user: state.user || {},
	vehiclePageUI: state.vehiclePageUI || {},
	ui: state.serviceSelectorPageUI || {},
})

export const mapDispatchToProps = (dispatch) => ({
	addFacet: () => dispatch(addFacet()),
	push: route => dispatch(routing.push(route)),
	removeFacet: (id, index) => dispatch(removeFacet(id, index)),
	removePackage: pkg => dispatch(removePackage(pkg)),
	setFacet: (facet, index) => dispatch(setFacet(facet, index)),
	setPackage: pack => dispatch(setPackage(pack)),
	showPackagePartsAndLabor: pkg => dispatch(showPackagePartsAndLabor(pkg)),
})

export const ConnectedServiceSelectorPage = connect(mapStateToProps, mapDispatchToProps)(ServiceSelectorPage)
