import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FacetImage} from './facet-image'
import {BrixIcon} from '@bestbuy/brix-web'
import {emptyFunction} from '../utils/constants'

const cb = 'auto-tech-service-facet'

export class ServiceFacet extends Component {

	render() {
		const {facet, setFacet, children, selected} = this.props
		const selectedClass = selected ? 'selected' : ''
		const classModifier = this.getPositionModifier(this.props.indexValue)

		if (!facet) {
			return null
		}

		return (
			<li className={cb}>
				<a id={`${cb}__${facet.id}`}
				   className={`${cb}__facet ${cb}__facet${classModifier} ${selectedClass}`}
				   href='javascript:void(0)'
				   onClick={() => setFacet(facet)}
				   data-track={`Service:${facet.name}`}>
					<div className={`${cb}__facet-name-wrapper`}>
						<div className={`${cb}__facet-name`}>{facet.name}</div>
					</div>
					<FacetImage name={facet.id} active={selected}/>
					<div className={`${selected ? 'fill-white-base' : ''}`}>
						<BrixIcon icon='caret-down' className={`svg-size-s`}/>
					</div>
				</a>

				{selected && (
					<div className={`${cb}__facet-questionaire ${cb}__facet-questionaire${classModifier}`}>
						<div className={`${cb}__facet-questionaire-content`}>{children}</div>
					</div>
				)}
			</li>
		)
	}

	getPositionModifier = (indexValue) => {
		const remainder = indexValue % 3

		if (remainder === 0) {
			return `--left`
		}
		if (remainder === 1) {
			return `--center`
		}
		if (remainder === 2) {
			return `--right`
		}
		return ''
	}
}

ServiceFacet.defaultProps = {
	setFacet: emptyFunction
}

ServiceFacet.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	indexValue: PropTypes.number,
	selected: PropTypes.bool,
	facet: PropTypes.object,
	setFacet: PropTypes.func
}
