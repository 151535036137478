import * as Types from '../actions/action-types'

export function serviceSelectorPageUI(state = {}, action = {}) {
	switch (action.type) {
		case Types.SERVICE_SUMMARY_SUSPEND_RENDERING_START:
			return {...state, suspendRendering: true}

		case Types.SERVICE_SUMMARY_SUSPEND_RENDERING_STOP:
			return {...state, suspendRendering: false}

		default:
			return state
	}
}
