import * as Actions from '../actions/action-types'

export function routerReducer(state = {}, action = {}) {
	switch (action.type) {
		case Actions.NAVIGATE:
		case Actions.PUSH:
			return {...state, location: action.location, action: action.action}

		case Actions.SET_FLOW_TYPE:
			return {...state, ...action.value}

		default:
			return state
	}
}
