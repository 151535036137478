import * as Types from './action-types'
import {baseFetch} from '../api/utils'
import {DEFAULT_DROPDOWN_VALUE} from '../utils/constants'

export function retrieveTrims() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_TRIMS})

		const state = getState()

		if (state.models.selection === DEFAULT_DROPDOWN_VALUE) {
			return new Promise(function (resolve) {
				resolve(dispatch({type: Types.RETRIEVE_TRIMS_FAILURE}))
			})
		}

		const consult = state.router.isConsult ? '&consult=true' : ''
		return baseFetch(`${state.metaLayer.env_appServer}/carfit/vehicles?year=${state.years.selection}&make=${state.makes.selection}&model=${state.models.selection}${consult}`)
			.then(data => data.trims ? dispatch({type: Types.RETRIEVE_TRIMS_SUCCESS, trims: data.trims}) : dispatch({type: Types.RETRIEVE_VEHICLE_SUCCESS, vehicle: data}))
			.catch(err => dispatch({type: Types.RETRIEVE_TRIMS_FAILURE}))
	}
}

export function setTrim(trim) {
	return dispatch => dispatch({type: Types.SET_TRIM, trim})
}
