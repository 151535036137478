import {CONSULT_PARAM} from './constants'

export const buildConsultUrlUsingState = (state, baseUrl) => {
	const isConsult = (state.router || {}).isConsult
	return buildConsultUrl(isConsult, baseUrl)
}

export const buildConsultUrl = (isConsult, baseUrl) => {
	return isConsult ? `${baseUrl}?${CONSULT_PARAM}` : baseUrl
}
