import * as Types from '../../actions/action-types'
import {ERROR} from '../../utils/constants'

export function reviewPageUIReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.SUBMIT_APPOINTMENT:
			return {...state, submitting: true}

		case Types.SUBMIT_APPOINTMENT_SUCCESS:
			return {...state, submitting: false, submitSuccess: true, error: undefined}

		case Types.SUBMIT_APPOINTMENT_FAILURE: {
			const error = (action.response || {}).error
			let errorType = error && (error.errorCode === '-4000') ? ERROR.SLOT_UNAVAILABLE : ERROR.UNKNOWN
			return {...state, submitting: false, error: errorType}
		}

		case Types.SELECT_SLOT:
			return {...state, error: undefined}

		default:
			return state
	}
}
