import React, {Component, createRef} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {isAdditionalItem} from '../reducers/packages'
import {emptyFunction, noHref, PRODUCT_DETAILS_API_STATUS} from '../utils/constants'
import {humanizeDuration} from '../utils/string-utils'
import {BrixIcon} from '@bestbuy/brix-web'
import globals from '../globals'

require('moment-duration-format')

const cb = 'auto-tech-selected-service'

export class SelectedService extends Component {

	static propTypes = {
		packages: PropTypes.object,
	}
	constructor(props) {
		super(props)
		this.triggerRef = createRef()
	}

	componentDidUpdate(prevProps) {
		const {showPartsAndLabor} = prevProps.packages
		if(showPartsAndLabor === true && this.props.packages.showPartsAndLabor === false) {
			if(this.triggerRef.current) this.triggerRef.current.focus()
		}
	}
	
	render() {
		const {pkg = {},  suppressFleetInformation} = this.props
		const price = (pkg.price || 0).toFixed(2)
		const duration = humanizeDuration(pkg.duration)
		const isRemoteStartFacet = pkg.facet && pkg.facet.id === 'remotestart'
		
		return (
			<div className={cb}>
				<div className={`${cb}__left-column`}>
					<div>{pkg.name}</div>
					{duration && <div className={`${cb}__duration`}>{duration}</div>}
				</div>
				{!suppressFleetInformation && <div className={`${cb}__cost`}>${price}</div>}
				{pkg.name && !isAdditionalItem(pkg) && (
					<button className={`${cb}__service-removal`} aria-label='Remove this service' onClick={() => this.props.removePackage(pkg)}>
						<div className={'fill-value-red'}>
							<BrixIcon icon='cancel' fill={'#BB0628'}/>
						</div>
					</button>
				)}
				{
					pkg.productDetailsApiStatus !== PRODUCT_DETAILS_API_STATUS.PENDING
					&& !isRemoteStartFacet
					&& globals.liveConfig.addPartsLink
					&& (
						<div className={`${cb}__parts`}>
							<a href={noHref} onClick={() => this.props.showPackagePartsAndLabor(pkg)} ref={this.triggerRef} >
								See itemized parts and labors
							</a>
						</div>
					)
				}
			</div>
		)
	}
}

SelectedService.defaultProps = {
	removePackage: emptyFunction,
	showPackagePartsAndLabor: emptyFunction
}

SelectedService.propTypes = {
	pkg: PropTypes.object,
	removePackage: PropTypes.func,
	showPackagePartsAndLabor: PropTypes.func,
	suppressFleetInformation: PropTypes.bool,
}

export const mapStateToProps = (state) => {
	return {packages: state.packages || {}}
	
}

export const ConnectedSelectedServicePage = connect(mapStateToProps)(SelectedService)
