import {SUBMIT_APPOINTMENT, SUBMIT_APPOINTMENT_FAILURE, SUBMIT_APPOINTMENT_SUCCESS} from './action-types'
import * as api from '../api/utils'
import Moment from 'moment'
import {OPT_OUT, OPT_IN, STD_DATE_TIME, STD_DATE_TIME_WITH_T} from '../utils/constants'
import {isAdditionalItem} from '../reducers/packages'
import * as router from './routing'
import globals from '../globals'

export function submitAppointment() {
	return (dispatch, getState) => {
		dispatch({type: SUBMIT_APPOINTMENT})

		const rescheduling = (getState().router || {}).isReschedule || (getState().user || {}).isRescheduleAppointment

		return api.baseFetch(
			`${getState().metaLayer.env_appServer}/appointment${rescheduling ? '/reschedule' : ''}`,
			{
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify(rescheduling ? buildRescheduleBody(getState()) : buildNewAppointmentBody(getState()))
			})
			.then(response => {
				dispatch({type: SUBMIT_APPOINTMENT_SUCCESS, confirmationNumber: response.confirmationNumber})
				dispatch(router.push('/confirmation'))
			})
			.catch(error => dispatch({type: SUBMIT_APPOINTMENT_FAILURE, response: JSON.parse(error.message)}))
	}
}

export const buildNewAppointmentBody = state => {

	const {addingPhone, optingOut, updatingPhone, newPhone, smsOptInPhoneNumber: originalSmsOptInPhoneNumber} = state.user
	const hasChangedPhone = (updatingPhone || addingPhone) && !!newPhone
	const isUpdateOptIn = !!(optingOut || hasChangedPhone)
	let optInStatus, smsOptInPhoneNumber
	if (isUpdateOptIn) {
		if (optingOut && !newPhone) {
			optInStatus = OPT_OUT
			smsOptInPhoneNumber = originalSmsOptInPhoneNumber
		}
		else if (newPhone) {
			optInStatus = OPT_IN
			smsOptInPhoneNumber = newPhone
		}
	}

	const phoneNumber = globals.liveConfig.smsOptInEnabled ? newPhone || originalSmsOptInPhoneNumber || state.user.phone : state.user.phone
	const smsParams = globals.liveConfig.smsOptInEnabled ? {isUpdateOptIn, optInStatus, smsOptInPhoneNumber} : {}

	let services = []

	state.packages.selection.map(pkg => {
		services = services.concat(pkg.skus.map(sku => ({
			sku: `${sku.sku}`,
			model: sku.model,
			name: sku.name,
			category: isAdditionalItem(pkg) ? 'Material Fee' : pkg.facet.name,
			specialOrder: sku.specialOrder,
			price: sku.price ? `${sku.price}` : undefined,
			duration: sku.duration ? `${sku.duration}` : undefined,
			packageId: pkg.id,
			packageDescription: pkg.shortName
		})))
	})

	return {
		firstName: state.user.firstName,
		lastName: state.user.lastName,
		partyId: state.user.partyId,
		phoneNumber,
		emailId: state.user.email,
		comments: state.user.comments,
		storeId: state.storePageUI.selection.locationId,
		duration: `${state.packages.summary.duration}`,
		timeZoneId: state.stores.selection.timeZoneId,
		appointmentTime: Moment(state.storePageUI.selection.time, STD_DATE_TIME).format(STD_DATE_TIME_WITH_T),
		year: state.years.selection,
		make: state.makes.selection,
		model: state.models.selection,
		trim: state.trims.selection,
		resources: state.storePageUI.selection.resources,
		services,
		...smsParams
	}
}

export const buildRescheduleBody = state => ({
	confirmationNumber: state.searchPageUI.confirmationNumber,
	storeId: state.storePageUI.selection.locationId,
	timeZoneId: state.stores.selection.timeZoneId,
	appointmentTime: Moment(state.storePageUI.selection.time, STD_DATE_TIME).format(STD_DATE_TIME_WITH_T),
	resources: state.storePageUI.selection.resources,
})
