import {baseFetch} from './utils'

export function getStoresByZip(url) {
	return baseFetch(url).then(resp => {
		return resp.map(store => ({
			locationId: store.locationId,
			timeZoneId: store.timeZoneId,
			distance: store.distance ? store.distance.toFixed(2) : undefined,
			latitude: store.latitude,
			longitude: store.longitude,
			phone: store.phone,
			address: {
				displayName: store.displayName,
				addressLineOne: store.addr1,
				addressLineTwo: store.addr2,
				city: store.city,
				state: store.state,
				zip: store.zipCode
			}
		}))
	})
}
