import * as Types from './action-types'
import {baseFetch} from '../api/utils'

export function retrieveYears() {
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_YEARS})

		return baseFetch(`${getState().metaLayer.env_appServer}/carfit/vehicles`)
			.then(
				data => dispatch({type: Types.RETRIEVE_YEARS_SUCCESS, years: data.years}),
				err => dispatch({type: Types.RETRIEVE_YEARS_FAILURE})
			)
	}
}

export function setYear(year) {
	return dispatch => (dispatch({type: Types.SET_YEAR, year}))
}
