import React, {Component} from 'react'
import {BrixCalendar} from '@bestbuy/brix-web-calendar/dist/main.cjs'
import {emptyFunction} from '../../utils/constants'
import PropTypes from 'prop-types'
import Moment from 'moment'

const cb = 'auto-tech-date-picker'

export class DatePicker extends Component {

	static defaultProps = {
		handleNextMonthClick: emptyFunction,
		handlePreviousMonthClick: emptyFunction,
		setSelectedDate: emptyFunction
	}

	static propTypes = {
		lastAvailableDate: PropTypes.object,
		firstAvailableDate: PropTypes.object,
		availableDates: PropTypes.object,
		selectedDate: PropTypes.object,
		showSpinner: PropTypes.bool,
		setSelectedDate: PropTypes.func,
		handleNextMonthClick: PropTypes.func,
		handlePreviousMonthClick: PropTypes.func,
	}

	render() {
		const {lastAvailableDate, availableDates, handlePreviousMonthClick, handleNextMonthClick, selectedDate, setSelectedDate, showSpinner} = this.props
		/**
		 *
		 * PLCalendar component has 2 modes -
		 * 1. lazy = true
		 *        - shows the spinner when 'isLoading' is set to true
		 *        - does not enable and disable previous and next month buttons based on the first available date or last available date in the 'availableDates' prop.
		 * 2. lazy = false
		 *        - does not show the spinner since the assumption is that all the all availableDates are eagerly loaded in the 'availableDates' prop
		 *        - Enables and disables previous and next month buttons based on the first available date and last available date in the 'availableDates' prop.
		 *
		 * The following workaround achieves this behavior by
		 *    1.    Toggling between 2 modes of PLCalendar based on value of the showSpinner prop
		 *    2.    Eagerly loading a single lastDate entry in the availableDates prop to enable the next arrows until this date is reached.
		 *          We still continue to lazy load next month data on next month button click.
		 *
		 */
		const showHideSpinner = showSpinner ? {lazy: true, isLoading: true} : {lazy: false, isLoading: false}
		const endDate = new Date(lastAvailableDate)
		if (!availableDates[endDate.toISOString()]) {
			availableDates[endDate.toISOString()] = {id: Object.keys(availableDates).length, date: endDate, disabled: false}
		}
		/**
		 * END OF WORKAROUND
		 */
		return (
			<div className={cb}>
				<BrixCalendar
					className={`${cb}__pl-calendar`}
					selectedDate={new Date(selectedDate)}
					availableDates={availableDates}
					handlePreviousMonthClick={cursorDate => !showSpinner && handlePreviousMonthClick(Moment(cursorDate))}
					handleNextMonthClick={cursorDate => !showSpinner && handleNextMonthClick(Moment(cursorDate))}
					onDateChange={selectedDate => !showSpinner && setSelectedDate(Moment(selectedDate))}
					{...showHideSpinner}
				/>
			</div>
		)
	}
}
