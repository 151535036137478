import React from 'react'
import PropTypes from 'prop-types'
import {BrixButton, BrixIcon} from '@bestbuy/brix-web'

export const AppointmentCalendar = ({
    url
}) => {
    return (
        <BrixButton
            className='calendar_button'
            buttonStyle='default-link'
            iconPosition='leading'
            href={url}
            target='_blank'
            disabled={!url}
        >
            <BrixIcon icon='Calendar_Line_Sm' />
            Add to calendar
        </BrixButton>
    )
}

AppointmentCalendar.propTypes = {
    url: PropTypes.string
}
