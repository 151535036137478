import * as slotApi from '../api/slots'
import * as Types from './action-types'
import Moment from 'moment'
import {STD_DATE, STD_DATE_TIME} from '../utils/constants'

export const STORE_QUERY_SIZE = 3

export function retrieveSlots() {

	return (dispatch, getState) => {
		const index = getState().stores.index || 0
		const summary = getState().packages.summary || {}
		const specialOrder = summary.hasSpecialOrder ? '&specialOrder=true' : ''
		const skus = (summary.skus || []).join(',')
		const confirmationNumber = (getState().searchPageUI || {}).confirmationNumber

		if (!getState().stores.data) {
			dispatch({type: Types.RETRIEVE_INITIAL_SLOTS_FAILURE})
			return Promise.reject('No store data found')
		}

		return Promise.all(getState().stores.data.slice(index, index + STORE_QUERY_SIZE)
			.map(store => {
					const locationId = store.locationId
					if (locationId) {
						dispatch({type: Types.RETRIEVE_INITIAL_SLOTS, locationId})
						return slotApi.getSlotsByStore(`${getState().metaLayer.env_appServer}/appointment${confirmationNumber ? `/${confirmationNumber}` : ''}/store/${locationId}${summary.duration ? `/${summary.duration}` : ''}?timeZoneId=${encodeURIComponent(store.timeZoneId)}${specialOrder}${skus ? `&skus=${skus}` : ''}`)
							.then(slots => dispatch({type: Types.RETRIEVE_INITIAL_SLOTS_SUCCESS, locationId, slots}))
							.then(() => {
								const calendar = getState().calendars
								if (calendar && calendar[locationId]) {
									return dispatch(setSlot(locationId, {time: calendar[locationId].firstAvailableDate}))
								}
							})
							.catch(err => dispatch({type: Types.RETRIEVE_INITIAL_SLOTS_FAILURE, locationId}))
					}
					return dispatch({type: Types.RETRIEVE_INITIAL_SLOTS_FAILURE})
				}
			))
	}
}

export function retrieveSlotsForStore(storeId, start, end) {
	return (dispatch, getState) => {
		const summary = getState().packages.summary || {}
		const skus = (summary.skus || []).join(',')
		const store = getState().stores.data.find(store => store.locationId === storeId) || {}
		const confirmationNumber = (getState().searchPageUI || {}).confirmationNumber

		if (store.locationId) {
			dispatch({type: Types.RETRIEVE_SLOTS, locationId: store.locationId})

			return slotApi.getSlotsByStore(`${getState().metaLayer.env_appServer}/appointment${confirmationNumber ? `/${confirmationNumber}` : ''}/calendar/${storeId}${summary.duration ? `/${summary.duration}` : ''}?start=${start}&end=${end}&timeZoneId=${encodeURIComponent(store.timeZoneId)}${skus ? `&skus=${skus}` : ''}`)
				.then(slots => dispatch({type: Types.RETRIEVE_SLOTS_SUCCESS, locationId: store.locationId, slots, startDate: start, endDate: end}))
				.catch(err => dispatch({type: Types.RETRIEVE_SLOTS_FAILURE}))
		}
		return dispatch({type: Types.RETRIEVE_SLOTS_FAILURE})
	}
}

export function setSlot(locationId, slot) {
	return (dispatch, getState) => {
		if (slot.selected) {
			return dispatch({type: Types.SELECT_SLOT, locationId})
		}

		if (slot.time) {
			const date = Moment(slot.time, STD_DATE_TIME).format(STD_DATE)
			const time = getState().calendars[locationId].dates[date].slots[slot.time]
			return dispatch({type: Types.SET_SLOT, locationId, date, start: time.start, end: time.end, resources: time.resources, ...slot})
		}

		return dispatch({type: Types.SET_SLOT, locationId, time: null, start: null, end: null, resources: null, ...slot})
	}
}
