import React from 'react'
import PropTypes from 'prop-types'
import {BrixIcon} from '@bestbuy/brix-web'
import {emptyFunction} from '../utils/constants'

const cb = 'auto-tech-back-link'

export class BackLink extends React.Component {

	static propTypes = {
		onClick: PropTypes.func,
	}

	static defaultProps = {
		onClick: emptyFunction,
	}

	render() {
		return (
			<a className={`${cb}__back-link`} href='javascript:void(0)' onClick={() => this.props.onClick()}>
				<div className={`${cb}__icon fill-best-buy-blue svg-size-s `}>
					<BrixIcon icon='caret-left' className={'ficon-caret-left'}/>
				</div>Back
			</a>
		)
	}
}
