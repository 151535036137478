import React, {Component} from 'react'
import PropTypes from 'prop-types'

const cb = 'auto-tech-address'

export class Address extends Component {
	render() {
		const {address = {}, locationId, distance} = this.props

		let title = <div className={`${cb}__address-name`}>
			<div>
				<div>{address.displayName}</div>
				<div>(Store {locationId})</div>
			</div>
		</div>

		if (this.props.onClick) {
			title = <a href='javascript:void(0)' onClick={() => this.props.onClick(locationId)}>{title}</a>
		}

		return (
			<div className={cb}>
				{locationId && title}
				{address.addressLineOne && <div className={`${cb}__value`}>{address.addressLineOne}</div>}
				{address.addressLineTwo && <div className={`${cb}__value`}>{address.addressLineTwo}</div>}
				{(address.city || address.state || address.zip) && (
					<div className={`${cb}__value`}>
						{address.city && `${address.city},`}
						{address.state && <span className={`${cb}__value--uppercase`}> {address.state} </span>}
						{address.zip}
					</div>
				)}
				{!!distance && <div className={`${cb}__distance`}>{`${distance} mi`}</div>}
			</div>
		)
	}
}

Address.propTypes = {
	address: PropTypes.object,
	locationId: PropTypes.string,
	distance: PropTypes.string,
	onClick: PropTypes.func
}
