import React from 'react'
import gsAgent from '../../images/gs-agent.svg'
import globals from '../../globals'
import PropTypes from 'prop-types'
import {DollarAmount} from '../../components/dollar-amount'

const cb = 'auto-tech-labor-line-item'

export const LaborLineItem = props => {
	const {isTotalTechMember, isPaidMember, description, price, productDescription, originalPrice, offerSavings} = props
	const hasOfferSavings = !!offerSavings

	return (
		<li className={cb}>
			<div className={`${cb}__flex`}>
				<div className={`${cb}__inner-flex`}>
					{globals.isLargeView && <img className={`${cb}__img`} src={gsAgent} alt=''/>}
					<div className={`${cb}__description`}>{productDescription || description}</div>
				</div>
				{(isTotalTechMember || isPaidMember) && (
					<div className={`${cb}__price-member`}>
						{hasOfferSavings && (
							<div className={`${cb}__inner-flex`}>
								<div className={`${cb}__dollar-amount`}><DollarAmount amount={originalPrice} strikeout={true}/></div>
							</div>
						)}
						<div className={`${cb}__inner-flex`}>
							<div className={`${cb}__dollar-amount`}><DollarAmount amount={price}/></div>
						</div>
					</div>
				)}
				{!(isTotalTechMember || isPaidMember) && (
					<div className={`${cb}__price-nonmember`}>
						<DollarAmount amount={price}/>
					</div>
				)}
			</div>
		</li>
	)
}

LaborLineItem.propTypes = {
	description: PropTypes.string,
	price: PropTypes.number,
	originalPrice: PropTypes.number,
	productDescription: PropTypes.string,
	isTotalTechMember: PropTypes.bool,
	isPaidMember: PropTypes.bool,
	offerSavings: PropTypes.number,
}
