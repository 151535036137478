import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {BrixButton, BrixSelect} from '@bestbuy/brix-web'
import {TitleAndProgress} from '../components/title-and-progress'
import consultLogo from '../images/car-electronics-assistance.png'
import {retrieveYears, setYear} from '../actions/years'
import {retrieveMakes, setMake} from '../actions/makes'
import {retrieveModels, setModel} from '../actions/models'
import {retrieveTrims, setTrim} from '../actions/trims'
import {retrieveVehicle} from '../actions/retrieve-vehicle'
import * as Constants from '../utils/constants'
import {GeneralMessage} from '../components/general-message'
import globals from '../globals'
import {toLowerCase} from '../utils/string-utils'
import * as routing from '../actions/routing'
import {saveUserInput} from '../actions/save-user-input'
import {setConsultationSelection} from '../actions/set-consultation'
import {buildConsultUrl} from '../utils/flow'
import {RAM_HINT_FOR_DODGE_MODELS_TEXT} from '../utils/constants'
import {RAM_HINT_FOR_DODGE_MODELS_VALUE} from '../utils/constants'
import {hasTruthyLength} from '../utils/utils'
import {DEFAULT_DROPDOWN_VALUE} from '../utils/constants'

const cb = 'auto-tech-vehicle-selector'

export class VehicleSelectorPage extends Component {

	componentWillMount() {
		if (this.props.user.restoreSession && !this.props.user.restoreSessionCompleted) {
			if (this.props.isConsult) {
				this.props.push(buildConsultUrl(this.props.isConsult, '/store-selector'))
			}
			else if(this.props.user.isCancelAppointment){
				this.props.push('/cancel/confirmation')
			}
			else {
				this.props.push('/service-selector')
			}
		}
	}

	componentDidMount() {
		if (!this.props.years.data) {
			this.props.retrieveYears()
		}
	}

	render() {
		const {vehicle, vehiclePageUI, error, allowSelfSchedule, isConsult} = this.props
		const pageError = error || !allowSelfSchedule
		const omitNextButton = !vehicle.data || pageError
		const isLargeView = globals.isLargeView
		let title = isConsult ? 'Your car electronics consultation' : 'Describe your vehicle'

		if (isLargeView && !isConsult) {
			return (
				<div className={`${cb} ${cb}__background`}>
					<div className={`${cb}__all-content-wrapper ${cb}__all-content-wrapper--orange-stripe`}>
						<div className={`${cb}__large-view-heading`}>
							<TitleAndProgress title={title}/>
						</div>
						{this.pageError(pageError)}
						<div className={`${cb}__body`}>
							<div className={`${cb}__loader-wrapper`}>
								{this.vehicleLoading(vehiclePageUI.loading)}
								{this.vehicleForm(this.props)}
							</div>
							{!vehicle.data && this.helpText}
							{globals.isLargeView && this.nextButton(omitNextButton, isConsult)}
						</div>
					</div>
				</div>
			)
		}

		else if (isLargeView && isConsult) {
			return (
				<div className={`${cb} ${cb}__background`}>
					<div className={`${cb}__all-content-wrapper ${cb}__all-content-wrapper--orange-stripe`}>
						<div className={`${cb}__flex`}>
							<div className={`${cb}__left`}>
								<div className={`${cb}__consult-heading`}>
									<div className={`${cb}___consult-title`}>
										<TitleAndProgress title={title}/>
									</div>
									{pageError && <div className={`${cb}__consult-error`}><GeneralMessage/></div>}
									<div className={`${cb}__consult-body`}>
										<div className={`${cb}__consult-text`}><strong>Thinking about updating your vehicle with new tech?</strong> Schedule a free consultation to talk about the possibilities. Installation scheduled separately.</div>
										<div>
											{this.vehicleLoading(vehiclePageUI.loading)}
											{this.vehicleForm(this.props)}
										</div>
										{!vehicle.data && this.helpText}
										{this.nextButton(omitNextButton, isConsult)}
									</div>
								</div>
							</div>
							<div className={`${cb}__right`}>
								<img src={consultLogo} className={`${cb}__consult-image`} alt='Mobile Electronics Certified Professionals'/>
								<div className={`${cb}__consult-image-text`}>Our Autotechs are Mobile Electronics Certified Professionals</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div className={cb}>
				<div className={`${cb}__small-view-heading`}>
					<TitleAndProgress title={title} currentPage='vehicle-selector'/>
				</div>
				<div className={`${cb}__all-content-wrapper`}>
					{this.pageError(pageError)}
					<div className={`${cb}__body`}>
						{isConsult && (
							<div className={`${cb}__consult-text`}>
								<strong>Thinking about updating your vehicle with new tech? </strong>
								Schedule a free consultation with one of our Mobile Electronics Certified Professionals to talk about the possibilities. Installation scheduled separately.
							</div>)}
						<div className={`${cb}__loader-wrapper`}>
							{this.vehicleLoading(vehiclePageUI.loading)}
							{this.vehicleForm(this.props)}
						</div>
						{!vehicle.data && this.helpText}
					</div>
				</div>
				<div className={`${cb}__button-section`}>
					{!omitNextButton && this.nextButton(omitNextButton, isConsult)}
				</div>
			</div>
		)
	}

	pageError = (error) => {
		if (error) {
			return <div className={`${cb}__error`}><GeneralMessage/></div>
		}
	}

	vehicleLoading = (isLoading) => {
		if (isLoading) {
			return (
				<div className={`${cb}__loader-background`}>
					<div className={`${cb}__loader-content`}>
						<i className='spinner' aria-label='loading'/>
					</div>
				</div>
			)
		}
	}

	vehicleForm = (props) => {
		const {years, makes, models, trims, setYear, setMake, setModel, setTrim, isConsult} = props

		return (
			<form className={`${cb}__vehicle-form`}>
				{this.dropdown({
					type: 'Year',
					source: years,
					onChange: setYear
				})}

				{makes.data && !!makes.data.length && this.dropdown({
					type: 'Make',
					source: makes,
					onChange: setMake
				})}

				{models.data && !!models.data.length && this.dropdown({
					type: 'Model',
					source: models,
					years: years,
					makes: makes,
					selectedYear: years.selection,
					selectedMake: makes.selection,
					onChange: setModel
				})}

				{!isConsult && trims.data && !!trims.data.length && this.dropdown({
					type: 'Trim',
					source: trims,
					onChange: setTrim
				})}
			</form>
		)
	}

	dropdown = (config) => {
		const lowercaseType = toLowerCase(config.type)
		const source = config.source || {}
		const dropdownValue = source.selection || DEFAULT_DROPDOWN_VALUE
		let options

		if (config.type === 'Model') {
			options = this.getModelOptionsWithAnalytics(source.data, config.selectedYear, config.selectedMake, addRamHintToDodgeModels(config.years, config.makes))
		}
		else {
			options = this.getOptions(source.data, config.type)
		}

		return (
			<div className={`${cb}__${lowercaseType}-dropdown-container`}>
				<label className={`${cb}__${lowercaseType}-dropdown-label sr-only`}
					   htmlFor={`${cb}__${lowercaseType}-dropdown`}
					   aria-hidden={globals.isLargeView}>
					{config.type} Select
				</label>
				<BrixSelect className={`${cb}__${lowercaseType}-dropdown ${cb}__dropdown`}
							id={`${cb}__${lowercaseType}-dropdown`}
							options={options}
							value={dropdownValue}
							aria-label={`Select vehicle ${lowercaseType}`}
							onChange={e => config.onChange(e.target.value)}/>
			</div>
		)
	}

	getOptions = (source = [], type) => {
		const options = [{text: `Select vehicle ${toLowerCase(type)}`, value: 'default', attributes: {disabled: true}}]
		options.push(...source.map(it => ({text: `${it}`, value: `${it}`})))
		return options
	}

	getModelOptionsWithAnalytics = (models = [], selectedYear, selectedMake, addRamHintToDodgeModelsDropdown) => {
		const options = [{text: `Select vehicle model`, value: 'default', attributes: {disabled: true}}]
		options.push(...models.map(model => ({text: model, value: model, attributes: {'data-track': `Auto:${selectedYear}:${selectedMake}:${model}`}})))
		if (addRamHintToDodgeModelsDropdown) {
			options.push({text: RAM_HINT_FOR_DODGE_MODELS_TEXT, value: RAM_HINT_FOR_DODGE_MODELS_VALUE, attributes: {disabled: true}})
		}
		return options
	}

	helpText = (
		<div className={`${cb}__need-help-text`}>
			Do you have a boat, recreational vehicle or motorcycle? Give us a call on {globals.isLargeView ? Constants.AUTOTECH_NUMBER : Constants.AUTOTECH_NUMBER_LINK} to discuss your options and schedule an appointment.
		</div>
	)

	nextButton = (omitNextButton, isConsult) => {
		let handler = (e) => {
			isConsult ? this.props.setConsultationSelection(buildConsultUrl(isConsult, '/store-selector')) : this.props.push('/service-selector')
		}

		return omitNextButton?	null : <BrixButton className={`${cb}__next-button`} buttonStyle='secondary' onClick={handler}>Next</BrixButton>
	}
}

export const addRamHintToDodgeModels = (years, makes) => {
	// See FIRE-3611
	let addRamHintToDodgeModels = false
	if (years && makes && hasTruthyLength(years.data) && years.selection && hasTruthyLength(makes.data) && makes.selection) {
		if (makes.selection === 'Dodge' && makes.data.indexOf('Ram') !== -1) {
			addRamHintToDodgeModels = true
		}
	}
	return addRamHintToDodgeModels
}

VehicleSelectorPage.propTypes = {
	allowSelfSchedule: PropTypes.bool,
	error: PropTypes.bool,
	isConsult: PropTypes.bool,
	makes: PropTypes.object,
	models: PropTypes.object,
	push: PropTypes.func,
	retrieveYears: PropTypes.func,
	setMake: PropTypes.func,
	setModel: PropTypes.func,
	setTrim: PropTypes.func,
	setYear: PropTypes.func,
	trims: PropTypes.object,
	user: PropTypes.object,
	vehicle: PropTypes.object,
	vehiclePageUI: PropTypes.object,
	years: PropTypes.object,
	setConsultationSelection: PropTypes.func,
}

export const mapStateToProps = (state) => {
	const {years = {}, makes = {}, models = {}, trims = {}, vehicle = {}, vehiclePageUI = {}, user = {}, router = {}} = state
	const vehicleData = vehicle.data || {}
	let allowSelfSchedule = true
	if (vehicleData.make && !vehicleData.allowSelfSchedule) {
		allowSelfSchedule = false
	}
	return {
		isConsult: router.isConsult,
		years,
		makes,
		models,
		trims,
		vehicle,
		vehiclePageUI,
		allowSelfSchedule,
		user,
		error: !!(years.error || makes.error || models.error || trims.error || vehicle.error)
	}
}

export const mapDispatchToProps = (dispatch) => ({
	push: route => dispatch(routing.push(route)),
	retrieveYears: () => dispatch(retrieveYears()),
	setYear: (year) => {
		dispatch(setYear(year))
		dispatch(retrieveMakes())
	},
	setMake: (make) => {
		dispatch(setMake(make))
		dispatch(retrieveModels())
	},
	setModel: (model) => {
		dispatch(setModel(model))
		dispatch(retrieveTrims())
	},
	setTrim: (trim) => {
		dispatch(setTrim(trim))
		dispatch(retrieveVehicle())
	},
	saveUserInput: userInput => dispatch(saveUserInput(userInput)),
	setConsultationSelection: (url) => dispatch(setConsultationSelection(url))
})

export const ConnectedVehicleSelectorPage = connect(mapStateToProps, mapDispatchToProps)(VehicleSelectorPage)
