import React, {Component} from 'react'
import {TitleAndProgress} from '../components/title-and-progress'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {BrixAlert, BrixButton, BrixModal, BrixIcon} from '@bestbuy/brix-web'
import {retrieveStores} from '../actions/retrieve-stores'
import {withRouter} from 'react-router-dom'
import {retrieveSlots, retrieveSlotsForStore, setSlot} from '../actions/slots'
import {StoreRow} from './store-row'
import {ZipEntry} from './zip-entry'
import {setZip} from '../actions/zip'
import {determineNextRoute} from '../actions/session'
import {resetRestoreSession, restoreSessionCompleted} from '../actions/user'
import {showTimes} from '../actions/show-times'
import {showHideMap} from '../actions/show-map'
import {StoreMap} from './store-map'
import globals from '../globals'
import {BackLink} from '../components/back-link'
import {SET_FOCUSED_STORE} from '../actions/action-types'
import * as routing from '../actions/routing'
import {buildConsultUrl} from '../utils/flow'

const cb = 'auto-tech-store-selector'

export class StoreSelectorPage extends Component {
	state = {}

	componentDidMount() {
		const {user = {}, stores = {}, isConsult} = this.props

		this.moveToCustomerDetails(user.restoreSession, user.restoreSessionCompleted, user.verified, isConsult, user.isRescheduleAppointment)

		if (!user.zip) {
			this.setState({showModal: true})
		}
		else if (user.zip !== stores.zip) {
			this.props.retrieveStores(user.zip)
		}
	}

	componentWillReceiveProps(nextProps) {
		const {user = {}, isConsult} = nextProps
		//In the case of coming from login page, /user-details may not have finished before being restored to this page, so wait for the change
		//User cannot interact until the change comes in because of spinner below so no jarring view change
		this.moveToCustomerDetails(user.restoreSession, user.restoreSessionCompleted, user.verified, isConsult, user.isRescheduleAppointment)
	}

	render() {
		const {calendars = {}, storePageUI = {}, user = {}, vehicle = {}, vehicleYear = '', isConsult, isReschedule} = this.props
		const stores = (this.props.stores || {}).data
		const storeIndex = this.props.stores && this.props.stores.index || 0
		const showPageLoading = !!(storePageUI.loadingMultipleStores && storePageUI.loadingMultipleStores.length)
		const storesWithSlots = Array.isArray(stores) ? stores.filter(store => !!calendars[store.locationId]) : []
		const hasStoresWithSlots = !!storesWithSlots.length
		const showInitialLoadingDiv = !stores || user.loading || !!stores.length && !hasStoresWithSlots && showPageLoading
		const showShowMoreButton = Array.isArray(stores) ? storeIndex !== stores.length : false
		const isLargeView = globals.isLargeView
		const vehicleInfo = `${vehicleYear} ${vehicle.data.make} ${vehicle.data.model}`

		user.zip = user.zip || ''
		const headerText = globals.isLargeView ? `Open appointments near ${user.zip}` : `Stores near ${user.zip}`

		const svgUseAttributes={width:100, height:86}

		return (
			<div className={cb}>
				<div className={`${cb}__all-content-wrapper`}>
					<TitleAndProgress currentPage='store-selector'
									  searchFlow={!!isReschedule}
									  title='Choose a store and time'/>
					<div className={`${cb}__content`}>

						<div className={`${cb}__map-container`}>
							<div className={`${cb}__selected-vehicle-block`}>
								<div className={`${cb}__selected-vehicle-block-icon-wrapper`}>
									<BrixIcon className={`${cb}__selected-vehicle-block-icon`} icon='CarElectronics_Line_Sm' svgUseAttributes={svgUseAttributes}/>
								</div>
								<div className={`${cb}__selected-vehicle-block-text`}>
									<div>Appointment for your</div>
									<div>{vehicleInfo}</div>
								</div>
							</div>

							{globals.isLargeView && (
								<div className={`${cb}__map`}>
									<StoreMap storePageUI={storePageUI} stores={storesWithSlots}/>
								</div>
							)}
						</div>

						<div className={`${cb}__header-container`}>
							<h2 className={`${cb}__header`}>{headerText}</h2>
							<a className={`${cb}__change-link`} href='javascript:void(0)' onClick={() => this.setState({showModal: true})} data-track='Change ZIP'>Change</a>
						</div>

						{this.state.showModal && (
							<BrixModal className={`${cb}__zip-modal`} autoFocus='false' size={isLargeView ? 'small' : 'small-view'} onClose={() => this.setState({showModal: false})}>
								<ZipEntry zip={user.zip} submitHandler={(zip) => {
									this.props.setZip(zip)
									this.setState({showModal: false})
								}}/>
							</BrixModal>
						)}

						{showInitialLoadingDiv && (
							<div className={`${cb}__spinner-wrapper`}>
								<div className={`${cb}__spinner-content`}>
									<i className='spinner' aria-label='loading'/>
								</div>
							</div>
						)}

						{!showInitialLoadingDiv && (
							<div>
								{hasStoresWithSlots && (
									<ol className={`${cb}__store-list`}>
										{storesWithSlots.map((store, idx) =>
											<li key={idx} className={`${cb}__store-row`}>
												<StoreRow calendar={calendars[store.locationId]}
														  store={store}
														  isReschedule={isReschedule}
														  setSlot={this.props.setSlot}
														  retrieveSlotsForStore={this.props.retrieveSlotsForStore}
														  bingLoaded={storePageUI.bingLoaded}
														  storePageUI={storePageUI[store.locationId]}
														  setFocusedStore={this.props.setFocusedStore}
														  determineNextRoute={this.props.determineNextRoute}
														  user={user}
														  showTimes={this.props.showTimes}
														  showHideMap={this.props.showHideMap}/>
											</li>
										)}
									</ol>)
								}

								{!hasStoresWithSlots && (
									<div className={`${cb}__alert-wrapper`}>
										<BrixAlert level='info' role='alert' isVisible={true} className={`${cb}__alert-component`}>
											<div className={`${cb}__alert-text`}>
												<div className={`${cb}__alert-text-bold`}>We’re sorry.</div>
												There are no stores in your area with availability. Please call a Geek Squad Autotech on <span className={`${cb}__alert-text-phone`}>1-800-433-5778</span> or check your zip code and try again.
											</div>
										</BrixAlert>
									</div>)
								}
							</div>)
						}

						{showShowMoreButton && (
							<BrixButton className={`${cb}__show-more-button ${showPageLoading ? 'btn-trailing-ficon' : ''}`}
									  buttonStyle='outline'
									  size={isLargeView ? 'small' : 'medium'}
									  disabled={showPageLoading}
									  onClick={() => this.props.retrieveSlots()}
									  data-track='Show More Stores'>
								Show More Stores
								{showPageLoading ? <i className='spinner' aria-label='loading'/> : ''}
							</BrixButton>
						)}
					</div>

					{!isLargeView && <BackLink onClick={e => this.props.push(this.backLinkPath(isReschedule, isConsult))}/>}
				</div>
			</div>
		)
	}

	backLinkPath = (isReschedule, isConsult) => {
		let url = '/service-selector'
		if (isReschedule) {
			url = '/search/results'
		}
		else if (isConsult) {
			url = buildConsultUrl(isConsult, '/vehicle-selector')
		}
		return url
	}

	moveToCustomerDetails = (restoreSession, restoreSessionCompleted, verified, isConsult, isRescheduleAppointment) => {
		if (restoreSession) {
			if (!restoreSessionCompleted) {
				this.props.restoreSessionCompleted()
			}
			if (verified && !isRescheduleAppointment) {
				const url = buildConsultUrl(isConsult, '/customer-details')
				this.props.resetRestoreSession()
				this.props.push(url)
			}
		}
	}
}

StoreSelectorPage.propTypes = {
	calendars: PropTypes.object,
	determineNextRoute: PropTypes.func,
	isConsult: PropTypes.bool,
	isReschedule: PropTypes.bool,
	push: PropTypes.func,
	retrieveStores: PropTypes.func,
	retrieveSlots: PropTypes.func,
	retrieveSlotsForStore: PropTypes.func,
	setSlot: PropTypes.func,
	stores: PropTypes.object,
	storePageUI: PropTypes.object,
	resetRestoreSession: PropTypes.func,
	restoreSessionCompleted: PropTypes.func,
	setZip: PropTypes.func,
	showTimes: PropTypes.func,
	showHideMap: PropTypes.func,
	setFocusedStore: PropTypes.func,
	user: PropTypes.object,
	vehicle: PropTypes.object,
	vehicleYear: PropTypes.string
}

export const mapStateToProps = (state = {}) => {
	const {router = {}, search = {}, years = {}} = state

	return {
		calendars: state.calendars,
		isConsult: !!router.isConsult,
		isReschedule: !!router.isReschedule,
		storePageUI: state.storePageUI,
		stores: state.stores,
		user: state.user,
		vehicle: state.vehicle,
		vehicleYear: years.selection || search.year
	}
}

export const mapDispatchToProps = (dispatch) => ({
	determineNextRoute: () => dispatch(determineNextRoute()),
	push: route => dispatch(routing.push(route)),
	retrieveStores: (zip) => dispatch(retrieveStores(zip)).then(() => dispatch(retrieveSlots())),
	retrieveSlots: () => dispatch(retrieveSlots()),
	retrieveSlotsForStore: (storeId, start, end) => dispatch(retrieveSlotsForStore(storeId, start, end)),
	resetRestoreSession: () => dispatch(resetRestoreSession()),
	restoreSessionCompleted: () => dispatch(restoreSessionCompleted()),
	setFocusedStore: locationId => dispatch({type: SET_FOCUSED_STORE, locationId}),
	setSlot: (locationId, slot) => dispatch(setSlot(locationId, slot)),
	setZip: (zip) => {
		dispatch(setZip(zip))
		dispatch(retrieveStores(zip)).then(() => dispatch(retrieveSlots()))
	},
	showTimes: (locationId) => dispatch(showTimes(locationId)),
	showHideMap: (locationId) => dispatch(showHideMap(locationId))
})

export const ConnectedStoreSelectorPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreSelectorPage))
