import * as Types from '../actions/action-types'
import * as _ from 'lodash'
import {isZip} from '../utils/form-validation-utils'

export function userReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.SET_ZIP:
			if (isZip(action.zip)) {
				return {...state, zip: action.zip}
			}
			return state

		case Types.SEARCH_FOR_APPOINTMENT_SUCCESS:
			return {...state, zip: action.location.zipCode}

		case Types.SAVE_USER_INPUT:
			if (_.isPlainObject(action.userInput)) {
				return {...state, ...action.userInput}
			}

			return state

		case Types.RETRIEVE_USER_DETAILS:
			return {...state, loading: true, verified: false}

		case Types.RETRIEVE_USER_DETAILS_SUCCESS:
			return {...state, ...action.details, loading: false, verified: true}

		case Types.RETRIEVE_USER_DETAILS_FAILURE:
			return {...state, loading: false, verified: false, totalTechMember: false, paidMember: false}

		case Types.RESET_RESTORE_SESSION:
			return {...state, restoreSession: false}

		case Types.RESTORE_SESSION_COMPLETED:
			return {...state, restoreSessionCompleted: true}

		case Types.UPDATE_PHONE:
			return {...state, updatingPhone: true, optingOut: undefined}

		case Types.ADD_PHONE:
			return {...state, addingPhone: true}

		case Types.SMS_OPT_OUT:
			return {...state, updatingPhone: undefined, optingOut: true}

		default:
			return state
	}
}
