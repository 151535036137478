import * as api from '../api/stores'
import * as Types from './action-types'

export function retrieveStores(zip, start, end) {
	let queryParams = ''
	if (start && end) {
		queryParams = `?start=${start}&end=${end}`
	}
	return (dispatch, getState) => {
		dispatch({type: Types.RETRIEVE_STORES})
		return api.getStoresByZip(`${getState().metaLayer.env_appServer}/appointment/stores/${zip}${queryParams}`)
			.then(stores => dispatch({type: Types.RETRIEVE_STORES_SUCCESS, stores: stores, zip: zip}))
			.catch(err => dispatch({type: Types.RETRIEVE_STORES_FAILURE}))
	}
}
