import * as Types from '../actions/action-types'

export function vehiclePageUIReducer(state = {}, action = {}) {
	switch (action.type) {

		case Types.RETRIEVE_YEARS:
		case Types.RETRIEVE_MAKES:
		case Types.RETRIEVE_MODELS:
		case Types.RETRIEVE_TRIMS:
		case Types.RETRIEVE_VEHICLE:
			return {...state, loading: true}

		case Types.RETRIEVE_YEARS_SUCCESS:
		case Types.RETRIEVE_YEARS_FAILURE:
		case Types.RETRIEVE_MAKES_SUCCESS:
		case Types.RETRIEVE_MAKES_FAILURE:
		case Types.RETRIEVE_MODELS_SUCCESS:
		case Types.RETRIEVE_MODELS_FAILURE:
		case Types.RETRIEVE_TRIMS_SUCCESS:
		case Types.RETRIEVE_TRIMS_FAILURE:
		case Types.RETRIEVE_VEHICLE_SUCCESS:
		case Types.RETRIEVE_VEHICLE_FAILURE:
			return {...state, loading: false}

		case Types.SET_YEAR:
			return {...state, year: action.year}

		case Types.SET_MAKE:
			return {...state, make: action.make}

		case Types.SET_MODEL:
			return {...state, model: action.model}

		default:
			return state
	}
}
