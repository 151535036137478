import React from 'react'
import PropTypes from 'prop-types'
import {toLowerCase} from '../utils/string-utils'

const cb = 'auto-tech-progress-bar'

export class ProgressBar extends React.Component {

	static propTypes = {
		currentPage: PropTypes.string,
		searchFlow: PropTypes.bool,
	}

	render() {
		const {currentPage, searchFlow, title} = this.props
		const pageFlow = (searchFlow) ? this.searchFlowSections : this.scheduleFlowSections

		return (
			<ul className={`${cb} ${searchFlow ? `${cb}--search-flow` : ''}`}>
				{pageFlow.map((section, idx) => (
					<li className={`${cb}__step  ${searchFlow ? `${cb}__step--search-flow` : ''}`}
						key={section.name}>
						<div className={`${cb}__section ${toLowerCase(currentPage) === section.name ? 'current' : ''}`}>
							{toLowerCase(currentPage) === section.name &&
                            <span className='sr-only' aria-live='polite'>
								Current step, {idx+1}, {title}
                            </span> }
							<span aria-hidden={true}>{idx + 1}</span>
						</div>
					</li>
				))}
			</ul>
		)
	}

	scheduleFlowSections = [
		{name: 'vehicle-selector'},
		{name: 'service-selector'},
		{name: 'store-selector'},
		{name: 'customer-details'},
		{name: 'confirmation'}
	]

	searchFlowSections = [
		{name: 'store-selector'},
		{name: 'confirmation'}
	]
}
