import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v1'
import globals from '../globals'
import inactive from '../images/map-pin-inactive.png'

const cb = 'auto-tech-store-map'

export class StoreMap extends React.Component {

	state = {uuid: uuid()}

	componentDidMount() {
		this.renderMap(this.props)
	}

	componentWillReceiveProps(nextProps) {
		this.renderMap(nextProps)
	}

	render() {
		return (
			<div className={cb}>
				<div id={`${cb}__map-display-${this.state.uuid}`} className={`${cb}__map-display`}>
					{!this.props.storePageUI.bingError && (
						<div className={`${cb}__spinner-wrapper`}>

							<div className={`${cb}__spinner-content`}>
								<i className='spinner' aria-label='loading'/>
							</div>
						</div>)
					}
				</div>
			</div>
		)
	}

	createMap = () => new Microsoft.Maps.Map(`#${cb}__map-display-${this.state.uuid}`, {
		credentials: 'AtcpqfXhbKVgtX7kmsHegU6ztWUyXjH_cYrXIgPNEt4A68EqPGYFqD4z9MZB727z'
	})

	createPin = (location, name, index) =>
		new Microsoft.Maps.Pushpin(
			location,
			{
				title: 'Best Buy',
				subTitle: name,
				text: globals.isLargeView ? `${index + 1}` : undefined,
				icon: inactive,
				anchor: new Microsoft.Maps.Point(21, 50)
			})

	renderMap = props => {
		if (props.storePageUI.bingLoaded && props.stores) {
			let bingMap = this.state.bingMap
			if (!bingMap) {
				bingMap = this.createMap()
				this.setState({bingMap})
			}

			bingMap.entities.clear()

			const focusedIndex = props.stores.findIndex(store => store.locationId === (props.storePageUI.focusedLocationId || props.stores[0].locationId))
			props.stores.forEach((store, index) => {
				if (store.latitude && store.longitude && store.address) {
					const location = new Microsoft.Maps.Location(store.latitude, store.longitude)
					bingMap.entities.push(this.createPin(location, store.address.city, index))
					if (focusedIndex === index) {
						bingMap.setView({center: location, zoom: 13})
					}
				}
			})
		}
	}
}

StoreMap.propTypes = {
	storePageUI: PropTypes.object,
	stores: PropTypes.array
}
