import React from 'react'
import PropTypes from 'prop-types'
import {AppointmentPrepTask} from './appointment-prep-task'

export const AppointmentPrepList = ({
    taskList = []
}) => {
    return (
        <>
            <ul className='apptPrepList v-m-top-xs v-m-bottom-reset list-unstyled'>
                {taskList && taskList.map((task) => (
                    <AppointmentPrepTask
                        key={task.taskCode}
                        task={task}
                    />
                ))}
            </ul>
        </>
    )
}

AppointmentPrepList.propTypes = {
    taskList: PropTypes.array.isRequired,
}
