import React from 'react'
import PropTypes from 'prop-types'
import {BrixAlert, BrixButton, BrixSelect, BrixModal} from '@bestbuy/brix-web'
import {Address} from '../components/address'
import {clickablePhone, DAY_LABEL_MONTH_DATE, emptyFunction, STD_DATE, STD_DATE_TIME} from '../utils/constants'
import Moment from 'moment'
import {DatePicker} from './datepicker/datepicker'
import * as _ from 'lodash'
import globals from '../globals'
import {StoreMap} from './store-map'

const cb = 'auto-tech-store-row'

export class StoreRow extends React.Component {
	state = {}

	componentDidMount() {
		if (this.storeSummary && this.alertContainer && globals.isLargeView) {
			this.storeSummary.style.height = `${Math.max(this.storeSummary.clientHeight, this.alertContainer.clientHeight)}px`
		}
	}

	render() {
		const {calendar = {}, retrieveSlotsForStore, showTimes, showHideMap, setSlot, store = {}, storePageUI = {}, user = {}, bingLoaded, isReschedule} = this.props
		const storeHasAvailableDates = !!(storePageUI.date || calendar.firstAvailableDate)
		const date = storePageUI.date || Moment(calendar.firstAvailableDate, STD_DATE_TIME).format(STD_DATE)
		const isLargeView = globals.isLargeView
		const showTimeslots = isLargeView || (!isLargeView && calendar.showTimes)
		const showHideMapText = calendar.showMap ? 'hide map' : 'show map'

		return (
			<div className={cb}>
				<div className={`${cb}__store-summary`} ref={el => this.storeSummary = el}>
					<Address locationId={store.locationId} address={store.address} distance={store.distance} onClick={globals.isLargeView ? this.props.setFocusedStore : null}/>
				</div>

				{!isLargeView && <a className={`${cb}__show_hide_map`} href='javascript:void(0)' onClick={() => showHideMap(store.locationId)}>{`${showHideMapText}`}</a>}
				{!isLargeView && calendar.showMap && <StoreMap storePageUI={{bingLoaded}} stores={[store]}/>}

				{!storeHasAvailableDates && showTimeslots && (
					<div className={`${cb}__alert-wrapper`} ref={el => this.alertContainer = el}>
						<BrixAlert level='info' role='alert' isVisible={true} className={`${cb}__alert-component`}>
							<div className={`${cb}__alert-text`}>
								{this.createStoreMessage(store)}
							</div>
						</BrixAlert>
					</div>
				)}

				{!showTimeslots && (
					<div className={`${cb}__show-times-container`}>
						<BrixButton className={`${cb}__show-times-button`}
								  buttonStyle='secondary'
								  data-track='Show Available Times'
								  onClick={() => showTimes(store.locationId)}>
							Show Available Times
						</BrixButton>
					</div>
				)}

				{storeHasAvailableDates && showTimeslots && (
					<div className={`${cb}__slot-summary`}>
						<div className={`${cb}__date-block`}>
							<label className={`${cb}__date-block-label`}>{Moment(date, STD_DATE).format(DAY_LABEL_MONTH_DATE)}</label>
							<BrixButton className={`${cb}__date-button`}
									  buttonStyle='outline'
									  data-track='Change Date'
									  onClick={() => {
										  this.getCalendarData(date, calendar, store.locationId, retrieveSlotsForStore)
										  this.setState({showModal: !this.state.showModal})
									  }}>
								Change Date
							</BrixButton>

							{this.state.showModal && (
								<BrixModal autoFocus='false'
										 className={`${cb}__datepicker-modal`}
										 size={isLargeView ? 'small' : 'small-view'}
										 onClose={() => this.setState({showModal: false})}>
									<DatePicker {...this.setupCalendarProps(calendar, storePageUI, store, retrieveSlotsForStore, setSlot)}/>
								</BrixModal>
							)}
						</div>

						<div className={`${cb}__timeframe-block`}>
							<BrixSelect className={`${cb}__timeframe-dropdown`}
										options={this.getSlots(calendar.dates[date])}
										value={storePageUI.time || 'default'}
										onChange={(event) => setSlot(store.locationId, {time: event.target.value})}/>
						</div>

						<div className={`${cb}__schedule-block ${(!user.email && !isReschedule) ? `${cb}__schedule-block--message` : ''}`}>
							<BrixButton className={`${cb}__schedule-button ${storePageUI.storeLoading ? 'btn-trailing-ficon' : ''}`}
									  buttonStyle='secondary'
									  disabled={storePageUI.storeLoading || !storePageUI.time}
									  onClick={() => {
										  setSlot(store.locationId, {selected: true})
										  this.props.determineNextRoute()
									  }}>
								Schedule
								{storePageUI.storeLoading && <i className='spinner' aria-label='loading'/>}
							</BrixButton>

							{(!user.email && !isReschedule) && <div className={`${cb}__login-message`}>Follow the login prompt on the next screen</div>}
						</div>
					</div>
				)}
			</div>
		)
	}

	createStoreMessage = (store) => {
		if (store.address && store.address.city && store.phone) {
			return <span>Our online scheduler shows we are full at this store, but things change on a daily basis. Visit or call the {store.address.city} store at {globals.isSmallView ? clickablePhone(store.phone) : store.phone} to see if we can accommodate you.</span>
		}
		return 'Our online scheduler shows we are full at this store, but things change on a daily basis. Visit or call this store to see if we can accommodate you.'
	}

	getSlots = (date = {}) => {
		const title = [{value: 'default', text: 'Select a Time', attributes: {disabled: true}}]
		const dynamicOptions = _.map(date.slots, (slot, key) => {
			return {value: key, text: slot.start}
		})
		return title.concat(dynamicOptions)
	}

	setupCalendarProps = (calendar = {}, storePageUI = {}, store = {}, retrieveSlotsForStore, setSlot, now) => {
		const monthChanges = (cursorDate) => {
			const startFetchDate = this.handleMonthChanges(cursorDate, calendar.firstAvailableDate)
			this.getCalendarData(startFetchDate, calendar, store.locationId, retrieveSlotsForStore)
		}

		return {
			availableDates: this.transformAvailableDates(calendar.dates),
			firstAvailableDate: Moment(calendar.firstAvailableDate, STD_DATE_TIME),
			lastAvailableDate: Moment(now).add(1, 'month').endOf('month'),
			selectedDate: storePageUI.date ? Moment(storePageUI.date, STD_DATE) : null,
			showSpinner: storePageUI.storeLoading,
			handleNextMonthClick: (cursorDateObj) => monthChanges(cursorDateObj),
			handlePreviousMonthClick: (cursorDateObj) => monthChanges(cursorDateObj),
			setSelectedDate: (momentObj) => this.saveSelectedSlot(momentObj, store.locationId, setSlot)
		}
	}

	transformAvailableDates = availableDates =>
		Object.keys(availableDates)
			.reduce((transformedDates, yyyymmddDate, currentIndex) => {
					const [year, month, day] = yyyymmddDate.split('-')
					const availableDate = new Date(year, month - 1, day, 0, 0, 0, 0)
					transformedDates[availableDate.toISOString()] = availableDates[yyyymmddDate]
					transformedDates[availableDate.toISOString()]['id'] = currentIndex
					transformedDates[availableDate.toISOString()]['date'] = availableDate
					return transformedDates
				}, {}
			)

	handleMonthChanges = (cursorDateObj, firstAvailableDate) => {
		const firstAvailableDateObj = Moment(firstAvailableDate, STD_DATE_TIME)

		if (firstAvailableDateObj.isSame(cursorDateObj, 'month')) {
			return firstAvailableDateObj.format(STD_DATE)
		}
		return cursorDateObj.startOf('month').format(STD_DATE)
	}

	getCalendarData = (date, calendar = {}, storeId, retrieveSlotsForStore) => {
		const endOfMonth = Moment(date, STD_DATE).endOf('month')
		const hasMonthsData = calendar.dates && calendar.dates[date] && calendar.dates[endOfMonth.format(STD_DATE)]

		if (!hasMonthsData) {
			retrieveSlotsForStore(storeId, date, endOfMonth.format(STD_DATE))
		}
	}

	saveSelectedSlot = (momentObj, storeId, setSlot) => {
		setSlot(storeId, {date: momentObj.format(STD_DATE)})
		this.setState({showModal: false})
	}
}

StoreRow.defaultProps = {
	determineNextRoute: emptyFunction,
	retrieveSlotsForStore: emptyFunction,
	setFocusedStore: emptyFunction,
	setSlot: emptyFunction,
	showHideMap: emptyFunction,
	showTimes: emptyFunction
}

StoreRow.propTypes = {
	bingLoaded: PropTypes.bool,
	calendar: PropTypes.object,
	isReschedule: PropTypes.bool,
	store: PropTypes.object,
	storePageUI: PropTypes.object,
	setSlot: PropTypes.func,
	setFocusedStore: PropTypes.func,
	retrieveSlotsForStore: PropTypes.func,
	determineNextRoute: PropTypes.func,
	user: PropTypes.object,
	showTimes: PropTypes.func,
	showHideMap: PropTypes.func
}
