import * as Types from '../actions/action-types'

export function yearsReducer(state = {}, action = {}) {
	switch (action.type) {
		case Types.RETRIEVE_YEARS_SUCCESS:
			return {data: action.years}

		case Types.RETRIEVE_YEARS_FAILURE:
			return {...state, error: true}

		case Types.SET_YEAR:
			return {...state, selection: action.year}

		default:
			return state
	}
}
